<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../../assets/img/bg-services1.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Maintenance & Support</h1>
    </div>
  </div>
</div>

<!--<< Service Details Section Start >>-->
<section class="service-details fix section-padding pt-5">
  <div class="container">
    <div class="service-details-wrapper">
      <div class="row">
        <div class="breadcrumb-wrapper mt-0">
          <div class="container">
            <div class="page-heading"></div>
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <div class="service-details-items">
            <!-- <div class="details-image">
                            <img src="assets/img/service/details-1.jpg" alt="img">
                        </div> -->
            <div class="details-content">
              <h3>Your Partner in Digital Transformation</h3>
              <p>
                At Groot Software Solutions, we offer top-tier consulting and
                strategy services to drive your digital transformation journey.
                With our expertise and guidance, we help businesses navigate the
                complexities of the digital landscape and achieve their
                strategic goals.
              </p>
              <h3 class="mt-3">Why Choose Us?</h3>
              <ul>
                <li class="text-li">
                  <i class="far fa-check"></i>
                  Strategic Insight: Our team of consultants brings deep
                  industry knowledge and strategic insight to help you identify
                  opportunities and overcome challenges.
                </li>
                <li class="text-li">
                  <i class="far fa-check"></i>
                  Customized Solutions: We understand that every business is
                  unique. That's why we tailor our consulting services to your
                  specific needs and objectives.
                </li>
                <li class="text-li">
                  <i class="far fa-check"></i>
                  Holistic Approach: We take a holistic approach to digital
                  transformation, considering factors such as technology,
                  processes, and people to ensure comprehensive solutions.
                </li>

                <li class="text-li">
                  <i class="far fa-check"></i>
                  Proven Track Record: With a track record of successful
                  projects and satisfied clients, we have earned a reputation as
                  a trusted partner in digital transformation.
                </li>
                <li class="text-li">
                  <i class="far fa-check"></i>
                  Long-Term Partnership: We're not just here to solve immediate
                  problems – we're committed to building long-term partnerships
                  and supporting your ongoing success.
                </li>
              </ul>
              <div class="seperation mt-5 mb-4"></div>
              <div class="service-details-video mt-5">
                <div class="row g-4 align-items-center">
                  <div class="col-lg-12 mt-0">
                    <div class="details-video-content">
                      <h4 class="mt-3">Service Offering</h4>
                      <p>
                        Leverage our expertise to drive transformation goals and
                        continuous improvement across industrial verticals.
                      </p>
                      <h4 class="">Inclusions:</h4>
                      <ul>
                        <li>
                          <i class="far fa-check"></i>
                          Strategic planning and roadmapping
                        </li>
                        <li>
                          <i class="far fa-check"></i>
                          Digital maturity assessment
                        </li>

                        <li>
                          <i class="far fa-check"></i>
                          Technology selection and implementation strategy
                        </li>
                        <li>
                          <i class="far fa-check"></i>
                          Change management and organizational alignment
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Applicability section start-->
            <div class="seperation mt-5 mb-4"></div>
            <div class="Applicability mt-5">
              <div class="row g-4 align-items-center">
                <div class="col-lg-12 mt-0">
                  <div class="details-video-content">
                    <h4>Applicability:</h4>
                    <p style="margin-top: 20px">
                      Our maintenance and support services are applicable to
                      various types of software applications and systems,
                      including:
                    </p>
                    <br />
                    <h6><b>Use Cases:-</b></h6>
                    <ul
                      class="detail"
                      style="
                        list-style-type: circle;
                        margin-left: 10px;
                        padding: 20px;
                      "
                    >
                      <li>
                        <p style="color: black">
                          <b>Web Applications: -</b> Security Patching
                        </p>
                      </li>
                      <p style="font-size: 15px; padding: 2px">
                        -> Regularly apply security patches and updates to web
                        applications to address vulnerabilities and protect
                        against cyber threats such as malware, hacking, and data
                        breaches.
                      </p>
                      <li>
                        <p style="color: black">
                          <b>Mobile Applications:-</b> OS Compatibility Updates
                        </p>
                      </li>
                      <p style="font-size: 15px; padding: 2px">
                        -> Update mobile applications to ensure compatibility
                        with the latest operating system (OS) versions and
                        devices, preventing compatibility issues and ensuring a
                        seamless user experience.
                      </p>
                      <li>
                        <p style="color: black">
                          <b>Enterprise Software Systems:-</b>
                          Performance Tuning
                        </p>
                      </li>
                      <p style="font-size: 15px; padding: 2px">
                        -> Optimize the performance of enterprise software
                        systems by identifying and resolving bottlenecks,
                        inefficiencies, and scalability issues to improve
                        response times and user satisfaction.
                      </p>
                      <li>
                        <p style="color: black">
                          <b>E-commerce Platforms:-</b> Payment Gateway
                          Integration Support
                        </p>
                      </li>
                      <p style="font-size: 15px; padding: 2px">
                        -> Provide technical support and assistance for
                        integrating and troubleshooting payment gateways on
                        e-commerce platforms to ensure smooth and secure online
                        transactions for customers.
                      </p>
                      <li>
                        <p style="color: black">
                          <b>Content Management Systems:-</b> Plugin Updates and
                          Compatibility Checks
                        </p>
                      </li>
                      <p style="font-size: 15px; padding: 2px">
                        -> Regularly update and maintain plugins and extensions
                        for content management systems (CMS) to ensure
                        compatibility with core software updates and prevent
                        conflicts or errors.
                      </p>
                      <li>
                        <p style="color: black">
                          <b>Customer Relationship Management Systems:-</b> Data
                          Migration and Integration Support
                        </p>
                      </li>
                      <p style="font-size: 15px; padding: 2px">
                        -> Assist with data migration and integration tasks for
                        customer relationship management (CRM) systems, ensuring
                        seamless transition and continuity of customer data and
                        workflows.
                      </p>
                      <li>
                        <p style="color: black">
                          <b>Database Management Systems:-</b> Backup and
                          Recovery Management
                        </p>
                      </li>
                      <p style="font-size: 15px; padding: 2px">
                        -> Implement and manage backup and recovery solutions
                        for database management systems (DBMS) to safeguard
                        critical data and ensure business continuity in the
                        event of data loss or system failure.
                      </p>
                      <li>
                        <p style="color: black">
                          <b>Custom Software Solutions:-</b> User Training and
                          Documentation
                        </p>
                      </li>
                      <p style="font-size: 15px; padding: 2px">
                        -> Provide user training and documentation for custom
                        software solutions to help users understand and
                        effectively utilize the software's features and
                        functionalities, maximizing productivity and user
                        satisfaction.
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="seperation mt-5 mb-4"></div>
            <h3>Frequently Asked Questions</h3>
            <div class="faq-content">
              <div class="faq-accordion">
                <div class="accordion" id="accordion">
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".3s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq1"
                        aria-expanded="false"
                        aria-controls="faq1"
                      >
                        What is included in your maintenance and support
                        services?
                      </button>
                    </h4>
                    <div
                      id="faq1"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        Our maintenance and support services include bug fixing,
                        troubleshooting, software updates, patches, performance
                        optimization, technical assistance, and guidance to
                        ensure the continuous performance, security, and
                        usability of your software applications.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".5s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        How do you prioritize and respond to support requests?
                      </button>
                    </h4>
                    <div
                      id="faq2"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        We prioritize support requests based on severity, impact
                        on business operations, and urgency. We have established
                        response time targets for different priority levels and
                        strive to address support requests promptly and
                        effectively..
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".7s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq3"
                        aria-expanded="false"
                        aria-controls="faq3"
                      >
                        Do you offer ongoing software updates and patches?
                      </button>
                    </h4>
                    <div
                      id="faq3"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        Yes, we provide ongoing software updates and patches to
                        address security vulnerabilities, bugs, performance
                        issues, and compatibility issues, ensuring that your
                        software remains secure, stable, and up-to-date
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".9s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq4"
                        aria-expanded="false"
                        aria-controls="faq4"
                      >
                        Can you assist with performance optimization for
                        software systems?
                      </button>
                    </h4>
                    <div
                      id="faq4"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        Yes, we offer performance optimization services to
                        identify and resolve bottlenecks, inefficiencies, and
                        scalability issues in software systems, improving
                        response times, throughput, and user satisfaction.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".9s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq5"
                        aria-expanded="false"
                        aria-controls="faq5"
                      >
                        How do you ensure customer satisfaction with your
                        maintenance and support services?
                      </button>
                    </h4>
                    <div
                      id="faq5"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        We prioritize customer satisfaction by providing
                        responsive support, timely resolutions, proactive
                        guidance, and personalized assistance tailored to each
                        customer's needs and requirements. We continuously seek
                        feedback and strive to improve our services based on
                        customer input and expectations.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="main-sidebar">
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3>Related Services</h3>
              </div>
              <div class="service-category">
                <a
                  [routerLink]="['/strategy-and-transformation']"
                  routerLinkActive="active"
                >
                  Strategy and Transformation

                  <i class="fal fa-long-arrow-right"></i>
                </a>
                <a
                  [routerLink]="['/core-engineering']"
                  routerLinkActive="active"
                >
                  Core Engineering
                  <i class="fal fa-long-arrow-right"></i>
                </a>
                <a
                  [routerLink]="['/business-&-digitalSolutions']"
                  routerLinkActive="active"
                >
                  Business & Digital Solutions
                  <i class="fal fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            <div
              class="single-sidebar-widget bg-info"
              style="border-radius: 20px"
            >
              <div class="wid-title">
                <h3 style="color: white">Applications and Technology</h3>
              </div>
              <div class="service-category">
                <a
                  [routerLink]="['/software-testing_QA']"
                  routerLinkActive="active"
                >
                  Software Testing & QA

                  <i class="fal fa-long-arrow-right"></i>
                </a>

                <a
                  [routerLink]="['/modernization_and_migration']"
                  routerLinkActive="active"
                >
                  Modernization and Migration

                  <i class="fal fa-long-arrow-right"></i>
                </a>
                <a
                  [routerLink]="['/web-application-development']"
                  routerLinkActive="active"
                >
                  Web Application Development

                  <i class="fal fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3>Contact us to kickstart your innovation journey</h3>
              </div>

              <app-commonenquiryform></app-commonenquiryform>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
