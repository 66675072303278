import { Component } from '@angular/core';

@Component({
  selector: 'naveen-tiwari',
  templateUrl: './naveen-tiwari.component.html',
  styleUrls: ['./naveen-tiwari.component.scss']
})

export class NaveenTiwariComponent {
  constructor() { }
}