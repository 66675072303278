import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'erp-crm',
  templateUrl: './digital-wallets-cryptocurrency.component.html',
  styleUrls: ['./digital-wallets-cryptocurrency.component.scss']
})
export class DigitalWalletsCryptocurrencyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
