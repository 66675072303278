import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiServices } from 'src/app/api-services';
import { email } from 'src/app/models/email';
import { ValidateEmail } from 'src/app/validation';
import { notificationTypes, commonErrorMessage } from 'src/utils/constants';
import { ShowNotificationsService } from './show-notifications.service';
import { serviceBoxes } from 'src/app/servicedata';

declare var $: any; // Declare jQuery globally

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  serviceBoxes = serviceBoxes;
  submitted = false;
  contactForm: FormGroup;
  enquiryForm: FormGroup;
  currentSlideIndex: number = 0;
  autoSlideInterval: any; // Variable to hold the interval

  @ViewChild('testcount') testcount!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiServices,
    private router: Router,
    private showNotificationService: ShowNotificationsService
  ) {
    this.initForm();
  }

  serviceCategories = [
    {
      title: 'Strategy and Transformation',
      links: [
        { text: 'Consulting & Strategy', route: '/consulting-and-strategy' },
        { text: 'Cloud Computing', route: '/cloud-computing' },
        {
          text: 'Blockchain Software Development',
          route: '/blockchain-software-development',
        },
        { text: 'Internet of Things (IoT)', route: '/internet-of-things' },
        {
          text: 'Artificial Intelligence',
          route: '/artificial-intelligence_machine-learning',
        },
      ],
    },
    {
      title: 'Business & Digital Solutions',
      links: [
        { text: 'ERP & CRM', route: '/erp-crm' },
        { text: 'Digital Acceleration', route: '/digital-acceleration' },
        { text: 'Business Intelligence', route: '/business-intelligence' },
        { text: 'Process Automation', route: '/process-automation' },
        {
          text: 'Digital Wallets & Cryptocurrency',
          route: '/digital-wallets-cryptocurrency',
        },
        { text: 'Data Science', route: '/data-science' },
        { text: 'Database Development', route: '/database-development' },
      ],
    },
    {
      title: 'Core Engineering',
      links: [
        {
          text: 'Custom Software Development',
          route: '/custom-software-development',
        },
        { text: 'Front-End Development', route: '/front-end-development' },
        { text: 'Back-End Development', route: '/back-end-development' },
        { text: 'IOS App Development', route: '/ios-app-devlopment' },
        { text: 'Android App Development', route: '/android-app-development' },
        { text: 'UI/UX Design', route: '/ui-ux-design' },
        { text: 'MVP Development', route: '/mvp-development' },
        {
          text: 'Progressive Web Applications',
          route: '/progressive-web-application',
        },
        { text: 'E-commerce Development', route: '/e-commerce-development' },
      ],
    },
    {
      title: 'Applications and Technology',
      links: [
        { text: 'Software Testing & QA', route: '/software-testing_QA' },
        { text: 'Maintenance & Support', route: '/maintenance_support' },
        {
          text: 'Modernization and Migration',
          route: '/modernization_and_migration',
        },
        {
          text: 'Web Application Development',
          route: '/web-application-development',
        },
      ],
    },
  ];

  dropDownService: { key: string; value: string }[] = [
    { key: 'consulting_strategy', value: 'Consulting & Strategy' },
    { key: 'cloud_computing', value: 'Cloud Computing' },
    { key: 'blockchain_dev', value: 'Blockchain Software Development' },
    { key: 'iot', value: 'Internet of Things' },
    { key: 'ai_ml', value: 'Artificial Intelligence & Machine Learning' },
    { key: 'testing_qa', value: 'Software Testing & QA' },
    { key: 'maintenance_support', value: 'Maintenance & Support' },
    { key: 'modernization_migration', value: 'Modernization and Migration' },
    { key: 'web_app_dev', value: 'Web Application Development' },
    { key: 'custom_dev', value: 'Custom Software Development' },
    { key: 'front_end_dev', value: 'Front-End Development' },
    { key: 'back_end_dev', value: 'Back-End Development' },
    { key: 'ios_app_dev', value: 'iOS App Development' },
    { key: 'android_app_dev', value: 'Android App Development' },
    { key: 'ui_ux_design', value: 'UI/UX Design' },
    { key: 'mvp_dev', value: 'MVP Development' },
    { key: 'pw_as', value: 'Progressive Web Applications (PWAs)' },
    { key: 'ecommerce_dev', value: 'E-commerce Development' },
    { key: 'erp_crm', value: 'ERP & CRM' },
    { key: 'digital_acceleration', value: 'Digital Acceleration' },
    { key: 'business_intelligence', value: 'Business Intelligence' },
    { key: 'process_automation', value: 'Process Automation' },
    { key: 'wallets_crypto', value: 'Digital Wallets & Cryptocurrency' },
    { key: 'data_science', value: 'Data Science' },
    { key: 'db_dev', value: 'Database Development' },
  ];

  testimonials: Testimonial[] = [
    {
      text: 'Groot Software Solutions transformed our business with their expertise in AI and machine learning. Their solutions helped us automate processes and make data-driven decisions, leading to remarkable growth.',
      author: 'Sarah Johnson',
      position: ', CEO of VisionaryTech',
    },
    {
      text: 'We partnered with Groot Software Solutions for our digital transformation journey, and it was a game-changer. Their team provided customized strategies and flawless execution, helping us stay ahead in the competitive landscape.',
      author: 'Michael Anderson',
      position: ', CTO of InnovateCorp',
    },
    {
      text: 'Working with Groot Software Solutions was a breeze. They not only developed a custom software solution for us but also provided invaluable insights and guidance throughout the process. Their commitment to post-development support is commendable.',
      author: 'Emily Chen',
      position: ', COO of NexusTech',
    },
    {
      text: "Groot Software Solutions exceeded our expectations with their web application development services. They understood our brand's vision perfectly and delivered a user-friendly interface that resonated well with our audience.",
      author: 'David Patel',
      position: ', Marketing Manager of GrowthX',
    },
    {
      text: 'The team at Groot Software Solutions made the modernization of our legacy systems seamless. Their expertise in cloud computing and migration ensured a smooth transition, with minimal disruption to our operations.',
      author: 'Jessica Taylor',
      position: ', IT Director of GlobalTech Solutions',
    },
    {
      text: 'Groot Software Solutions leverages its expertise in cloud computing to architect and implement a scalable infrastructure for our e-commerce platform. Their solutions not only improved performance but also enhanced our ability to handle peak traffic loads seamlessly.',
      author: 'Neharika Patel',
      position: ', CTO of DigitalMart Solutions',
    },
    {
      text: 'Groot Software Solutions revolutionized our data management system with their expertise in cloud computing. Their scalable and efficient cloud infrastructure enhanced our data storage and processing capabilities, allowing us to handle large volumes of data seamlessly.',
      author: 'Sarah Chang',
      position: ', Data Engineer at TechOps Inc.',
    },
  ];

  ngOnInit() {
    // Start auto-sliding when component initializes
    this.startAutoSlide();
    this.newCarouselScript();
  }

  ngOnDestroy() {
    this.stopAutoSlide(); // Stop auto-sliding when component is destroyed
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, ValidateEmail]],
      service: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  ontestCountSelected(value: string) {
    console.log('onOptionsSelected', value);
    //this.serviceName.setValue(e.target.value)
  }

  // Getter method to access formcontrols
  get serviceName() {
    return this.contactForm.get('service');
  }

  //for contactForm control
  get f() {
    return this.contactForm.controls;
  }

  //onsubmit function
  onSubmit(form: FormGroup) {
    this.submitted = true;

    if (this.testcount?.nativeElement?.value != '') {
      this.contactForm.controls['service'].setValue(
        this.testcount?.nativeElement?.value
      );
    }

    if (this.contactForm.invalid) {
      return;
    }

    const emailData: email = {
      firstName: this.contactForm.value.name,
      userEmail: this.contactForm.value.email,
      message: this.contactForm.value.message,
      companyName: '',
      lastName: '',
      services: this.contactForm.value.service,
      phoneNumber: '',
    };
    this.sendEmail(emailData);
  }

  sendEmail(emailData: email) {
    this.apiService.sendEmail(emailData, '').subscribe(
      (data: any) => {
        const { responseCode, responseMessage } = data;
        if (responseCode === 200) {
          this.showNotificationService.showNotification(
            notificationTypes.success,
            responseMessage
          );
          this.contactForm.reset();
          this.submitted = false;
        } else {
          this.showNotificationService.showNotification(
            notificationTypes.error,
            commonErrorMessage.somethingWentWrong
          );
        }
      },
      (error) => {
        this.showNotificationService.showNotification(
          notificationTypes.error,
          commonErrorMessage.somethingWentWrong
        );
      }
    );
  }

  nextSlide() {
    this.currentSlideIndex =
      (this.currentSlideIndex + 1) % this.testimonials.length;
  }

  prevSlide() {
    this.currentSlideIndex =
      (this.currentSlideIndex - 1 + this.testimonials.length) %
      this.testimonials.length;
  }

  startAutoSlide() {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide(); // Automatically move to the next slide
    }, 5000); // Change slide every 5 seconds (adjust as needed)
  }

  stopAutoSlide() {
    clearInterval(this.autoSlideInterval); // Stop the auto-slide interval
  }

  newCarouselScript() {
    this.loadScript('assets/js/slick.min.js')
      .then(() => {
        $('.news-carousel-active').slick({
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 800,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          dotsClass: 'slide-dots',
          responsive: [
            {
              breakpoint: 1399,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1191,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                center: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });

        // Hide Next and Previous buttons after a short delay
        setTimeout(() => {
          $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
        }, 100); // Adjust the delay as needed
      })
      .catch((error) => console.log('Script loading error', error));
  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }
}

interface Testimonial {
  text: string;
  author: string;
  position: string;
}
