<div *ngIf="!isAdmin()">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>

<div *ngIf="isAdmin()">
  <admin-layout>
    <router-outlet></router-outlet>
  </admin-layout>
</div>

<!-- <div *ngIf="isAdmin() && !isLoggedIn()">
    <router-outlet></router-outlet>
</div> -->
