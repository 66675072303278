<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('assets/img/breadcrumb.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Success Stories</h1>
    </div>
  </div>
</div>

<!--<< About Section Start >>-->

<section class="case-study-2 fix section-padding pt-5">
  <div class="container">
    <div class="section-title style-2 text-center">
      <span class="wow fadeInUp">Turning dreams into digital reality</span>
      <h2 class="wow fadeInUp" data-wow-delay=".3s">
        Crafting success stories that
        <span> inspire and drive</span>
        businesses forward
      </h2>
    </div>
  </div>
  <div class="case-study-wrapper">
    <div class="case-studies-carousel-active-2">
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/05.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a href="project-details.html">Professional Website Design</a>
          </h3>
          <p>Web Design & Development</p>
          <a href="project-details.html" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/06.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a href="project-details.html">Professional Website Design</a>
          </h3>
          <p>Web Design & Development</p>
          <a href="project-details.html" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/07.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a href="project-details.html">Professional Website Design</a>
          </h3>
          <p>Web Design & Development</p>
          <a href="project-details.html" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Brand Section Start >>-->

<section
  class="contact-section-2 fix section-padding bg-cover"
  style="background-image: url('assets/img/contact-bg.jpg')"
>
  <div class="contact-image">
    <img src="assets/img/contact-2.png" alt="contact-img" />
  </div>
  <div class="container">
    <div class="contact-wrapper-2">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="contact-content">
            <div class="section-title style-2">
              <span class="wow fadeInUp">Contact Us</span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                Ready to start the conversation? We're all ears.
              </h2>
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              Whether it's a brainstorm, a question, or a bold idea waiting to
              be unleashed, we're eager to dive into the discussion with
              you.Please fill the form or send us an email
              info&#64;grootsoftwares.com
            </p>
            <div class="contact-info-area">
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div class="icon">
                  <i class="far fa-phone"></i>
                </div>
                <div class="content">
                  <p>Call For Inquiry</p>
                  <h3><a href="tel:+01762460300">0176-2460300</a></h3>
                </div>
              </div>
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".8s"
              >
                <div class="icon">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="content">
                  <p>Send Us Email</p>
                  <h3>
                    <a href="mailto:info@grootsoftwares.com"
                      >info&#64;grootsoftwares.com</a
                    >
                  </h3>
                </div>
              </div>
              <div class="contact-info-items wow fadeInUp" data-wow-delay=".9s">
                <div class="icon">
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div class="content">
                  <p>Location</p>
                  <h3>
                    Sushma Infinium, 3rd Floor, Zirakpur(Chandigarh), Punjab,
                    140603
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="contact-box">
            <div class="contact-title">
              <h3 class="wow fadeInUp" data-wow-delay=".3s">
                Please send a message to get the conversation started
              </h3>
              <p class="wow fadeInUp" data-wow-delay=".5s">
                Let's turn potential into action
              </p>
            </div>
            <div class="contact-form-items">
              <form>
                <div class="row g-3">
                  <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                    <div class="form-clt">
                      <input
                        type="text"
                        placeholder="First Name"
                        formControlName="name"
                      />
                      <div class="icon">
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                    <div class="form-clt">
                      <input
                        type="email"
                        placeholder="Last Name"
                        formControlName="email"
                      />
                      <div class="icon">
                        <i class="far fa-envelope"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                    <div class="form-clt">
                      <input
                        type="text"
                        placeholder="Company Email"
                        formControlName="name"
                      />
                      <div class="icon">
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                    <div class="form-clt">
                      <input
                        type="email"
                        placeholder="Company Number"
                        formControlName="email"
                      />
                      <div class="icon">
                        <i class="far fa-phone"></i>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                    <div class="form-clt">
                      <input
                        type="Company"
                        placeholder="Company"
                        formControlName="email"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 wow fadeInUp" data-wow-delay=".4s">
                    <button type="submit" class="theme-btn theme-btn-2">
                      <span>
                        Submit
                        <i class="fas fa-chevron-right"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
