<!--<< About  Mohit Sharma Section Start >>-->

<section class="team-details-section fix pt-5">
  <div class="container">
    <div
      class="about-Mohit"
      style="
        padding: 30px;
        background-image: url(../../../../assets/img/team/img-ow.jpg);
      "
    >
      <div class="row justify-content-between align-items-center">
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay=".3s"
          style="
            visibility: visible;
            animation-delay: 0.3s;
            animation-name: fadeInUp;
          "
        >
          <div class="circular--portrait">
            <img
              class="img-fluid"
              src="assets/img/team/04.jpg"
              alt=" pic"
              height="450px"
            />
          </div>
        </div>
        <div
          class="col-lg-6 mt-5 mt-lg-0 wow fadeInUp"
          data-wow-delay=".5s"
          style="
            visibility: visible;
            animation-delay: 0.5s;
            animation-name: fadeInUp;
          "
        >
          <div class="team-details-content">
            <h2>Mohit Sharma</h2>
            <br />

            <h3>Meet Mohit Sharma</h3>
            <br />

            <p>
              Mohit Sharma, an Enterprise Software Consultant with over 20 years
              of experience, leads Groot Software Solutions. His expertise lies
              in Business Intelligence Solutions, Enterprise Application
              Development, and Software Development Lifecycle Management,
              ensuring end-to-end solutions tailored to your business needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--<< Service Details Section Start >>-->
<section class="service-details fix section-padding pt-5">
  <div class="container">
    <div class="service-details-wrapper">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="seperation mt-5 mb-4"></div>
          <div class="service mt-5">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 mt-0">
                <div class="service-detail">
                  <h4>Services</h4>

                  <ul
                    class="detail"
                    style="list-style-type: circle; padding: 10px"
                  >
                    <li>
                      <p style="color: black">
                        <b>Custom Software Development: </b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Comprehensive solutions designed to address your
                      specific business challenges, from concept to deployment.
                    </p>
                    <li>
                      <p style="color: black">
                        <b>Web and Mobile App Development: </b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Intuitive and user-friendly applications that enhance
                      user experience and drive engagement.
                    </p>
                    <li>
                      <p style="color: black"><b>Enterprise Solutions:</b></p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Robust and scalable applications that streamline
                      operations and drive efficiency across your organization.
                    </p>
                    <li>
                      <p style="color: black">
                        <b>Data Management and Business Intelligence:</b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Harnessing the power of data to gain actionable
                      insights and drive informed decision-making.
                    </p>
                    <li>
                      <p style="color: black"><b>E-commerce Solutions:</b></p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Secure and feature-rich online platforms that enable
                      seamless transactions and enhance customer satisfaction.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="service-details-items">
            <!-- <div class="details-image">
                            <img src="assets/img/service/details-1.jpg" alt="img">
                        </div> -->
            <div class="details-content">
              <h3 class="mt-3">Why Choose Us?</h3>
              <ul>
                <li class="text-li">
                  <h6>Expertise:</h6>
                  Decades of experience and a deep understanding of industry
                  best practices, ensuring top-quality solutions that drive
                  results.
                </li>
                <li class="text-li">
                  <h6>Customization:</h6>
                  Tailored solutions designed to meet your unique business needs
                  and objectives, delivering maximum value and impact.
                </li>
                <li class="text-li">
                  <h6>Innovation:</h6>
                  Continuously evolving and staying ahead of the curve with the
                  latest technologies and trends, ensuring your solutions are
                  always cutting-edge.
                </li>
              </ul>
            </div>

            <div class="seperation mt-5 mb-4"></div>
            <h3>Our Expertise in Action</h3>
            <br />
            <p>
              At Groot Software Solutions, we pride ourselves on delivering
              innovative solutions tailored to your unique business needs. Here
              are some examples of how our directors' expertise translates into
              real-world use cases:
            </p>
            <div class="row pt-5">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/Mohit-use-cases1.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Configuration Management Database
                        </div>
                        <p class="image__description">
                          Develop a CMDB solution for IT teams to manage
                          configuration data, track assets, and analyze
                          infrastructure changes for effective IT service
                          management.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/Mohit-use-cases2.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          IT Service Management (ITSM) Portal
                        </div>
                        <p class="image__description">
                          Design an ITSM portal for managing IT service
                          requests, incident tickets, and service level
                          agreements (SLAs) to streamline IT service delivery
                          processes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/Mohit-use-cases3.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">Task Management System</div>
                        <p class="image__description">
                          Create a task management system for organizing and
                          tracking tasks, projects, and deadlines, facilitating
                          collaboration and productivity within teams.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/Mohit-use-cases4.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          ROperational Support System (OSS)
                        </div>
                        <p class="image__description">
                          evelop an OSS solution for telecommunication companies
                          to automate order processing, billing, and network
                          management for enhanced operational efficiency.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/Mohit-use-cases5.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Enterprise Resource Planning (ERP) System
                        </div>
                        <p class="image__description">
                          Design an ERP system for integrating and managing core
                          business processes, including finance, HR, inventory,
                          and supply chain management.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/Mohit-use-cases6.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Customer Support Ticketing System
                        </div>
                        <p class="image__description">
                          Build a customer support ticketing system for tracking
                          and resolving customer inquiries and issues, ensuring
                          timely and efficient support services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 30px">
              <h3>Looking for Something Else?</h3>
              <br />
              <p>
                If you have a project idea in mind or need assistance with a
                specific requirement, we're here to help. Get in touch with us
                today to discuss your project needs and explore how Groot
                Software Solutions can bring your vision to life.
              </p>
              <br />
              <div class="col-lg-12">
                <button
                  [routerLink]="['/enquiry']"
                  class="theme-btn"
                  type="submit"
                >
                  <span>
                    Get in Touch
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="main-sidebar">
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">Engagement Models</h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample1"
                    >
                      Fixed Price
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample1">
                    <div class="card card-body">
                      Clear project scopes and budgets for predictable outcomes
                      and peace of mind.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample2"
                    >
                      Time and Material
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample2">
                    <div class="card card-body">
                      Flexibility to accommodate evolving project requirements
                      and ensure optimal results.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn3"
                      data-bs-toggle="collapse"
                      href="#collapseExample3"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample3"
                    >
                      Dedicated Team
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample3">
                    <div class="card card-body">
                      Access to a dedicated team of experts committed to your
                      project's success, providing ongoing support and
                      collaboration.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">Featured Projects</h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample4"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample4"
                    >
                      CMDB (Configuration Management Database)
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample4">
                    <div class="card card-body">
                      Empower your IT teams with a comprehensive CMDB solution
                      for efficient server metadata management and analysis.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample5"
                    >
                      ITSM (IT Service Management)
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample5">
                    <div class="card card-body">
                      Streamline IT service management with a portal for case
                      submission and dashboards, integrating with BMC Remedy for
                      seamless operations.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">
                  Discover More with Groot Software Solutions
                </h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample6"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample6"
                    >
                      Industry Experience
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample6">
                    <div class="card card-body">
                      With a wealth of experience in various domains, including
                      telecommunications, healthcare, and finance, we bring a
                      deep understanding of industry-specific challenges and
                      requirements.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample7"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample7"
                    >
                      Client Testimonials
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample7">
                    <div class="card card-body">
                      Hear from our satisfied clients about their experiences
                      working with Groot Software Solutions and the impact our
                      solutions have had on their businesses.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample8"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample8"
                    >
                      Partnerships
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample8">
                    <div class="card card-body">
                      We collaborate with industry-leading technology providers
                      to deliver best-in-class solutions that exceed
                      expectations.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <form
                action="#"
                id="contact-form"
                class="message-form"
                style="
                  background-color: #00c0da;
                  padding: 20px;
                  border-radius: 30px;
                  padding-bottom: 50px;
                "
              >
                <div class="row g-3">
                  <div class="wid-title">
                    <h3 style="color: white; text-align: center; padding: 8px">
                      Contact us
                    </h3>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <a href="mailto:info@grootsoftwares.com">
                        <li
                          style="
                            background-color: white;
                            padding: 10px;
                            list-style: none;
                            border-radius: 3px;
                          "
                        >
                          <i class="fas fa-envelope"></i>
                          info&#64;grootsoftwares.com
                        </li>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <a href="tel:+01762460300">
                        <li
                          style="
                            background-color: white;
                            padding: 10px;
                            list-style: none;
                            border-radius: 3px;
                          "
                        >
                          <i class="fas fa-mobile-alt"></i> 0176-2460300
                        </li>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <li
                        style="
                          background-color: white;
                          padding: 10px;
                          list-style: none;
                          border-radius: 3px;
                        "
                      >
                        <i class="fas fa-map-marker-alt"></i> United States │
                        India
                      </li>
                    </div>
                  </div>
                </div>
              </form>

              <app-commonportfoliocomponent></app-commonportfoliocomponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
