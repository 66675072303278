import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-web-development',
  templateUrl: './custom-web-development.component.html',
  styleUrls: ['./custom-web-development.component.scss']
})
export class CustomWebDevelopmentComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {

  }


}
