import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-management-and-strategy',
  templateUrl: './product-management-and-strategy.component.html',
  styleUrls: ['./product-management-and-strategy.component.scss']
})
export class ProductManagementAndStrategyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
