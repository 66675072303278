import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-testing_QA',
  templateUrl: './software-testing_QA.component.html',
  styleUrls: ['./software-testing_QA.component.scss']
})
export class SoftwareTestingQAComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
