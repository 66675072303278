<div
  class="breadcrumb-wrapper section-padding m-0 bg-cover"
  style="background-image: url('../../../assets/img/bg-5.jpeg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Awards & Recognisation</h1>
    </div>
  </div>
</div>
<div class="container-fluid certificate" style="background-color: #f6f6f6">
  <h6 class="text-center wow fadeInUp" data-wow-delay=".3s">BRANDS TRUST US</h6>
  <div class="row mt-3 m-0">
    <div class="col-12">
      <div class="row m-0">
        <div class="col-md-4 mt-3 text-center">
          <div class="bg-certificate py-4">
            <img
              class="img-fluid fluid-no"
              src="../../../assets/img/certificate-1.jpg"
              alt="brand-img"
              width="400px"
            />
          </div>
        </div>
        <div class="col-md-4 mt-3 text-center">
          <div class="bg-certificate py-4">
            <img
              class="img-fluid fluid-no"
              src="../../../assets/img/certificate-2.png"
              alt="brand-img"
              width="400px"
            />
          </div>
        </div>
        <div class="col-md-4 mt-3 text-center">
          <div class="bg-certificate py-4">
            <img
              class="img-fluid fluid-no"
              src="../../../assets/img/certificate-3.jpg"
              alt="brand-img"
              width="400px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="counter-section section-padding pt-0 section-bg mt-0">
  <div class="line-shape">
    <img src="assets/img/line.png" alt="shape-img" />
  </div>
  <div class="container">
    <div class="counter-wrapper">
      <div class="row g-4">
        <div
          class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div class="counter-items text-center">
            <div class="icon center">
              <i class="flaticon-earth-globe"></i>
            </div>
            <div class="content">
              <h2><span class="count">50</span>+</h2>
              <p>
                Countries <br />
                Represented
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay=".5s"
        >
          <div class="counter-items text-center">
            <div class="icon center">
              <i class="flaticon-verified"></i>
            </div>
            <div class="content">
              <h2><span class="count">80</span>+</h2>
              <p>
                Completed <br />
                Projects
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay=".7s"
        >
          <div class="counter-items text-center">
            <div class="icon center">
              <i class="flaticon-financial-profit"></i>
            </div>
            <div class="content">
              <h2><span class="count">55</span>+</h2>
              <p>
                Revenue <br />
                per year
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
          data-wow-delay=".9s"
        >
          <div class="counter-items text-center">
            <div class="icon center">
              <i class="flaticon-infrastructure"></i>
            </div>
            <div class="content">
              <h2><span class="count">50</span>+</h2>
              <p>
                Experienced <br />
                Team Members
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div class="marque-section section-padding pt-0 mt-5">
  <div class="marquee-wrapper text-slider">
    <div class="marquee-inner to-left">
      <ul class="marqee-list d-flex">
        <li class="marquee-item">
          <span class="text-slider">arketing</span
          ><span class="text-slider">Eit</span
          ><span class="text-slider style-border"></span>
          <span class="text-slider">Co</span
          ><span class="text-slider">advisor</span
          ><span class="text-slider style-border"></span
          ><span class="text-slider">it</span
          ><span class="text-slider">consultancy</span
          ><span class="text-slider style-border"></span
          ><span class="text-slider">Data</span
          ><span class="text-slider">recover</span>
          <span class="text-slider">arketing</span
          ><span class="text-slider">Eit</span
          ><span class="text-slider style-border"></span>
          <span class="text-slider">Co</span
          ><span class="text-slider">advisor</span
          ><span class="text-slider style-border"></span
          ><span class="text-slider">it</span
          ><span class="text-slider">consultancy</span
          ><span class="text-slider style-border"></span
          ><span class="text-slider">Data</span
          ><span class="text-slider">recover</span>
          <span class="text-slider">arketing</span
          ><span class="text-slider">Eit</span
          ><span class="text-slider style-border"></span>
          <span class="text-slider">Co</span
          ><span class="text-slider">advisor</span
          ><span class="text-slider style-border"></span
          ><span class="text-slider">it</span
          ><span class="text-slider">consultancy</span
          ><span class="text-slider style-border"></span
          ><span class="text-slider">Data</span
          ><span class="text-slider">recover</span>
        </li>
      </ul>
    </div>
  </div>
</div> -->

<section class="cta-chat-section section-padding pt-0">
  <div class="container">
    <div class="cta-chat-wrapper">
      <div class="chat-items wow fadeInUp" data-wow-delay=".3s">
        <div class="icon">
          <i class="flaticon-chat"></i>
        </div>
        <div class="content">
          <h3>Got a project in mind?</h3>
          <p>Get started now.</p>
        </div>
      </div>
      <a
        [routerLink]="['/enquiry']"
        class="theme-btn bg-white wow fadeInUp"
        data-wow-delay=".5s"
      >
        <span>
          Let's Talk
          <i class="fas fa-chevron-right"></i>
        </span>
      </a>
    </div>
  </div>
</section>
