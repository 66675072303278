import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digital-acceleration',
  templateUrl: './digital-acceleration.component.html',
  styleUrls: ['./digital-acceleration.component.scss']
})
export class DigitalAccelerationComponent  implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
