import { Component } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Observable, Observer } from 'rxjs';

import { NzSafeAny } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'single-blognz-demo-form-auto-tips',
  templateUrl:"./add-blog.component.html",
  styleUrl:"./add-blog.component.scss",
})
export class AddBlogComponent {
  validateForm: FormGroup<{
    title: FormControl<string>;
    keywords: FormControl<string>;
    category: FormControl<string>;
    description: FormControl<string>;
  }>;

  // current locale is key of the nzAutoTips
  // if it is not found, it will be searched again with `default`
  autoTips: Record<string, Record<string, string>> = {
    default: {
      title: 'The input is not valid title'
    }
  };

  submitForm(): void {
    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
}



constructor(private fb: NonNullableFormBuilder) {
  // use `MyValidators`
  const { required, maxLength, minLength, email, mobile } = MyValidators;
  this.validateForm = this.fb.group({
    title: ['', [required, minLength(6)]],
    description: ['', [required, minLength(6)]],
    keywords: ['', [required, minLength(6)]],
    category: ['', [required]],
  });
}
}
// current locale is key of the MyErrorsOptions
export type MyErrorsOptions = { 'zh-cn': string; en: string } & Record<string, NzSafeAny>;
export type MyValidationErrors = Record<string, MyErrorsOptions>;

export class MyValidators extends Validators {
static override minLength(minLength: number): ValidatorFn {
  return (control: AbstractControl): MyValidationErrors | null => {
    if (Validators.minLength(minLength)(control) === null) {
      return null;
    }
    return { minlength: { 'zh-cn': ` ${minLength}`, en: `MinLength is ${minLength}` } };
  };
}

static override maxLength(maxLength: number): ValidatorFn {
  return (control: AbstractControl): MyValidationErrors | null => {
    if (Validators.maxLength(maxLength)(control) === null) {
      return null;
    }
    return { maxlength: { 'zh-cn': ` ${maxLength}`, en: `MaxLength is ${maxLength}` } };
  };
}

static mobile(control: AbstractControl): MyValidationErrors | null {
  const value = control.value;

  if (isEmptyInputValue(value)) {
    return null;
  }

  return isMobile(value)
    ? null
    : { mobile: { 'zh-cn': ``, en: `Mobile phone number is not valid` } };
}
}

function isEmptyInputValue(value: NzSafeAny): boolean {
return value == null || value.length === 0;
}

function isMobile(value: string): boolean {
return typeof value === 'string' && /(^1\d{10}$)/.test(value);
}

