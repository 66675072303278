import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'process-automation',
  templateUrl: './process-automation.component.html',
  styleUrls: ['./process-automation.component.scss']
})
export class  ProcessAutomationComponent  implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
