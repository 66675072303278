<div class="breadcrumb-wrapper section-padding bg-cover" style="background-image: url('assets/img/breadcrumb.jpg');">
    <div class="container">
        <div class="page-heading">
            <h1 class="wow fadeInUp" data-wow-delay=".3s">Team Details</h1>
            <!-- <ul class="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                <li>
                    <a href="index.html">
                        Home Page
                    </a>
                </li>
                <li>
                    <i class="fal fa-minus"></i>
                </li>
                <li>
                    Team Details
                </li>
            </ul> -->
        </div>
    </div>
</div>

<!-- Team Details Section Start -->
<section class="team-details-section fix section-padding">
    <div class="container">
        <div class="team-details-wrapper">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                    <div class="team-image bg-cover" style="background-image: url('assets/img/team/details-1.jpg');">
                    </div>
                </div>
                <div class="col-lg-5 mt-5 mt-lg-0 wow fadeInUp" data-wow-delay=".5s">
                    <div class="team-details-content">
                        <div class="star pb-3">
                            <a href="#"> <i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"> <i class="fas fa-star"></i></a>
                            <a href="#"><i class="fas fa-star"></i></a>
                            <a href="#"> <i class="fas fa-star"></i></a>
                            <a href="#">(5k)</a>
                        </div>
                        <h3>Alextina Ditarson</h3>
                        <span>General Manager</span>
                        <p>
                            On the other hand, we denounce with righteous indignation dislike men who
                            are so beguiled and demoralized by the charms of pleasure of the moment so
                            blinded by desire that they cannot foresee the pain and trouble that are
                        </p>
                        <div class="social-icon d-flex align-items-center">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-vimeo-v"></i></a>
                            <a href="#"><i class="fab fa-pinterest-p"></i></a>
                        </div>
                        <a href="contact.html" class="theme-btn mt-5">
                            <span class="mb-0">
                                Get a Free Quate
                                <i class="fas fa-chevron-right"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Team Skill Section Start -->
<section class="team-skill fix section-padding">
    <div class="container">
        <div class="team-skill-wrapper">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                    <div class="team-skill-content">
                        <h3>
                            Welcome to our culinary haven
                            where each dish is a symphony
                            of flavors meticulously
                        </h3>
                    </div>
                </div>
                <div class="col-lg-5 mt-4 mt-lg-0 wow fadeInUp" data-wow-delay=".5s">
                    <div class="progress-wrap">
                        <div class="pro-items">
                            <div class="pro-head">
                                <h6 class="title">
                                    business managment
                                </h6>
                                <span class="point">
                                    65%
                                </span>
                            </div>
                            <div class="progress">
                                <div class="progress-value"></div>
                            </div>
                        </div>
                        <div class="pro-items">
                            <div class="pro-head">
                                <h6 class="title">
                                    technology solution
                                </h6>
                                <span class="point">
                                    75%
                                </span>
                            </div>
                            <div class="progress">
                                <div class="progress-value style-two"></div>
                            </div>
                        </div>
                        <div class="pro-items">
                            <div class="pro-head">
                                <h6 class="title">
                                    Human Interacation
                                </h6>
                                <span class="point">
                                    65%
                                </span>
                            </div>
                            <div class="progress">
                                <div class="progress-value"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Team Contact Section Start -->
<section class="team-contact-area fix section-padding">
    <div class="container">
        <div class="team-contact-wrapper">
            <div class="row">
                <div class="col-lg-6">
                    <div class="get-touch-items">
                        <div class="get-touch-title">
                            <h2 class="wow fadeInUp">Get in touch</h2>
                            <p class="wow fadeInUp" data-wow-delay=".3s">
                                Lorem ipsum dolor sit amet consectetur adipiscing elit mattis <br>
                                faucibus odio feugiat arc dolor.
                            </p>
                        </div>
                        <div class="contact-items">
                            <div class="contact-info wow fadeInUp" data-wow-delay=".3s">
                                <h4>Contact</h4>
                                <h5><a href="tel:+1718-904-4450">+1718-904-4450</a></h5>
                            </div>
                            <div class="contact-info wow fadeInUp" data-wow-delay=".5s">
                                <h4>Email</h4>
                                <h5><a href="mailto:info@grootsoftwares.com.com" class="link">info&#64;example.com</a>
                                </h5>
                            </div>
                        </div>
                        <div class="contact-items">
                            <div class="contact-info wow fadeInUp" data-wow-delay=".3s">
                                <h4>Address</h4>
                                <h5>Jackpark, Ghana</h5>
                            </div>
                            <div class="contact-info wow fadeInUp" data-wow-delay=".5s">
                                <h4>Follow</h4>
                                <div class="social-icon d-flex align-items-center">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-vimeo-v"></i></a>
                                    <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mt-5 mt-lg-0">
                    <div class="contact-box">
                        <div class="contact-title">
                            <h3 class="wow fadeInUp" data-wow-delay=".3s">Need Help For Project!</h3>
                            <p class="wow fadeInUp" data-wow-delay=".5s">We are ready to help your next projects, let’s
                                work together</p>
                        </div>
                        <div class="contact-form-items">
                            <form action="#" id="contact-form" method="POST">
                                <div class="row g-3">
                                    <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="form-clt">
                                            <input type="text" name="name" id="name" placeholder="Name">
                                            <div class="icon">
                                                <i class="far fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                                        <div class="form-clt">
                                            <input type="text" name="email" id="email" placeholder="Email">
                                            <div class="icon">
                                                <i class="far fa-envelope"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="form-clt">
                                            <div class="nice-select" tabindex="0">
                                                <span class="current">
                                                    Choose Services
                                                </span>
                                                <ul class="list">
                                                    <li data-value="1" class="option selected focus">
                                                        Default sorting
                                                    </li>
                                                    <li data-value="1" class="option">
                                                        Sort by popularity
                                                    </li>
                                                    <li data-value="1" class="option">
                                                        Sort by average rating
                                                    </li>
                                                    <li data-value="1" class="option">
                                                        Sort by latest
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                                        <div class="form-clt">
                                            <textarea name="message" id="message"
                                                placeholder="Write Your Message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 wow fadeInUp" data-wow-delay=".4s">
                                        <button type="submit" class="theme-btn center d-block">
                                            <span>
                                                Send Us Messages
                                                <i class="fas fa-chevron-right"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<< Brand Section Start >>-->
<div class="brand-section-2 fix section-bg-2 mt-0">
    <div class="container">
        <div class="brand-wrapper style-2">
            <div class="brand-carousel-active-2">
                <div class="brand-image">
                    <img src="assets/img/brand/01.png" alt="brand-img">
                </div>
                <div class="brand-image">
                    <img src="assets/img/brand/01.png" alt="brand-img">
                </div>
                <div class="brand-image">
                    <img src="assets/img/brand/01.png" alt="brand-img">
                </div>
                <div class="brand-image">
                    <img src="assets/img/brand/01.png" alt="brand-img">
                </div>
                <div class="brand-image">
                    <img src="assets/img/brand/01.png" alt="brand-img">
                </div>
                <div class="brand-image">
                    <img src="assets/img/brand/01.png" alt="brand-img">
                </div>
                <div class="brand-image">
                    <img src="assets/img/brand/01.png" alt="brand-img">
                </div>
            </div>
        </div>
    </div>
</div>