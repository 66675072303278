import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiServices } from '../../api-services';
import { email } from 'src/app/models/email';
import { notificationTypes, commonErrorMessage } from 'src/utils/constants';
import { ValidateEmail } from 'src/app/validation';
import { ShowNotificationsService } from '../services/show-notifications.service';
import { environment } from 'src/environments/environment';
import { notOnlyWhitespace } from 'src/app/Emptyspace';

@Component({
  selector: 'app-commonserviceform',
  templateUrl: './commonserviceform.component.html',
  styleUrls: ['./commonserviceform.component.scss'],
})
export class CommonserviceformComponent {
  environment = environment;

  @ViewChild('testcount') testcount!: ElementRef;

  disabled = false;

  formTitle = 'Need Help For Project!';

  contactForm: FormGroup;

  submitted = false;

  dropDownService: { key: string; value: string }[] = [
    { key: 'consulting_strategy', value: 'Consulting & Strategy' },
    { key: 'cloud_computing', value: 'Cloud Computing' },
    { key: 'blockchain_dev', value: 'Blockchain Software Development' },
    { key: 'iot', value: 'Internet of Things' },
    { key: 'ai_ml', value: 'Artificial Intelligence & Machine Learning' },
    { key: 'testing_qa', value: 'Software Testing & QA' },
    { key: 'maintenance_support', value: 'Maintenance & Support' },
    { key: 'modernization_migration', value: 'Modernization and Migration' },
    { key: 'web_app_dev', value: 'Web Application Development' },
    { key: 'custom_dev', value: 'Custom Software Development' },
    { key: 'front_end_dev', value: 'Front-End Development' },
    { key: 'back_end_dev', value: 'Back-End Development' },
    { key: 'ios_app_dev', value: 'iOS App Development' },
    { key: 'android_app_dev', value: 'Android App Development' },
    { key: 'ui_ux_design', value: 'UI/UX Design' },
    { key: 'mvp_dev', value: 'MVP Development' },
    { key: 'pw_as', value: 'Progressive Web Applications (PWAs)' },
    { key: 'ecommerce_dev', value: 'E-commerce Development' },
    { key: 'erp_crm', value: 'ERP & CRM' },
    { key: 'digital_acceleration', value: 'Digital Acceleration' },
    { key: 'business_intelligence', value: 'Business Intelligence' },
    { key: 'process_automation', value: 'Process Automation' },
    { key: 'wallets_crypto', value: 'Digital Wallets & Cryptocurrency' },
    { key: 'data_science', value: 'Data Science' },
    { key: 'db_dev', value: 'Database Development' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiServices,
    private showNotificationService: ShowNotificationsService
  ) {
    this.serviceForm();
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  // Getter method to access form controls easily in the template
  get f() {
    return this.contactForm.controls;
  }

  // Choose service using select dropdown
  ontestCountSelected(value: string) {
    this.contactForm.get('service')?.setValue(value);
  }

  serviceForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required,notOnlyWhitespace]],
      email: ['', [Validators.required, Validators.email, ValidateEmail]],
      service: [[], Validators.required], // Include Validators.required for service field
      message: ['', [Validators.required,notOnlyWhitespace]],
      recaptcha: ['', [Validators.required]],
    });
  }

  onSubmitService() {
    this.submitted = true;

     // Check form validity before submitting
    if (this.contactForm.invalid) {
      return;
    }

    // If form is valid, proceed with form submission
    const emailData: email = {
      firstName: this.contactForm.value.name,
      userEmail: this.contactForm.value.email,
      message: this.contactForm.value.message,
      companyName: '',
      lastName: '',
      services: this.contactForm.value.service.join(', '),
      phoneNumber: '',
    };
    this.sendEmail(emailData);
  }

  // Method to send email using API service
  sendEmail(emailData: email) {
    this.disabled = true;

    this.apiService.sendEmail(emailData, this.formTitle).subscribe(
      (data: any) => {
        const { responseCode, responseMessage } = data;
        if (responseCode === 200) {
          this.showNotificationService.showNotification(
            notificationTypes.success,
            responseMessage
          );
          this.contactForm.reset();
          this.contactForm.controls.service.setValue('');
          this.submitted = false;
        } else {
          this.showNotificationService.showNotification(
            notificationTypes.error,
            commonErrorMessage.somethingWentWrong
          );
        }
        this.disabled = false;
      },
      (error) => {
        this.disabled = false;

        console.error('Error sending email:', error);
        this.showNotificationService.showNotification(
          notificationTypes.error,
          commonErrorMessage.somethingWentWrong
        );
      }
    );
  }
}
