<div class="breadcrumb-wrapper section-padding bg-cover" style="background-image: url('assets/img/breadcrumb.jpg');">
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">service Details</h1>

    </div>
  </div>
</div>

<!--<< Service Details Section Start >>-->
<section class="service-details fix section-padding">
  <div class="container">
    <div class="service-details-wrapper">
      <div class="row g-5">
        <div class="col-12 col-lg-8">
          <div class="service-details-items">
            <div class="details-image">
              <img src="assets/img/service/details-1.jpg" alt="img">
            </div>
            <div class="details-content">
              <h3>Digital Marketing</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat qui ducimus illum modi? perspiciatis
                accusamus soluta perferendis, ad illum, nesciunt, reiciendis iusto et cupidit Repudiandae provident to
                consectetur, sapiente, libero iure necessitatibus corporis nulla voluptate, quisquam aut perspiciatis?
                Fugiat labore aspernatur eius, perspiciatis ut molestiae, delectus rem.
              </p>
              <h4>Sed ut perspiciatis unde omnis iste natus et</h4>
              <p>
                Need something changed or is there something not quite working the way you envisaged? Is your van a
                little old and tired and need refreshing? Lorem Ipsum is simply dummy text of the printing and
                typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not
                only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              </p>
              <div class="service-details-video">
                <div class="row g-4 align-items-center">
                  <div class="col-lg-6">
                    <div class="details-video-content">
                      <h3>Why Marketing Important ?</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisici
                        sed do eiusmod tempor incididunt ut labore et
                      </p>
                      <ul>
                        <li>
                          <i class="far fa-check"></i>
                          Research beyond the business plan
                        </li>
                        <li>
                          <i class="far fa-check"></i>
                          Marketing options and rates
                        </li>
                        <li>
                          <i class="far fa-check"></i>
                          The ability to turnaround consulting
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="video-image">
                      <img src="assets/img/service/details-2.jpg" alt="img">
                      <div class="video-box">
                        <a href="https://www.youtube.com/watch?v=Cn4G2lZ_g2I" class="video-btn ripple video-popup">
                          <i class="fas fa-play"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat qui ducimus illum modi? perspiciatis
                accusamus soluta perferendis, ad illum, nesciunt, reiciendis iusto et cupidit Repudiandae provident to
                consectetur, sapiente, libero iure necessitatibus corporis nulla voluptate, quisquam aut perspiciatis?
                Fugiat labore aspernatur eius, perspiciatis ut molestiae, delectus rem.
              </p>
              <div class="details-image-2">
                <div class="row g-4">
                  <div class="col-lg-6">
                    <img src="assets/img/service/details-3.jpg" alt="img">
                  </div>
                  <div class="col-lg-6">
                    <img src="assets/img/service/details-4.jpg" alt="img">
                  </div>
                </div>
              </div>
            </div>
            <div class="faq-content">
              <div class="faq-accordion">
                <div class="accordion" id="accordion">
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".3s">
                    <h4 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq1" aria-expanded="false" aria-controls="faq1">
                        How to learn digital marketing?
                      </button>
                    </h4>
                    <div id="faq1" class="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div class="accordion-body">
                        Ne summo dictas pertinacia nam. Illum cetero vocent ei vim, case regione signiferumque vim te.
                        Ex mea quem munere lobortis. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".5s">
                    <h4 class="accordion-header">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq2"
                        aria-expanded="true" aria-controls="faq2">
                        Can I use the demos made by Ewebot?
                      </button>
                    </h4>
                    <div id="faq2" class="accordion-collapse collapse show" data-bs-parent="#accordion">
                      <div class="accordion-body">
                        Ne summo dictas pertinacia nam. Illum cetero vocent ei vim, case regione signiferumque vim te.
                        Ex mea quem munere lobortis. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".7s">
                    <h4 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                        Why didn’t you showcase my submission?
                      </button>
                    </h4>
                    <div id="faq3" class="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div class="accordion-body">
                        Ne summo dictas pertinacia nam. Illum cetero vocent ei vim, case regione signiferumque vim te.
                        Ex mea quem munere lobortis. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".9s">
                    <h4 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                        What everybody ought to know about digital marketing?
                      </button>
                    </h4>
                    <div id="faq4" class="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div class="accordion-body">
                        Ne summo dictas pertinacia nam. Illum cetero vocent ei vim, case regione signiferumque vim te.
                        Ex mea quem munere lobortis. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="main-sidebar">
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3>Categories</h3>
              </div>
              <div class="service-category">
                <a href="service-details.html" class="active">
                  Cloud Service
                  <i class="fal fa-long-arrow-right"></i>
                </a>
                <a href="service-details.html">
                  Web Development
                  <i class="fal fa-long-arrow-right"></i>
                </a>
                <a href="service-details.html">
                  Ui/Ux Designing
                  <i class="fal fa-long-arrow-right"></i>
                </a>
                <a href="service-details.html">
                  IT Management
                  <i class="fal fa-long-arrow-right"></i>
                </a>
                <a href="service-details.html">
                  Data Visualization
                  <i class="fal fa-long-arrow-right"></i>
                </a>
                <a href="service-details.html">
                  Security System
                  <i class="fal fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3>Any Question For Us</h3>
              </div>
             
              <app-commonenquiryform></app-commonenquiryform>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Brand Section Start >>-->
<div class="brand-section-2 fix section-bg-2 mt-0">
  <div class="container">
    <div class="brand-wrapper style-2">
      <div class="brand-carousel-active-2">
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
      </div>
    </div>
  </div>
</div>