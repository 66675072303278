import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-end-development',
  templateUrl: './front-end-development.component.html',
  styleUrls: ['./front-end-development.component.scss']
})
export class FrontEndDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
