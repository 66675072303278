import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ApiServices } from 'src/app/api-services';
import { email } from 'src/app/models/email';
import { ValidateEmail } from 'src/app/validation';
import { notificationTypes, commonErrorMessage } from 'src/utils/constants';
import { ShowNotificationsService } from '../services/show-notifications.service';
import { environment } from 'src/environments/environment';
import { notOnlyWhitespace } from 'src/app/Emptyspace';
import { serviceBoxes } from 'src/app/servicedata';

declare var $: any; // Declare jQuery globally

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit {
  environment = environment;

  submitted = false;

  disabled = false;

  enquiryForm: FormGroup;

  currentSlideIndex: number = 0;

  formTitle: any = "Connect With Expert";

  autoSlideInterval: any; // Variable to hold the interval reference

  serviceBoxes = serviceBoxes;

  @ViewChild("testcount") testcount!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiServices,
    private router: Router,
    private showNotificationService: ShowNotificationsService

  ) {
    this.enquiryFormData();
  }

  caseStudies = [
    {
      imageSrc: 'assets/img/case-studies/05.jpg',
      title: 'Boosting Financial Data Efficiency',
      link: '/boosting-financial-data-efficiency',
      category: 'Financial Services'
    },
    {
      imageSrc: 'assets/img/case-studies/06.jpg',
      title: 'Unlocking Digital Creativity',
      link: '/unlocking-digital-creativity',
      category: 'Digital Media & Entertainment'
    },
    {
      imageSrc: 'assets/img/case-studies/07.jpg',
      title: 'Championing Healthcare Evolution',
      link: '/championing-healthcare-evolution',
      category: 'Healthcare'
    }
  ];


  teamMembers = [
    {
      name: 'Ankesh Verma',
      position: 'Director',
      imageSrc: 'assets/img/team/07.jpg',
      linkedin: 'https://www.linkedin.com/in/ankesh1989/'
    },
    {
      name: 'Harsh Kaushal',
      position: 'Director',
      imageSrc: 'assets/img/team/08.jpg',
      linkedin: 'https://www.linkedin.com/in/harshkaushal/'
    },
    {
      name: 'Naveen Tiwari',
      position: 'Director',
      imageSrc: 'assets/img/team/09.jpg',
      linkedin: 'https://www.linkedin.com/in/naveen-tiwari-groot/'
    },
   
  ];

  newsItems = [
    {
      imageSrc: 'assets/img/news/06.jpg',
      date: {
        day: '25',
        month: 'Dec'
      },
      title: 'The Power Of Minimalism',
      link: '/the-power-of-minimalism',
      content: 'Simplifying Design To Enhance User Experiences'
    },
    {
      imageSrc: 'assets/img/news/07.jpg',
      date: {
        day: '28',
        month: 'Dec'
      },
      title: 'The Power Of User Experience',
      link: '/the-power-of-user-experience',
      content: 'Simplifying Design To Enhance User Experiences'
    },
    {
      imageSrc: 'assets/img/news/08.jpg',
      date: {
        day: '30',
        month: 'Dec'
      },
      title: 'Navigating AI',
      link: '/navigating-ai',
      content: 'Exploring Generative Vs Traditional Approaches'
    }
  ];

  

  testimonials: Testimonial[] = [
    {
      text: "Groot Software Solutions transformed our business with their expertise in AI and machine learning. Their solutions helped us automate processes and make data-driven decisions, leading to remarkable growth.",
      author: "Sarah Johnson",
      position: ", CEO of VisionaryTech"
    },
    {
      text: "We partnered with Groot Software Solutions for our digital transformation journey, and it was a game-changer. Their team provided customized strategies and flawless execution, helping us stay ahead in the competitive landscape.",
      author: "Michael Anderson",
      position: ", CTO of InnovateCorp"
    },
    {
      text: "Working with Groot Software Solutions was a breeze. They not only developed a custom software solution for us but also provided invaluable insights and guidance throughout the process. Their commitment to post-development support is commendable.",
      author: "Emily Chen",
      position: ", COO of NexusTech"
    },
    {
      text: "Groot Software Solutions exceeded our expectations with their web application development services. They understood our brand's vision perfectly and delivered a user-friendly interface that resonated well with our audience.",
      author: "David Patel",
      position: ", Marketing Manager of GrowthX"
    },
    {
      text: "The team at Groot Software Solutions made the modernization of our legacy systems seamless. Their expertise in cloud computing and migration ensured a smooth transition, with minimal disruption to our operations.",
      author: "Jessica Taylor",
      position: ", IT Director of GlobalTech Solutions"
    },
    {
      text: "Groot Software Solutions leverages its expertise in cloud computing to architect and implement a scalable infrastructure for our e-commerce platform. Their solutions not only improved performance but also enhanced our ability to handle peak traffic loads seamlessly.",
      author: "Neharika Patel",
      position: ", CTO of DigitalMart Solutions"
    },
    {
      text: "Groot Software Solutions revolutionized our data management system with their expertise in cloud computing. Their scalable and efficient cloud infrastructure enhanced our data storage and processing capabilities, allowing us to handle large volumes of data seamlessly.",
      author: "Sarah Chang",
      position: ", Data Engineer at TechOps Inc."
    }
  ];

  ngOnInit() {
    // Start auto-sliding when component initializes
    this.startAutoSlide();
    this.getCaseStudiesCarousel();
    this.initMagnificPopup();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
      
        this.dismissModal();
      }
    });
  }

  ngOnDestroy() {
    this.stopAutoSlide(); // Stop auto-sliding when component is destroyed
  }

  enquiryFormData() {
    this.enquiryForm = this.formBuilder.group({
      name: ['', [Validators.required,notOnlyWhitespace]],
      email: ['', [Validators.required, Validators.email, ValidateEmail]],
      message: ['', [Validators.required,notOnlyWhitespace]],
      recaptcha: ['', Validators.required]
    });
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  //for Enquiryform form control
  get f1() {
    return this.enquiryForm.controls;
  }

  onSubmitNew(form: FormGroup) {
    this.submitted = true;

    if (this.enquiryForm.invalid) {
      return;
    }

    const emailData: email = {
      firstName: this.enquiryForm.value.name,
      userEmail: this.enquiryForm.value.email,
      message: this.enquiryForm.value.message,
      companyName: '',
      lastName: '',
      services: '',
      phoneNumber: '',
    };

    this.sendEmailData(emailData);
  }

  sendEmailData(emailData: email) {

    this.disabled = true;
    this.apiService.sendEmail(emailData, this.formTitle).subscribe(
      (data: any) => {
        const { responseCode, responseMessage } = data;
        if (responseCode === 200) {
          this.showNotificationService.showNotification(notificationTypes.success, responseMessage);
          // this.contactForm.reset();
          this.enquiryForm.reset();
          this.submitted = false;
        } else {
          this.showNotificationService.showNotification(notificationTypes.error, commonErrorMessage.somethingWentWrong);
        }
        this.disabled = false;
      },
      (error) => {
        this.disabled = false;
        console.error('Error sending email:', error);
        this.showNotificationService.showNotification(notificationTypes.error, commonErrorMessage.somethingWentWrong);
      }
    );
  }

  nextSlide() {
    this.currentSlideIndex = (this.currentSlideIndex + 1) % this.testimonials.length;
  }

  prevSlide() {
    this.currentSlideIndex = (this.currentSlideIndex - 1 + this.testimonials.length) % this.testimonials.length;
  }

  startAutoSlide() {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide(); // Automatically move to the next slide
    }, 5000); // Change slide every 5 seconds (adjust as needed)
  }

  stopAutoSlide() {
    clearInterval(this.autoSlideInterval); // Stop the auto-slide interval
  }


  getCaseStudiesCarousel() {
    this.loadScript('assets/js/slick.min.js').then(() => {
      $('.case-studies-carousel-active').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 800,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        dotsClass: 'slide-dots',
        responsive: [
          {
            breakpoint: 1699,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 1499,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1191,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              center: true,
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1
            }
          }
        ],

      });
      $('.case-studies-carousel-active-2').slick({
        autoplay: true,
        autoplaySpeed: 1500,
        speed: 1000,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        dotsClass: 'slide-dots',
        responsive: [
          {
            breakpoint: 1499,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              center: true,
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1
            }
          }
        ],

      });

      // Hide Next and Previous buttons after a short delay
      setTimeout(() => {
        $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
      }, 100); // Adjust the delay as needed
    }).catch(error => console.log('Script loading error', error));

    // Event handlers for previous and next buttons
    $('.testimonial-nav-prev').on('click', function () {
      $('.service-carousel-active').slick('slickPrev');
    });

    $('.testimonial-nav-next').on('click', function () {
      $('.service-carousel-active').slick('slickNext');
    });

  }

// ........for popup video............ //
  initMagnificPopup() {
     
    $('.video-play').click(function () { 
      var videoSrc = $(this).data('video');
      $('#videoPlayer1').get(0).play();
      $('#videoPlayer1').attr('src', videoSrc);
      $('.video-popup-modal').fadeIn();
      $('body').css('overflow', 'hidden'); // Prevent scrolling

    });

    $('.video-play2').click(function () { 
      var videoSrc = $(this).data('video');
      $('#videoPlayer2').get(0).play();
      $('#videoPlayer2').attr('src', videoSrc);
      $('.video-popup-modal').fadeIn();
      $('body').css('overflow', 'hidden'); // Prevent scrolling

    });

    $('.video-play3').click(function () { 
      var videoSrc = $(this).data('video');
      $('#videoPlayer3').get(0).play();
      $('#videoPlayer3').attr('src', videoSrc);
      $('.video-popup-modal').fadeIn();
      $('body').css('overflow', 'hidden'); // Prevent scrolling

    });
 
    $('.video-popup-modal .close').click(function () { 
      $('.video-player').each(function() {
        var video = $(this).get(0);
        video.pause();
        video.currentTime = 0;
      });
     $('.video-popup-modal').fadeOut(); 
     $('body').css('overflow', '');      
    }); 
    
    $('a[data-bs-toggle="modal"]').click(function() { 
      $('body').css('cssText', 'overflow: hidden !important;');
  });
  $('a[data-bs-dismiss="modal"]').click(function() { 
    $('body').css('cssText', 'overflow: auto !important;');
    
});
   
  }
  
 

 loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  dismissModal() {
    // Add your code here to dismiss the modal
    // Example:
    document.getElementById('exampleModal').style.display = 'none';
  }

  

}


interface Testimonial {
  text: string;
  author: string;
  position: string;
}
