<!--<< About  Harsh Kaushal Section Start >>-->

<section class="team-details-section fix pt-5">
  <div class="container">
    <div
      class="about-Harsh"
      style="
        padding: 30px;
        background-image: url(../../../../assets/img/team/img-ow.jpg);
      "
    >
      <div class="row justify-content-between align-items-center">
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay=".3s"
          style="
            visibility: visible;
            animation-delay: 0.3s;
            animation-name: fadeInUp;
          "
        >
          <div class="circular--portrait">
            <img
              class="img-fluid"
              src="assets/img/team/02.jpg"
              alt=" pic"
              height="450px"
            />
          </div>
        </div>
        <div
          class="col-lg-6 mt-5 mt-lg-0 wow fadeInUp"
          data-wow-delay=".5s"
          style="
            visibility: visible;
            animation-delay: 0.5s;
            animation-name: fadeInUp;
          "
        >
          <div class="team-details-content">
            <h2>Harsh Kaushal</h2>
            <br />

            <h3>Meet Harsh Kaushal</h3>
            <br />

            <p>
              Harsh Kaushal, a seasoned Full Stack Developer with over 14 years
              of experience, is a driving force behind Groot Software Solutions.
              With a passion for continuous learning and skill expansion, Harsh
              ensures that our solutions are always at the forefront of
              technological innovation.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--<< Service Details Section Start >>-->
<section class="service-details fix section-padding pt-5">
  <div class="container">
    <div class="service-details-wrapper">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="seperation mt-5 mb-4"></div>
          <div class="service mt-5">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 mt-0">
                <div class="service-detail">
                  <h4>Services</h4>

                  <ul
                    class="detail"
                    style="list-style-type: circle; padding: 10px"
                  >
                    <li>
                      <p style="color: black">
                        <b>Custom Software Development: </b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Bespoke solutions tailored to your unique business
                      requirements, utilizing the latest technologies and
                      development methodologies.
                    </p>
                    <li>
                      <p style="color: black">
                        <b>Web and Mobile App Development: </b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Engaging and responsive applications designed to
                      provide seamless user experiences across all devices.
                    </p>
                    <li>
                      <p style="color: black"><b>Enterprise Solutions:</b></p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Scalable and robust solutions that optimize business
                      processes and drive growth.
                    </p>
                    <li>
                      <p style="color: black">
                        <b>Data Management and Business Intelligence:</b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Transforming raw data into actionable insights to
                      support informed decision-making and strategic planning.
                    </p>
                    <li>
                      <p style="color: black"><b>E-commerce Solutions:</b></p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Secure and feature-rich online platforms that
                      facilitate transactions and enhance customer satisfaction.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="service-details-items">
            <!-- <div class="details-image">
                            <img src="assets/img/service/details-1.jpg" alt="img">
                        </div> -->
            <div class="details-content">
              <h3 class="mt-3">Why Choose Us?</h3>
              <ul>
                <li class="text-li">
                  <h6>Expertise:</h6>
                  A team of highly skilled professionals with extensive
                  experience in delivering innovative solutions across various
                  industries.
                </li>
                <li class="text-li">
                  <h6>Customization:</h6>
                  We work closely with our clients to understand their unique
                  challenges and objectives, ensuring that our solutions are
                  aligned with their goals.
                </li>
                <li class="text-li">
                  <h6>Quality Assurance:</h6>
                  Rigorous testing and quality assurance processes to ensure
                  that our solutions meet the highest standards of quality and
                  reliability.
                </li>
              </ul>
            </div>

            <div class="seperation mt-5 mb-4"></div>
            <h3>Our Expertise in Action</h3>
            <br />
            <p>
              At Groot Software Solutions, we pride ourselves on delivering
              innovative solutions tailored to your unique business needs. Here
              are some examples of how our directors' expertise translates into
              real-world use cases:
            </p>
            <div class="row pt-5">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/harsh-use-cases1.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">Blog Management Platform</div>
                        <p class="image__description">
                          Develop a blog management platform for content
                          creators to publish articles, manage subscriptions,
                          and engage with their audience effectively.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/harsh-use-cases2.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Real-Time Chat Application
                        </div>
                        <p class="image__description">
                          Design a real-time chat application for instant
                          messaging, group chats, and file sharing, facilitating
                          communication and collaboration among users.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/harsh-use-cases3.jpg"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Personal Finance Management App
                        </div>
                        <p class="image__description">
                          Create a personal finance management app for
                          budgeting, expense tracking, and financial goal
                          setting, empowering users to manage their finances
                          effectively.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/harsh-use-cases4.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Appointment Scheduling System
                        </div>
                        <p class="image__description">
                          Build an appointment scheduling system for booking
                          appointments, managing calendars, and sending
                          reminders for service-based businesses.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/harsh-use-cases5.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Task Tracking and Productivity App
                        </div>
                        <p class="image__description">
                          Develop a task tracking and productivity app for
                          managing tasks, setting priorities, and tracking
                          progress, enhancing individual and team productivity.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/harsh-use-cases6.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Language Learning Platform
                        </div>
                        <p class="image__description">
                          Design a language learning platform with interactive
                          lessons, quizzes, and progress tracking features for
                          users to learn new languages at their own pace.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 30px">
              <h3>Looking for Something Else?</h3>
              <br />
              <p>
                If you have a project idea in mind or need assistance with a
                specific requirement, we're here to help. Get in touch with us
                today to discuss your project needs and explore how Groot
                Software Solutions can bring your vision to life.
              </p>
              <br />
              <div class="col-lg-12">
                <button
                  [routerLink]="['/enquiry']"
                  class="theme-btn"
                  type="submit"
                >
                  <span>
                    Get in Touch
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="main-sidebar">
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">Engagement Models</h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample1"
                    >
                      Fixed Price
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample1">
                    <div class="card card-body">
                      Clearly defined project scopes and budgets for predictable
                      outcomes and peace of mind.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample2"
                    >
                      Time and Material
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample2">
                    <div class="card card-body">
                      Flexible engagement model that allows for adjustments
                      based on evolving project requirements.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn3"
                      data-bs-toggle="collapse"
                      href="#collapseExample3"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample3"
                    >
                      Dedicated Team
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample3">
                    <div class="card card-body">
                      Access to a dedicated team of experts committed to
                      delivering your project on time and within budget.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">Featured Projects</h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample4"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample4"
                    >
                      Mindset
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample4">
                    <div class="card card-body">
                      A web application for managing blogs related to mental
                      illness, facilitating article management, forum
                      discussions, and user interactions.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample5"
                    >
                      Whiteboard
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample5">
                    <div class="card card-body">
                      An online real-time trading system facilitating bid/sale
                      processes with accurate and reliable trade information.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">
                  Discover More with Groot Software Solutions
                </h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample6"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample6"
                    >
                      Technology Stack
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample6">
                    <div class="card card-body">
                      Our team is proficient in a wide range of technologies,
                      including React JS, AngularJS, Node.js, ASP.NET Core, SQL
                      Server, MongoDB, and more.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample7"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample7"
                    >
                      Industry Experience
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample7">
                    <div class="card card-body">
                      We have successfully delivered projects for clients in
                      diverse industries, including healthcare, finance,
                      e-commerce, and more.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample8"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample8"
                    >
                      Client Testimonials
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample8">
                    <div class="card card-body">
                      Hear from our satisfied clients about their experiences
                      working with Groot Software Solutions and the positive
                      impact our solutions have had on their businesses.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample9"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample8"
                    >
                      Partnerships
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample9">
                    <div class="card card-body">
                      We partner with leading technology providers to leverage
                      their expertise and resources, ensuring that our solutions
                      are always at the forefront of technological innovation
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <form
                action="#"
                id="contact-form"
                class="message-form"
                style="
                  background-color: #00c0da;
                  padding: 20px;
                  border-radius: 30px;
                  padding-bottom: 50px;
                "
              >
                <div class="row g-3">
                  <div class="wid-title">
                    <h3 style="color: white; text-align: center; padding: 8px">
                      Contact us
                    </h3>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <a href="mailto:info@grootsoftwares.com">
                        <li
                          style="
                            background-color: white;
                            padding: 10px;
                            list-style: none;
                            border-radius: 3px;
                          "
                        >
                          <i class="fas fa-envelope"></i>
                          info&#64;grootsoftwares.com
                        </li>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <a href="tel:+01762460300">
                        <li
                          style="
                            background-color: white;
                            padding: 10px;
                            list-style: none;
                            border-radius: 3px;
                          "
                        >
                          <i class="fas fa-mobile-alt"></i> 0176-2460300
                        </li>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <li
                        style="
                          background-color: white;
                          padding: 10px;
                          list-style: none;
                          border-radius: 3px;
                        "
                      >
                        <i class="fas fa-map-marker-alt"></i> United States │
                        India
                      </li>
                    </div>
                  </div>
                </div>
              </form>

              <app-commonportfoliocomponent></app-commonportfoliocomponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
