<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/bg-2.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Leadership & Advisors</h1>
    </div>
  </div>
</div>

<!--<< Team Section Start >>-->
<section class="team-section section-padding">

  <div class="container ">
    
    <div class="section-title text-center">
      
      <span class="wow fadeInUp">Meet Our Leadership & Advisors </span>
      <h2 class="wow fadeInUp" data-wow-delay=".3s">
        A Dynamic Team Driving Success
      </h2>
    </div>
    <div class="team-wrapper">
     
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="row justify-content-center   section-bg-3" >
            <div class="dot-shape-2">
              <img
                src="assets/img/team/dot-shape-2.png"
                alt="shape-img"
                class="position-image-pos"
              />
            </div>
        <div *ngFor="let member of teamMembers" class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" [attr.data-wow-delay]="'.3s'">
          <div class="single-team-items">
            <div class="team-image item">
              <img [src]="member.imageUrl" alt="team-img" />
              <div class="overlay bottom-overlay">
                <div class="text">
                  {{ member.bio }}
                </div>
              </div>
              <div class="social-profile">
                <a [href]="member.linkedInUrl" target="_blank">
                  <span class="plus-btn"><i class="fab fa-linkedin-in"></i></span>
                </a>
              </div>
            </div>
            <a [routerLink]="['/', member.name.toLowerCase().replace(' ', '-')]" class="team-content mt-0 text-center">
              <h4 class="heading-text">{{ member.name }}</h4>
              <p class="text-left">{{ member.role }}</p>
            </a>
          </div>
        </div>
        </div>
        </div>
       
        <div
          class="col-xl-3 col-lg-5 col-md-4 wow fadeInUp top-mar"
          data-wow-delay=".3s"
        >
          <div class="single-team-items">
            <div class="team-join-content text-center">
              <div class="circle-shape">
                <img src="assets/img/team/circle-shape.png" alt="shape-img" />
              </div>
              <div class="dot-shape">
                <img src="assets/img/team/dot-shape.png" alt="shape-img" />
              </div>
              <h2>JOIN OUR <span>TEAM</span></h2>
              <h3>50+ Certified Talent & Growing</h3>
            </div>
          </div>
        </div>
        <div class="col-xl-1"></div>
        <div
          class="col-xl-8 col-lg-6 col-md-8 wow fadeInUp mt-5"
          data-wow-delay=".5s"
        >
          <p class="">
            At Groot Software Solutions, we pride ourselves on having a
            leadership and advisory team that is second to none. Along with the
            seasoned professionals with proven track records of success, our
            leaders bring a wealth of experience and expertise to the table.
            <br />
            <br />
            With a keen eye for innovation and a dedication to excellence, our
            leadership team is committed to keeping the growth quotient of our
            organization at its peak. Backed by a deep understanding of the
            Silicon Valley landscape and industry trends, our advisors provide
            invaluable insights and strategic guidance that help steer our
            company toward success.
            <br />
            <br />
            When you partner with Groot Software Solutions, you're not just
            getting a product or service — you're gaining access to a network of
            exceptional leaders and advisors who are dedicated to driving your
            success forward. Join us on the journey to greatness.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Cta Banner Section Start >>-->
<section
  class="cta-banner-section bg-cover section-padding"
  style="background-image: url('assets/img/banner/01.jpg')"
>
  <div class="container">
    <div class="cta-banner-wrapper section-padding pt-0">
      <div class="section-title mb-0">
        <span class="wow fadeInUp text-white">JOIN OUR TEAM </span>
        <h2 class="text-white wow fadeInUp" data-wow-delay=".3s">
          Need It advisor For your business ? <br />
          Get in Touch
          <a href="tel:+01762460300" class="theme-color-1">0176-2460300</a>
        </h2>
      </div>
      <a
        [routerLink]="['/enquiry']"
        class="theme-btn hover-white wow fadeInUp"
        data-wow-delay=".5s"
      >
        <span>
          Get Expert Consultation
          <i class="fas fa-chevron-right"></i>
        </span>
      </a>
    </div>
  </div>
</section>

<!--<< Feature Icon Box Section Start >>-->
<section class="feature-icon-box-area">
  <div class="container">
    <div class="feature-icon-box-wrapper">
      <div class="row g-4">
        <div
          class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div class="icon-box-items">
            <div class="icon">
              <i class="fa fa-tasks"></i>
            </div>
            <div class="content content-fk">
              <h3>Purpose</h3>
              <p>
                Cultivating meaningful connections through innovative solutions,
                placing clients at the heart of every endeavor.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay=".5s"
        >
          <div class="icon-box-items active">
            <div class="icon">
              <i class="fa fa-eye" aria-hidden="true"></i>
            </div>
            <div class="content content-fk">
              <h3>Vision</h3>
              <p>
                Nurturing a landscape of interconnected growth, where technology
                empowers and transforms businesses and communities.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay=".7s"
        >
          <div class="icon-box-items">
            <div class="icon">
              <i class="fas fa-bullseye-arrow"></i>
            </div>
            <div class="content content-fk">
              <h3>Mission</h3>
              <p>
                Pioneering client-centric software solutions, fostering
                partnerships and progress through innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Testimonial Section Start >>-->
<section class="testimonial-section-2 fix section-padding">
  <div class="container-fluid">
    <div class="testimonial-wrapper-3 pt-0 pb-0 style-2">
      <div class="testimonial-carousel-active-3">
       
        <div class="testimonial-items">
          <div class="testimonial-image-area">
            <div
              class="testimonial-image bg-cover"
              style="background-image: url('assets/img/testimonial/07.jpg')"
            >
              <div class="icon">
                <i class="flaticon-double-quotes"></i>
              </div>
            </div>
          </div>
          <div class="testimonial-content">
            <h3>
              Every startup has its own language, and at Groot Software
              Solutions, we're fluent in innovation. We decode the complexities
              of the startup ecosystem, transforming challenges into
              opportunities that fuel growth and success
            </h3>
            <div class="athor-name">
              <h4>Ankesh Verma</h4>
            </div>
          </div>
        </div>
        <div class="testimonial-items">
          <div class="testimonial-image-area">
            <div
              class="testimonial-image bg-cover"
              style="background-image: url('assets/img/testimonial/08.jpg')"
            >
              <div class="icon">
                <i class="flaticon-double-quotes"></i>
              </div>
            </div>
          </div>
          <div class="testimonial-content">
            <h3>
              Startups are the architects of tomorrow's world, and at Groot
              Software Solutions, we're the architects behind the scenes. Our
              solutions don't just solve problems; they lay the foundation for
              startups to thrive, grow, and leave their mark on the world
            </h3>
            <div class="athor-name">
              <h4>Harsh Kaushal</h4>
            </div>
          </div>
        </div>
        <div class="testimonial-items">
          <div class="testimonial-image-area">
            <div
              class="testimonial-image bg-cover"
              style="background-image: url('assets/img/testimonial/09.jpg')"
            >
              <div class="icon">
                <i class="flaticon-double-quotes"></i>
              </div>
            </div>
          </div>
          <div class="testimonial-content">
            <h3>
              User experience is the heartbeat of every successful SMB. At Groot
              Software Solutions, we design with empathy, ensuring that every
              interaction with our applications is a testament to the dedication
              and passion behind every small and medium-sized business
            </h3>
            <div class="athor-name">
              <h4>Naveen Tiwari</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="slider-button">
        <div class="testimonial-nav-prev">
          <i class="fas fa-long-arrow-left"></i>
        </div>
        <div class="testimonial-nav-next">
          <i class="fas fa-long-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Contact Section Start >>-->
<section
  class="contact-section-2 fix section-padding bg-cover"
  style="background-image: url('assets/img/contact-bg.jpg')"
>
  <div class="contact-image">
    <img src="assets/img/contact-2.png" alt="contact-img" />
  </div>
  <div class="container">
    <div class="contact-wrapper-2">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="contact-content">
            <div class="section-title style-2">
              <span class="wow fadeInUp">Get in Touch</span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                Schedule A Free Consultation
              </h2>
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              Top American businesses accelerate their tech roadmaps with us.
              <br />See how we can help you.
            </p>
            <div class="contact-info-area">
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div class="icon">
                  <i class="far fa-phone"></i>
                </div>

                <div class="content">
                  <p>Call For Inquiry</p>
                  <h3><a href="tel:+01762460300">0176-2460300</a></h3>
                </div>
              </div>
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".8s"
              >
                <div class="icon">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="content">
                  <p>Send Us Email</p>
                  <h3>
                    <a href="mailto:info@grootsoftwares.com"
                      >info&#64;grootsoftwares.com</a
                    >
                  </h3>
                </div>
              </div>
              <div class="contact-info-items wow fadeInUp" data-wow-delay=".9s">
                <div class="icon">
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div class="content">
                  <p>Location</p>
                  <h3>
                    Sushma Infinium, 3rd Floor, Zirakpur(Chandigarh), Punjab,
                    140603
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="contact-box">
            <div class="contact-title">
              <h3 class="wow fadeInUp" data-wow-delay=".3s">
                Need Help For Project!
              </h3>
              <p class="wow fadeInUp" data-wow-delay=".5s">
                We are ready to help your next projects, let’s work together
              </p>
            </div>
            <div class="contact-form-items">
              <app-commonserviceform></app-commonserviceform>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
