// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  apiUrlForEmail: 'http://97.74.92.197:9005/api/gssEmail/',
  //apiUrlForEmail: 'https://localhost:7012/api/gssEmail/',

  apiUrlForEmail: '/api/gssEmail',

  recaptcha: {
    // siteKey: '6LeOZ4YUAAAAAJ8clQYNtOXNDHsd58KZHyJeILk6',//dummy site key
    // siteKey: '6LeGpwsqAAAAAPYKrGLAWF8ECqUhvupMhUBqwdSr',
    siteKey: '6LdudQ0qAAAAAEj5ftoUUw66MhFae0xW5Yo4c3yT',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
