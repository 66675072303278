import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-dev-ops',
  templateUrl: './dev-ops.component.html',
  styleUrls: ['./dev-ops.component.scss']
})

export class DevOpsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
