import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiServices } from 'src/app/api-services';
import { email } from 'src/app/models/email';
import { notificationTypes, commonErrorMessage } from 'src/utils/constants';
import { ShowNotificationsService } from '../show-notifications.service';

@Component({
  selector: 'app-cms-web-development',
  templateUrl: './cms-web-development.component.html',
  styleUrls: ['./cms-web-development.component.scss']
})

export class CMSWebDevelopmentComponent {
  // submitted = false;
  // enquiryForm: FormGroup;

  constructor(
    // private formBuilder: UntypedFormBuilder,
    // private apiService: ApiServices,
    // private showNotificationService: ShowNotificationsService
  ) {
    // this.createForm();
  }

  // createForm() {
  //   this.enquiryForm = this.formBuilder.group({
  //     firstName: ['', [Validators.required]],
  //     userEmail: ['', [Validators.required, Validators.email]],
  //     message: ['', [Validators.required]],
  //   });
  // }

  // get f() {
  //   return this.enquiryForm.controls;
  // }

  // onSubmit() {
  //   this.submitted = true;

  //   // Stop here if form is invalid
  //   if (this.enquiryForm.invalid) {
  //     return;
  //   }

  //   const emailData: email = {
  //     firstName: this.f.firstName.value,
  //     userEmail: this.f.userEmail.value,
  //     message: this.f.message.value,
  //     companyName: "",
  //     lastName: "",
  //     services: "",
  //     phoneNumber: ""
  //   };

  //   this.sendEmail(emailData);
  // }

  // sendEmail(emailData: email) {
  //   this.apiService.sendEmail(emailData).subscribe(
  //     (data: any) => {
  //       const { responseCode, responseMessage } = data;
  //       if (responseCode === 200) {
  //         this.showNotificationService.showNotification(notificationTypes.success, responseMessage);
  //         this.enquiryForm.reset();
  //         this.submitted = false;
  //       } else {
  //         this.showNotificationService.showNotification(notificationTypes.error, commonErrorMessage.somethingWentWrong);
  //       }
  //     },
  //     (error) => {
  //       this.showNotificationService.showNotification(notificationTypes.error, commonErrorMessage.somethingWentWrong);
  //     }
  //   );
  // }

}
