<form nz-form [nzAutoTips]="autoTips" [formGroup]="validateForm" (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Title</nz-form-label>
    <nz-form-control [nzSpan]="" nzValidatingTip="Validating...">
      <input nz-input formControlName="title" placeholder="Enter blog title" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Category</nz-form-label>
    <nz-form-control [nzSpan]="" nzValidatingTip="Validating...">
      <input nz-input formControlName="category" placeholder="Enter blog category" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Description</nz-form-label>
    <nz-form-control [nzSpan]="" nzValidatingTip="Validating...">
      <input nz-input formControlName="description" placeholder="Enter blog description" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired> Keywords</nz-form-label>
    <nz-form-control [nzSpan]="" nzValidatingTip="Validating...">
      <input nz-input formControlName="keywords" placeholder="Enter blog keywords" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzOffset]="7" [nzSpan]="12">
      <button nz-button nzType="primary">Submit</button>
    </nz-form-control>
  </nz-form-item>
</form>
