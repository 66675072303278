<div>
  <form [formGroup]="contactForm" (ngSubmit)="onSubmitService()">
    <div class="row g-3">
      <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
        <div class="form-clt">
          <input type="text" placeholder="Name*" formControlName="name">
          <div class="icon">
            <i class="far fa-user"></i>
          </div>
        </div>
        <div *ngIf="submitted && f.name.errors" class="text-danger">
          <!-- Name is required -->
          <div *ngIf="f.name.errors.required ">Name is required</div>
          <div *ngIf="f.name.errors.notOnlyWhitespace && !f.name.errors.required">Name is required</div>
        </div>
      </div>
      <div class="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
        <div class="form-clt">
          <input type="email" placeholder="Email*" formControlName="email">
          <div class="icon">
            <i class="far fa-envelope"></i>
          </div>
        </div>
        <div *ngIf="submitted && f.email.errors" class="text-danger">
          <div *ngIf="f.email.errors.required">Email is required</div>
          <div *ngIf="f.email.errors.email && !f.email.errors.required">Invalid email</div>
        </div>
      </div>
      <div class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
        <div class="form-clt">
          <!-- <select class="w-100" formControlName="service" #testcount (change)="ontestCountSelected(testcount.value)" >
            <option value="" >Choose Services*</option>
            <option *ngFor="let service of dropDownService" [value]="service.value">{{ service.value }}</option>
          </select> -->
          <mat-form-field class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
            <!-- <mat-label>Choose Services</mat-label> -->
            <mat-select  formControlName="service" placeholder="Choose Services*" multiple >
                <mat-option  *ngFor="let service of dropDownService" [value]="service.value" >{{service.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="submitted && f.service?.errors">
          <div *ngIf="f.service.errors?.required" class="text-danger">Please choose a service</div>
        </div>
      </div>
      <div class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
        <div class="form-clt">
          <textarea placeholder="Write Your Message*" formControlName="message"></textarea>
        </div>
        <div *ngIf="submitted && f.message.errors?.required" class="text-danger">
          Message is required
        </div>
        <div *ngIf="submitted && f.message.errors?.notOnlyWhitespace && !f.message.errors.required" class="text-danger">Message is required</div>

        
      </div>
      <div class="col-lg-12 wow fadeInUp" data-wow-delay=".7s">
        <div class="form-clt">

          <re-captcha (resolved)="resolved($event)" siteKey="{{environment.recaptcha.siteKey }}"
            formControlName="recaptcha"></re-captcha>

        </div>
        <div *ngIf="submitted && f.recaptcha.errors" class="text-danger">
          <div *ngIf="f.recaptcha.errors.required">Please verify you are human
          </div>
        </div>

      </div>
      <div class="col-lg-12 wow fadeInUp" data-wow-delay=".4s">
        <button type="submit" class="theme-btn theme-btn-2"  [disabled]="disabled">
          <span>
            Send Us Messages
            <i class="fas fa-chevron-right"></i>
          </span>
        </button>
      </div>
    </div>
  </form>
</div>