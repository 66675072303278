<div class="breadcrumb-wrapper section-padding bg-cover" style="background-image: url('assets/img/breadcrumb.jpg');">
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Project Details</h1>
     
    </div>
  </div>
</div>

<!--<< Project Details Section Start >>-->
<section class="project-details fix section-padding">
  <div class="container">
    <div class="project-details-wrapper">
      <div class="row g-4 justify-content-between align-items-center">
        <div class="col-lg-7">
          <div class="project-title-content">
            <span class="text">About The Project</span>
            <h2>
              Deeper Dive into Our Digital Product Design Masterpieces
            </h2>
            <p>
              Sed ut perspiciatis unde omniste natus voluptatem accusantiume rem aperia eaque ipsa
              quae abillo inventore veritatis quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
              epsam voluptatem quia voluptas aspernatur odites sed quia consequunture
            </p>
            <a href="project-details.html" class="theme-btn">
              <span>
                Visit Website
                <i class="fas fa-chevron-right"></i>
              </span>
            </a>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="details-info">
            <div class="info-items mb-4">
              <h5>Clients</h5>
              <p>Design Studio In USA</p>
            </div>
            <div class="info-items mb-4">
              <h5>Project Type</h5>
              <p>Digital Product Design</p>
            </div>
            <div class="info-items mb-4">
              <h5>Date</h5>
              <p>25 October 2024</p>
            </div>
            <div class="info-items">
              <h5>Website</h5>
              <a href="project-details.html">wiatech.com</a>
            </div>
          </div>
        </div>
      </div>
      <div class="project-details-image">
        <div class="row g-4">
          <div class="col-lg-4">
            <div class="details-image">
              <img src="assets/img/project/details.jpg" alt="img">
            </div>
          </div>
          <div class="col-lg-8">
            <div class="details-image">
              <img src="assets/img/project/details-2.jpg" alt="img">
            </div>
          </div>
        </div>
      </div>
      <div class="project-details-content">
        <div class="row">
          <div class="col-lg-4">
            <div class="title-content">
              <h2>
                Interesting facts in
                Development
              </h2>
            </div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-6">
            <div class="details-content">
              <p>
                Must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I
                will give you a complete account of the system, and expound the actual teachings of the great explorer
                of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure
                itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally
                encounter
              </p>
              <div class="list-items">
                <ul>
                  <li>
                    <i class="far fa-check"></i>
                    Efficient Sprint Planning
                  </li>
                  <li>
                    <i class="far fa-check"></i>
                    Standups and Demos
                  </li>
                </ul>
                <ul>
                  <li>
                    <i class="far fa-check"></i>
                    Iterative Delivery Approach
                  </li>
                  <li>
                    <i class="far fa-check"></i>
                    Problem-solving
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="project-details-content style-2">
        <div class="row">
          <div class="col-lg-4">
            <div class="title-content">
              <h2>
                The Results of <br>
                Our Project
              </h2>
            </div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-6">
            <div class="details-content">
              <p>
                Will give you a complete account of the system, and expound the actual teachings of the great explorer
                of the truth, the master-builder of human happiness rejects, dislikes, or avoids pleasure
              </p>
              <div class="list-items-area">
                <div class="icon">
                  <i class="fas fa-check-circle"></i>
                  Support clients
                </div>
                <p>
                  Sed ut perspiciatis unde omnis natus voluptatem accusantium
                  doloremque laudantium, totam rem aperiam inventore
                </p>
              </div>
              <div class="list-items-area">
                <div class="icon">
                  <i class="fas fa-check-circle"></i>
                  Solve problems
                </div>
                <p>
                  To take a trivial example, which of us ever undertakes laborious
                  physical exercise, except to obtain some advantage
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="project-details-image mt-0">
        <div class="row g-4">
          <div class="col-lg-6">
            <img src="assets/img/project/details-3.jpg" alt="img">
          </div>
          <div class="col-lg-6">
            <img src="assets/img/project/details-4.jpg" alt="img">
          </div>
        </div>
      </div>
      <div class="previous-button">
        <div class="preview-button">
          <span>Preview<i class="fas fa-chevron-right"></i></span>
          <h4>
            <a href="project-details.html">Business Website Design</a>
          </h4>
        </div>
        <div class="icon">
          <i class="fas fa-th"></i>
        </div>
        <div class="preview-button style-2">
          <span>Next<i class="fas fa-chevron-right"></i></span>
          <h4>
            <a href="project-details.html">Mobile Application Design</a>
          </h4>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Brand Section Start >>-->
<div class="brand-section-2 fix section-bg-2 mt-0">
  <div class="container">
    <div class="brand-wrapper style-2">
      <div class="brand-carousel-active-2">
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
      </div>
    </div>
  </div>
</div>