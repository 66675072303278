import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss'],
})

export class TeamDetailComponent implements OnInit {
  submitted = false;
  enquiryForm: FormGroup;

  constructor(
    private formbulider: UntypedFormBuilder
  ) { }

  get userName() {
    return this.enquiryForm.get('userName');
  }

  get emailId() {
    return this.enquiryForm.get('emailId');
  }

  get message() {
    return this.enquiryForm.get('message');
  }

  ngOnInit(): void {
    this.enquiryForm = this.formbulider.group({
      userName: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
      recaptcha: [''],
    });
  }
}
