<div class="breadcrumb-wrapper section-padding bg-cover" style="background-image: url('assets/img/breadcrumb.jpg');">
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">project Two</h1>
      <ul class="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
        <li>
          <a href="index.html">
            Home Page
          </a>
        </li>
        <li>
          <i class="fal fa-minus"></i>
        </li>
        <li>
          project 02
        </li>
      </ul>
    </div>
  </div>
</div>

<!--<< Case Studies Section Start >>-->
<section class="case-study-2 fix section-padding">
  <div class="container">
    <div class="section-title style-2 text-center">
      <span class="wow fadeInUp">Our Recent case study</span>
      <h2 class="wow fadeInUp" data-wow-delay=".3s">
        WHAT WE’RE <span>OFFERING</span> TO <br>
        OUR CUSTOMERS
      </h2>
    </div>
  </div>
  <div class="case-study-wrapper">
    <div class="case-studies-carousel-active-2">
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/05.jpg" alt="case-study-img">
        </div>
        <div class="case-study-content">
          <h3><a href="project-details.html">Professional Website Design</a></h3>
          <p>Web Design & Development</p>
          <a href="project-details.html" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/06.jpg" alt="case-study-img">
        </div>
        <div class="case-study-content">
          <h3><a href="project-details.html">Professional Website Design</a></h3>
          <p>Web Design & Development</p>
          <a href="project-details.html" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/07.jpg" alt="case-study-img">
        </div>
        <div class="case-study-content">
          <h3><a href="project-details.html">Professional Website Design</a></h3>
          <p>Web Design & Development</p>
          <a href="project-details.html" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/05.jpg" alt="case-study-img">
        </div>
        <div class="case-study-content">
          <h3><a href="project-details.html">Professional Website Design</a></h3>
          <p>Web Design & Development</p>
          <a href="project-details.html" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Brand Section Start >>-->
<div class="brand-section-2 fix section-bg-2 mt-0">
  <div class="container">
    <div class="brand-wrapper style-2">
      <div class="brand-carousel-active-2">
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
        <div class="brand-image">
          <img src="assets/img/brand/01.png" alt="brand-img">
        </div>
      </div>
    </div>
  </div>
</div>