import { AbstractControl } from '@angular/forms';

export function ValidateEmail(control: AbstractControl) {
  const email = control.value;
  if (!email) {
    return null; // Required validator will handle empty field
  }
  
  // Regular expression for email validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  
  if (emailRegex.test(email)) {
    return null; // Valid email format
  } else {
    return { email: true }; // Return error if email format is invalid
  }
}
