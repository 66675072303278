<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/bg-case-study-3.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">
        Championing Healthcare Evolution
      </h1>
    </div>
  </div>
</div>

<!--<< About Section Start >>-->

<section class="project-details fix section-padding pb-0 pt-5">
  <div class="container">
    <div class="project-details-wrapper">
      <div class="row g-4 justify-content-between align-items-center">
        <div class="project-title-content text-center">
          <span class="text text-center mt-4">Healthcare</span>
          <h2 style="text-align: center">
            Introducing Our Client: A Healthcare Conglomerate<br />
            in California, USA
          </h2>
          <p style="text-align: center; font-size: 21px">
            Our client, based in California, is at the forefront of healthcare
            innovation, reshaping practices and services globally.
          </p>
        </div>
        <div
          class="container"
          style="
            background-color: ghostwhite;
            border-radius: 20px;
            padding: 30px;
            margin-top: 20px;
          "
        >
          <div class="row">
            <div class="col-12">
              <h3 style="text-align: center; padding: 20px; font-size: 38px">
                Technologies Used
              </h3>
              <div class="row text-center">
                <div class="col-md-3 technology-details">React</div>
                <div class="col-md-3 technology-details">Redux</div>
                <div class="col-md-3 technology-details">REST</div>
                <div class="col-md-3 technology-details">Material-UI</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="row text-center justify-content-center">
                <div class="col-md-3 technology-details">GraphQL</div>

                <div class="col-md-3 technology-details">Node.js</div>
                <div class="col-md-3 technology-details">MongoDB</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="project-details-content overlay-container mt-5"
        style="
          padding: 8%;
          border-radius: 8px;
          background-image: url(../../../assets/img/project/Healthcare-Challenges.jpg);
          background-size: cover;
        "
      >
        <div class="overlay"></div>
        <div class="title-content text-center">
          <h2 style="color: white">Challenges in Healthcare Technology</h2>
        </div>
        <div
          class="details-content text-center"
          style="font-size: 18px; padding: 20px; color: white"
        >
          <p>
            In the midst of digital transformation, our client sought to enhance
            their digital systems to address scalability, talent shortages, and
            technical complexities.
          </p>
        </div>
      </div>

      <div class="project-details-content mt-5">
        <div class="row">
          <div class="col-lg-6 text-center">
            <div>
              <img
                src="../../../assets/img/project/healthcare.jpg"
                alt=""
                style="
                  width: 280px;
                  height: 280px;
                  border-radius: 50%;
                  border: 4px inset #fff;
                  margin-top: 20px;
                "
              />
            </div>
          </div>

          <div class="col-lg-6 mt-5">
            <div class="title-content p-2">
              <h2>
                Empowering Healthcare Innovation with Groot Software Solutions
              </h2>
            </div>
            <div class="details-content p-3" style="font-weight: 500">
              <p>
                Teaming up with Groot, a leader in tailor-made software
                solutions, our client embarked on a journey to redefine
                healthcare offerings.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="project-details-content" style="padding: 40px">
        <div class="title-content text-center">
          <h2>Crafting Custom Solutions for Healthcare Excellence</h2>
          <h3 style="font-weight: 600px; padding: 15px">
            The Innovators in Financial Excellence
          </h3>
        </div>
        <div class="details-content text-center" style="font-size: 20px">
          <p>
            Our team of skilled engineers developed crucial components for
            healthcare platforms, focusing on adaptability and efficiency to
            enhance user experiences.
          </p>
        </div>
      </div>
      <div
        class="project-details-content overlay-container mt-5"
        style="
          padding: 8%;
          border-radius: 8px;
          background-image: url(../../../assets/img/project/Delivering-Impact.jpg);
          background-size: cover;
        "
      >
        <div class="overlay"></div>
        <div class="title-content text-center">
          <h2 style="color: white">Delivering Tangible Impact</h2>
        </div>
        <div
          class="details-content text-center"
          style="font-size: 18px; padding: 20px; color: white"
        >
          <p>
            Through collaboration and dedication, we transformed our client's
            healthcare solutions, improving development efficiency, user
            engagement, and scalability.
          </p>
        </div>
      </div>

      <div class="project-details-content mt-5">
        <div class="title-content p-3 text-center">
          <h2>Measuring Success: Key Achievements</h2>
        </div>

        <div class="details-content">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-md-6 text-center mt-4">
                  <img
                    src="../../../assets/img/project/key.jpg"
                    alt=""
                    style="height: 260px; width: 260px; border-radius: 50%"
                  />
                </div>
                <div class="col-md-6">
                  <div class="list-items mt-5">
                    <ul style="margin-right: 30%">
                      <li class="list-indent">
                        Development Efficiency:<span class="list-bold">
                          Reduced time by 40%, ensuring faster delivery.
                        </span>
                      </li>
                      <li class="list-indent">
                        User Engagement:<span class="list-bold">
                          Increased by 50%, reflecting improved usability.
                        </span>
                      </li>
                      <li class="list-indent">
                        Scalability:<span class="list-bold">
                          Enhanced by 60%, future-proofing the digital
                          infrastructure.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="project-details-image mt-5">
        <div class="row">
          <div class="col-lg-6">
            <img
              src="../../../assets/img/project/Case-Study3-1.jpg"
              alt="img"
              style="border-radius: 8px; border: solid 2px bl"
            />
          </div>
          <div class="col-lg-6">
            <img
              src="../../../assets/img/project/Case-Study3-2.jpg"
              alt="img"
              style="border-radius: 8px"
            />
          </div>
        </div>
      </div>
      <div class="project-details-content mt-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="title-content">
              <h2>The Outcome: Forging the Future of Healthcare</h2>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="details-content" style="font-size: 20px">
              <p>
                Thanks to our partnership, our client leads the way in
                healthcare innovation, driving positive change worldwide.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container mb-5">
        <div class="cta-chat-wrapper style-2">
          <div
            data-wow-delay=".3s"
            class="chat-items wow fadeInUp col-md-8"
            style="
              visibility: visible;
              animation-delay: 0.3s;
              animation-name: fadeInUp;
            "
          >
            <div class="icon"><i class="flaticon-chat"></i></div>
            <div class="content">
              <h3>Join the Healthcare Innovation Movement</h3>
              <p>
                Discover how Groot Software Solutions can empower your
                organization to lead the way in healthcare excellence.
              </p>
            </div>
          </div>
          <a
            [routerLink]="['/enquiry']"
            data-wow-delay=".5s"
            class="theme-btn bg-white wow fadeInUp"
            style="
              visibility: visible;
              animation-delay: 0.5s;
              animation-name: fadeInUp;
            "
            ><span>
              Schedule a Consultation Today
              <i class="fas fa-chevron-right"></i></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>
