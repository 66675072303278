import { Component, OnInit } from '@angular/core';

declare var $: any; // Declare jQuery globally

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {
  constructor() {}
  
  ngOnInit(): void {
    this.getCarouselScript();
    this.getTestimonialCarouselScript();
    this.getSectionCard();
  }

  //make array of object for frequently ask questions//
   faqs = [
    {
      question: "What does the Groot Software Solutions Partner Program offer?",
      answer:
        "The Groot Software Solutions Partner Program provides an exclusive chance to earn lifelong commissions and rewards by referring your network to Groot Software Solutions. We are happy to have a conversation with anyone in your network who could be a fit for our services.",
      additionalInfo: [
        "Some good referral opportunities are:",
        "People or businesses that need support with a software development project",
        "Companies that are struggling to fill IT-related positions",
        "Businesses looking to outsource their software development to a Nearshore/Offshore provider."
      ]
    },
    {
      question: "What are Groot Software Solution’s area of expertise?",
      answer:
        "Custom Software Development, Software Testing & QA, Cloud Infrastructure & Devops, Mobile & Web Development, Maintenance & Support, Blockchain Consulting, Data Science, Internet of Things, UI/UX Design, E Commerce, Cybersecurity, and many others."
    },
    {
      question: "What are my responsibilities as a partner?",
      answer:
        "Your role as a partner involves referring your network to Groot Software Solutions, aiding in their onboarding process, and encouraging them to opt for premier technology services."
    },
    {
      question: "Is there a fee to join the partner program?",
      answer:
        "No, the Groot Software Solutions Partner Program is entirely free. There are no charges or joining fees."
    },
    {
      question: "When will I receive my earnings?",
      answer:
        "Your earnings will be credited during the first week of the following month of the billing."
    },
    {
      question: "Where can I find more information?",
      answer:
        "For further inquiries, fill out the form above, WhatsApp us at +91-6283282804, or drop us an email at info@grootsoftwares.com"
    }
  ];
  

  getCarouselScript() {
    setTimeout(() => {
      this.loadScript('assets/js/slick.min.js')
        .then(() => {
          $('.service-carousel-active').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });

          // Hide Next and Previous buttons after a short delay
          setTimeout(() => {
            $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
          }, 100); // Adjust the delay as needed
        })
        .catch((error) => console.log('Script loading error', error));

      // Event handlers for previous and next buttons
      $('.testimonial-nav-prev').on('click', function () {
        $('.service-carousel-active').slick('slickPrev');
      });

      $('.testimonial-nav-next').on('click', function () {
        $('.service-carousel-active').slick('slickNext');
      });
    }, 1000);
  }

  getTestimonialCarouselScript() {
    this.loadScript('assets/js/slick.min.js')
      .then(() => {
        $('.testimonial-carousel-active-4').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        });

        // Hide Next and Previous buttons after a short delay
        setTimeout(() => {
          $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
        }, 100); // Adjust the delay as needed
      })
      .catch((error) => console.log('Script loading error', error));

    // Event handlers for previous and next buttons
    $('.testimonial-nav-prev').on('click', function () {
      $('.testimonial-carousel-active-4').slick('slickPrev');
    });

    $('.testimonial-nav-next').on('click', function () {
      $('.testimonial-carousel-active-4').slick('slickNext');
    });
  }

  getSectionCard() {
    this.loadScript('assets/js/slick.min.js')
      .then(() => {
        $('.news-carousel-active').slick({
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 800,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          dotsClass: 'slide-dots',
          responsive: [
            {
              breakpoint: 1399,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1191,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                center: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });

        // Hide Next and Previous buttons after a short delay
        setTimeout(() => {
          $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
        }, 100); // Adjust the delay as needed
      })
      .catch((error) => console.log('Script loading error', error));

    // Event handlers for previous and next buttons
    $('.testimonial-nav-prev').on('click', function () {
      $('.news-carousel-active').slick('slickPrev');
    });

    $('.testimonial-nav-next').on('click', function () {
      $('.news-carousel-active').slick('slickNext');
    });
  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }
}
