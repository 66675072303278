this is common service form for all component that are present ij service
<div>
  <form
    action="#"
    id="contact-form"
    class="message-form"
    [formGroup]="enquiryForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="row g-3">
      <div class="col-lg-12">
        <div class="single-form-input">
          <input
            type="text"
            placeholder="Your Name*"
            formControlName="firstName"
          />
        </div>
        <div *ngIf="submitted && f.firstName.errors" class="text-danger">
          <div *ngIf="f.firstName.errors.required">Name is required</div>
          <div *ngIf="f.firstName.errors.notOnlyWhitespace && !f.firstName.errors.required">Name is required</div>

        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <input
            type="email"
            placeholder="Email Address*"
            formControlName="userEmail"
          />
        </div>
        <div *ngIf="submitted && f.userEmail.errors" class="text-danger">
          <div *ngIf="f.userEmail.errors.required">Email is required</div>
          <div *ngIf="f.userEmail.errors.email && !f.userEmail.errors.required">
            Invalid email
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <textarea
            placeholder="Tell Us A Brief About Your Requirement*"
            formControlName="message"
          ></textarea>
        </div>
        <div *ngIf="submitted && f.message.errors" class="text-danger">
          <div *ngIf="f.message.errors.required">Message is required</div>
          <div *ngIf="f.message.errors.notOnlyWhitespace && !f.message.errors.required">Message is required</div>

        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <re-captcha
            (resolved)="resolved($event)"
            siteKey="{{ environment.recaptcha.siteKey }}"
            formControlName="recaptcha"
          ></re-captcha>
        </div>
        <div *ngIf="submitted && f.recaptcha.errors" class="text-danger">
          <div *ngIf="f.recaptcha.errors.required">
            Please verify you are human
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <button class="theme-btn" type="submit" [disabled]="disabled">
          <span>
            Get A Free Quote
            <i class="fas fa-chevron-right"></i>
          </span>
        </button>
      </div>
    </div>
  </form>
</div>
