<footer class="footer-section section-bg-3">
  <div class="container">
    <div class="footer-top pb-0">
      <div class="footer-logo wow fadeInUp" data-wow-delay=".3s">
        <a [routerLink]="['/']">
          <img
            class="image-ft-logo"
            src="assets/img/logo/footer-logo-2.png"
            alt="img"
          />
        </a>
      </div>
    </div>
    <div class="footer-widgets-wrapper pt-0">
      <div class="row">
        <div
          class="col-xl-6 col-sm-12 col-md-8 col-lg-6 wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div class="single-footer-widget me-4">
            <div class="widget-head">
              <h3>Get In Touch</h3>
            </div>
            <div class="footer-content">
              <p>
                We understand that every challenge is an opportunity we are here
                seize <br />
                a team of dedicated professionals and a culture
              </p>
              <div class="newsletter-items">
                <form [formGroup]="enquiryForm" (ngSubmit)="onSubmit()">
                  <div class="form-clt position-relative">
                    <input
                      type="text"
                      name="email"
                      id="email2"
                      placeholder="Email Address"
                      formControlName="userEmail"
                    />
                    <div
                      *ngIf="submitted && f.userEmail.errors"
                      class="text-danger position-ft"
                    >
                      <div *ngIf="f.userEmail.errors.required">
                        Email is required
                      </div>
                      <div
                        *ngIf="
                          f.userEmail.errors.email &&
                          !f.userEmail.errors.required
                        "
                      >
                        Invalid email
                      </div>
                    </div>
                  </div>  
                  <button
                    class="theme-btn theme-btn-2 hover-white"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                    [disabled]="disabled"
                    
                    type="submit"
                  >
                    <span _ngcontent-maw-c47="">Contact</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 ps-lg-5 col-sm-6 col-md-6 col-lg-3 wow fadeInUp"
          data-wow-delay=".5s"
        >
          <div class="single-footer-widget">
            <div class="widget-head">
              <h5>Services</h5>
            </div>
            <ul class="list-items">
              <li>
                <a
                  [routerLink]="['/strategy-and-transformation']"
                  routerLinkActive="active"
                >
                  Strategy And Transformation
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/applications-and-technology']"
                  routerLinkActive="active"
                >
                  Applications And Technology
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/core-engineering']"
                  routerLinkActive="active"
                >
                  Core Engineering
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/business-&-digitalsolutions']"
                  routerLinkActive="active"
                >
                  Business & Digital Solutions
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-xl-3 ps-lg-5 col-sm-6 col-md-6 col-lg-3 wow fadeInUp"
          data-wow-delay=".7s"
        >
          <div class="single-footer-widget">
            <div class="widget-head">
              <h5>Location</h5>
            </div>
            <div class="footer-address-text">
              <p>Sushma Infinium, Zirakpur(Chandigarh), Punjab, 140603</p>
              <h5>Contact</h5>
              <a href="mailto:info@grootsoftwares.com" class="link-mail"
                >info&#64;grootsoftwares.com</a
              >
              <h5 class="pt-3">
                <a href="tel:+01762460300">0176-2460300</a>
              </h5>
              <ul class="wow fadeInUp" data-wow-delay=".5s">
                <a target="_blank" href="https://in.linkedin.com/company/groot-software-solutions">
                  <li class="d-inline-block">
                    <i class="fab fa-linkedin-in"></i>
                  </li>
                </a>
                <a target="_blank" href="https://www.facebook.com/GrootSoftwareSolutions/">
                  <li class="d-inline-block ms-2">
                    <i class="fab fa-facebook-f"></i>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom style-2">
    <div class="container">
      <div
        class="footer-wrapper d-flex align-items-center justify-content-between"
      >
        <p class="wow fadeInUp color-2" data-wow-delay=".4s">
          Copyright © {{ currentYear }}
          <a [routerLink]="['/']">Groot Software Solutions</a>. All Rights
          Reserved.
        </p>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal1"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <i class="fal fa-x me-2"></i>
      <div class="modal-content">
        <div class="contact-main-wrapper">
          <button
            type="button"
            class="btn-close close-modals"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="contact-form-items">
            <form
              action="contact.php"
              id="contact-form"
              method="POST"
              [formGroup]="enquiryForm"
              (ngSubmit)="onSubmit()"
            >
              <div class="row g-4">
                <div class="col-lg-12 wow fadeInUp" data-wow-delay=".7s">
                  <div class="form-clt">
                    <re-captcha
                      (resolved)="resolved($event)"
                      siteKey="{{ environment.recaptcha.siteKey }}"
                      formControlName="recaptcha"
                    ></re-captcha>
                  </div>
                  <div
                    *ngIf="submitted && f.recaptcha.errors"
                    class="text-danger"
                  >
                    <div *ngIf="f.recaptcha.errors.required">
                      Please verify you are human
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 wow fadeInUp" data-wow-delay=".8s">
                  <button type="submit" class="theme-btn" [disabled]="disabled">
                    <span><i class="fal fa-paper-plane me-2"></i>Submit</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
