import { Component, OnInit } from '@angular/core';

declare var $: any; // Declare jQuery globally

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})

export class TeamComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.getTestimonialCarouselScript();
  }

  teamMembers = [
    {
      name: 'Ankesh Verma',
      role: 'Director',
      imageUrl: 'assets/img/team/01.jpg',
      linkedInUrl: 'https://www.linkedin.com/in/ankesh1989/',
      bio: 'Ankesh Verma, our co-founder and the go-to guy for domain architecture, is the brain behind our innovative ideas. He\'s like the GPS for our tech journey, navigating through complex domains and steering us towards success.'
    },
    {
      name: 'Harsh Kaushal',
      role: 'Director',
      imageUrl: 'assets/img/team/02.jpg',
      linkedInUrl: 'https://www.linkedin.com/in/harshkaushal/',
      bio: 'Harsh Kaushal, the wizard of solution architecture and co-founder. He\'s the architect behind our game-changing solutions, turning challenges into opportunities and paving the way for growth and progress.'
    },
    {
      name: 'Naveen Tiwari',
      role: 'Director',
      imageUrl: 'assets/img/team/03.jpg',
      linkedInUrl: 'https://www.linkedin.com/in/naveen-tiwari-groot/',
      bio: 'Naveen Tiwari, another co-founder and our application guru, is the genius behind our user-friendly designs. He crafts apps that feel like a breeze to use, making life simpler for our customers and setting new standards in usability.'
    },
    // {
    //   name: 'Mohit Sharma',
    //   role: 'Director',
    //   imageUrl: 'assets/img/team/04.jpg',
    //   linkedInUrl: 'https://www.linkedin.com/in/mohit-sharma-groot/',
    //   bio: 'Mohit Sharma, our co-founder and the guru of enterprise software, brings a wealth of knowledge to the table with more than 20+ years of experience. He\'s like the strategic navigator for our corporate clients, guiding them towards solutions that exceed expectations and drive success.'
    // },
  ];

  getTestimonialCarouselScript() {
    this.loadScript('assets/js/slick.min.js').then(() => {
      $('.testimonial-carousel-active-3').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
      });

      // Hide Next and Previous buttons after a short delay
      setTimeout(() => {
        $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
      }, 100); // Adjust the delay as needed
    }).catch(error => console.log('Script loading error', error));

    // Event handlers for previous and next buttons
    $('.testimonial-nav-prev').on('click', function () {
      $('.testimonial-carousel-active-3').slick('slickPrev');
    });

    $('.testimonial-nav-next').on('click', function () {
      $('.testimonial-carousel-active-3').slick('slickNext');
    });
  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }
}
