import { Component, OnInit } from '@angular/core';

declare var $: any; // Declare jQuery globally

@Component({
  selector: 'app-blogdetail2',
  templateUrl: './blogdetail2.component.html',
  styleUrls: ['./blogdetail2.component.scss']
})

export class BlogdetailComponent2 implements OnInit {


  constructor() { }

  ngOnInit(): void {
    this.getCarouselScript();
    this.getTestimonialCarouselScript();
    this.getSectionCard();
  }



  getCarouselScript() {
    setTimeout(() => {
      this.loadScript('assets/js/slick.min.js').then(() => {
        $('.service-carousel-active').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        // Hide Next and Previous buttons after a short delay
        setTimeout(() => {
          $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
        }, 100); // Adjust the delay as needed
      }).catch(error => console.log('Script loading error', error));

      // Event handlers for previous and next buttons
      $('.testimonial-nav-prev').on('click', function () {
        $('.service-carousel-active').slick('slickPrev');
      });

      $('.testimonial-nav-next').on('click', function () {
        $('.service-carousel-active').slick('slickNext');
      });
    }, 1000);

  }

  getTestimonialCarouselScript() {
    this.loadScript('assets/js/slick.min.js').then(() => {
      $('.testimonial-carousel-active-4').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
      });

      // Hide Next and Previous buttons after a short delay
      setTimeout(() => {
        $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
      }, 100); // Adjust the delay as needed
    }).catch(error => console.log('Script loading error', error));

    // Event handlers for previous and next buttons
    $('.testimonial-nav-prev').on('click', function () {
      $('.testimonial-carousel-active-4').slick('slickPrev');
    });

    $('.testimonial-nav-next').on('click', function () {
      $('.testimonial-carousel-active-4').slick('slickNext');
    });
  }

  getSectionCard() {
    this.loadScript('assets/js/slick.min.js').then(() => {
      $('.news-carousel-active').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 800,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        dotsClass: 'slide-dots',
        responsive: [
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1191,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              center: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1
            }
          }
        ],

      });

      // Hide Next and Previous buttons after a short delay
      setTimeout(() => {
        $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
      }, 100); // Adjust the delay as needed
    }).catch(error => console.log('Script loading error', error));

    // Event handlers for previous and next buttons
    $('.testimonial-nav-prev').on('click', function () {
      $('.news-carousel-active').slick('slickPrev');
    });

    $('.testimonial-nav-next').on('click', function () {
      $('.news-carousel-active').slick('slickNext');
    });
  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

}
