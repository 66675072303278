export const caseStudies = [
    {
      imageSrc: 'assets/img/case-studies/05.jpg',
      title: 'Boosting Financial Data Efficiency',
      category: 'Financial Services',
      routerLink: '/boosting-financial-data-efficiency'
    },
    {
      imageSrc: 'assets/img/case-studies/06.jpg',
      title: 'Unlocking Digital Creativity',
      category: 'Digital Media & Entertainment',
      routerLink: '/unlocking-digital-creativity'
    },
    {
      imageSrc: 'assets/img/case-studies/07.jpg',
      title: 'Championing Healthcare Evolution',
      category: 'Healthcare',
      routerLink: '/championing-healthcare-evolution'
    },
    {
      imageSrc: 'assets/img/case-studies/08.jpg',
      title: 'Revolutionizing Fintech',
      category: 'Fintech',
      routerLink: '/revolutionizing-fintech-with-groot-software-solutions'
    }
  ];
  