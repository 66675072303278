import { Component, OnInit,  } from '@angular/core';
import {Router} from '@angular/router';
import { NavigationStart } from '@angular/router';
import * as _ from "lodash";

@Component({
  selector: 'admin-layout',
  templateUrl:"./layout.component.html",
  styleUrl:"./layout.component.scss"
})


export class AdminLayout implements OnInit {

  constructor(
    public router: Router
  ) {}

  isCollapsed = false
  currentUrl = ""

  getPageName(url) {

    let pageName = url.split("/")?.[2];
    if(typeof pageName === "string" && pageName?.length){
      this.currentUrl = _((pageName?.replace("-", " ")?.replace("-", " "))).capitalize()
    }else{
      this.currentUrl = ""
    }
  }

  ngOnInit() {
    this.router.events.subscribe(event =>{
      if (event instanceof NavigationStart){
         this.getPageName(event.url);
      }
   })
 }


}
