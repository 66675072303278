<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/bg-3.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Life At Groot</h1>
    </div>
  </div>
</div>

<!--<< About Section Start >>-->

<section class="team-details-section fix section-padding">
  <div class="container">
    <div class="team-details-wrapper">
      <div class="row justify-content-between align-items-center">
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay=".3s"
          style="
            visibility: visible;
            animation-delay: 0.3s;
            animation-name: fadeInUp;
          "
        >
          <div
            class="team-image bg-cover bg-image-life"
            style="background-image: url('assets/img/team/details-1.jpg')"
          ></div>
        </div>
        <div
          class="col-lg-5 mt-5 mt-lg-0 wow fadeInUp"
          data-wow-delay=".5s"
          style="
            visibility: visible;
            animation-delay: 0.5s;
            animation-name: fadeInUp;
          "
        >
          <div class="team-details-content">
            <h3>Life at <br />Groot Software Solutions</h3>
            <span>A work life driven by excellence! </span>
            <p>
              Every member of the fraternity at Groot is driven by the idea of
              excelling in the particular work role. We, as a collective team
              have succeeded in developing a healthy work sphere where everyone,
              not only has a clarity of his/her roles, but also compliments
              other team members through work output.
            </p>

            <a [routerLink]="['/enquiry']" class="theme-btn mt-5">
              <span class="mb-0">
                Get a Free Quote
                <i class="fas fa-chevron-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="grow-section section-padding section-bg-3 border-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="grow-wrapper">
          <div class="section-title style-2 col-md-12">
            <div class="content white-cnt my-cnt">
              <p class="mt-3 mb-3">
                We have a highly inter-woven network of work roles where
                deliverables from each team are valued and verified by the tasks
                performed by other teams. This, not only lends a different
                perspective to the task accomplished, but also invokes
                excellence and an urge to deliver better than before.
              </p>
              <p class="mt-4">
                Groot is all about ‘thinking out of the box’ and encouraging to
                innovate and find more than one solution to a given problem. For
                this, we encourage everyone to not stick to the rule book and
                have the freedom to work on a task, with an own, personal
                approach.
              </p>
              <ul
                data-wow-delay=".7s"
                class="about-list wow fadeInUp mt-4"
                style="
                  visibility: visible;
                  animation-delay: 0.7s;
                  animation-name: fadeInUp;
                "
              >
                <li>
                  <i class="far fa-check me-2"></i>
                  Innovation
                </li>
                <li>
                  <i class="far fa-check me-2"></i>
                  Brilliance
                </li>
                <li>
                  <i class="far fa-check me-2"></i>
                  Competitive
                </li>
                <li>
                  <i class="far fa-check me-2"></i>
                  Rewarding
                </li>
                <li>
                  <i class="far fa-check me-2"></i>
                  Solution-Oriented Organization
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <img
          src="assets/img/contact-3.png"
          alt="LifeAtGroot"
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</section>
