import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiServices } from 'src/app/api-services';
import { email } from 'src/app/models/email';
import { notificationTypes, commonErrorMessage } from 'src/utils/constants';
import { ValidateEmail } from 'src/app/validation';
import { ShowNotificationsService } from '../services/show-notifications.service';
import { environment } from 'src/environments/environment';
import { notOnlyWhitespace } from 'src/app/Emptyspace';
import { caseStudies } from 'src/app/casestudy';

declare var $: any; // Declare jQuery globally

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})

export class PortfolioComponent implements OnInit {

  caseStudies = caseStudies;
  environment = environment;

  enquiryForm: FormGroup;

  submitted = false;

  disabled =false;

  formTitle="Please Send A Message To Get The Conversation Started";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiServices,
    private showNotificationService:ShowNotificationsService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getSectionCard();
  }

  createForm(){
    this.enquiryForm = this.formBuilder.group({
      firstName: ['', [Validators.required,notOnlyWhitespace]],
      userEmail: ['', [Validators.required, Validators.email, ValidateEmail]],
      companyName: ['', [Validators.required,notOnlyWhitespace]],
      lastName: ['', [Validators.required,notOnlyWhitespace]],
      phoneNumber: ['', [Validators.required,Validators.pattern("^[0-9 ()+-]*$"),notOnlyWhitespace]],
      recaptcha : ['',[Validators.required]]

    });
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  get f() {
    return this.enquiryForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.enquiryForm.invalid) {
      return;
    }
    const emailData: email = {
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      userEmail: this.f.userEmail.value,
      companyName: this.f.companyName.value,
      phoneNumber: this.f.phoneNumber.value,
      message: '',
      services: ''
    }

    this.sendEmail(emailData);
  }

  sendEmail(emailData: email) {
    this.disabled = true;

    this.apiService.sendEmail(emailData,this.formTitle).subscribe(
      (data: any) => {
        const { responseCode, responseMessage } = data;
        if (responseCode === 200) {
          this.showNotificationService.showNotification(notificationTypes.success, responseMessage);
          this.enquiryForm.reset();
         this.submitted = false;
        } else {
          this.showNotificationService.showNotification(notificationTypes.error, commonErrorMessage.somethingWentWrong);
        }
        this.disabled = false;

      },
      (error) => {
        this.disabled = false;

        this.showNotificationService.showNotification(notificationTypes.error, commonErrorMessage.somethingWentWrong);
      }
    );
  }

  getSectionCard() {
    this.loadScript('assets/js/slick.min.js').then(() => {
      $('.case-studies-carousel-active-2').slick({
        autoplay: true,
        autoplaySpeed: 1500,
        speed: 1000,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        dotsClass: 'slide-dots',
        responsive: [
          {
            breakpoint: 1499,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              center: true,
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1
            }
          }
        ],

      });

      // Hide Next and Previous buttons after a short delay
      setTimeout(() => {
        $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
      }, 100); // Adjust the delay as needed
    }).catch(error => console.log('Script loading error', error));

    // Event handlers for previous and next buttons
    $('.testimonial-nav-prev').on('click', function () {
      $('.news-carousel-active').slick('slickPrev');
    });

    $('.testimonial-nav-next').on('click', function () {
      $('.news-carousel-active').slick('slickNext');
    });
  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

}
