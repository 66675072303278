<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/bg-1.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Company Profile</h1>
    </div>
  </div>
</div>

<!--<< About Section Start >>-->
<section class="about-section fix section-padding">
  <div class="container">
    <div class="about-wrapper">
      <div class="row">
        <div class="col-lg-6">
          <div class="about-image-items">
            <div class="circle-shape">
              <img
                src="assets/img/about/circle.png"
                alt="img"
                class="text-circle"
              />
              <div class="award-img">
                <img src="assets/img/about/award.png" alt="img" />
              </div>
            </div>
            <div class="border-shape">
              <img src="assets/img/about/border-shape.png" alt="shape-img" />
            </div>
            <div
              class="about-image bg-cover wow fadeInLeft"
              data-wow-delay=".3s"
              style="background-image: url('assets/img/about/about.jpg')"
            >
              <div class="about-image-2 wow fadeInUp" data-wow-delay=".5s">
                <img src="assets/img/about/about-2.jpg" alt="about-img" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="about-content">
            <div class="section-title">
              <span class="wow fadeInUp"
                >Groot Software Solutions At a Glance</span
              >
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              We are an award-winning IT services company serving companies and
              businesses worldwide, providing dedicated tech services. We
              provide professional, high-standard, impactful software
              development, IT management and IT support services that help your
              business create a niche. We are proud of our highly professional
              and dedicated team that makes us stand out! We are currently
              serving clients in more than 35 countries. On a collective scale,
              our client companies are serving more than 5 million customers
              across the globe.
            </p>

            <p class="mt-3">
              Our team of experts works on custom-made solutions, specially
              designed for our customers from diverse industries. We take
              immense pride in providing bespoke solutions in cloud services,
              web development, UI/UX designing, IT management, data
              visualisation and IT security services. The extent of our services
              is capable of serving the entire gamut of IT facilities required
              by a small and large businesses.
            </p>

            <ul class="about-list wow fadeInUp" data-wow-delay=".7s">
              <li>
                <i class="far fa-check me-2"></i>
                Tailored Solutions, Seamless Integration
              </li>
              <li>
                <i class="far fa-check me-2"></i>
                Customized Transformation Roadmaps
              </li>
              <li>
                <i class="far fa-check me-2"></i>
                Client-Centric Tech Empowerment
              </li>
            </ul>
            <div class="about-author">
              <div class="about-button wow fadeInUp" data-wow-delay=".8s">
                <a [routerLink]="['/portfolio']" class="theme-btn">
                  <span>
                    Checkout Our Portfolio
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Service Section Start >>-->
<section class="service-section fix section-padding section-bg">
  <div class="shape-image">
    <img src="assets/img/service/shape.png" alt="shape-img" />
  </div>
  <div class="container">
    <div class="title-section-area">
      <div class="section-title">
        <h2 class="wow fadeInUp" data-wow-delay=".3s">OUR CORE VALUES</h2>
      </div>
      <a
        [routerLink]="['/enquiry']"
        class="theme-btn wow fadeInUp"
        data-wow-delay=".5s"
      >
        <span>
          Contact Us
          <i class="fas fa-chevron-right"></i>
        </span>
      </a>
    </div>
  </div>
  <div class="service-wrapper">
    <div class="card-group mt-5" style="gap: 30px">
      <div class="card card-radius">
        <img
          class="card-img-top"
          src="../../../assets/img/service/Transparency.jpg"
          alt="Card image cap"
        />
        <div class="service-card-items">
          <div class="card-body card-radius service-content">
            <div class="flex-container">
              <div class="icon">
                <i class="flaticon-service"></i>
              </div>
              <h5 class="card-title">Transparency</h5>
            </div>

            <p class="card-text mt-4">
              At Groot, transparency isn't merely a principle, it's our
              cornerstone for unity. We cultivate an environment where feedback
              flows freely, fostering open and honest dialogue essential for our
              collective progress.
            </p>
          </div>
        </div>
      </div>

      <div class="card card-radius">
        <img
          class="card-img-top"
          src="../../../assets/img/service/empowerment.jpg"
          alt="Card image cap"
        />
        <div class="service-card-items">
          <div class="card-body card-radius service-content">
            <div class="flex-container">
              <div class="icon">
                <i class="flaticon-blueprint"></i>
              </div>
              <h5 class="card-title mt-4">Empowerment</h5>
            </div>
            <p class="card-text mt-4">
              Leverage your strong online presence to find referral partners in
              your client base who match our potential clients and join our
              referral partner program as valuable allies.
            </p>
          </div>
        </div>
      </div>

      <div class="card card-radius">
        <img
          class="card-img-top"
          src="../../../assets/img/service/inclusivity (1).jpeg"
          alt="Card image cap"
        />
        <div class="service-card-items">
          <div class="card-body card-radius service-content">
            <div class="flex-container">
              <div class="icon">
                <i class="flaticon-cloud-storage"></i>
              </div>
              <h5 class="card-title">Inclusivity</h5>
            </div>

            <p class="card-text mt-4">
              Inclusion isn't just an aspiration; it's ingrained in our DNA. At
              Groot, we embrace diversity as a strength, welcoming everyone into
              our family regardless of their background or origin.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Manage It Section Start >>-->
<section class="manage-it-section fix section-padding">
  <div class="container">
    <div class="manage-it-wrapper">
      <div class="row justify-content-between">
        <div class="col-lg-6">
          <div class="manage-it-content">
            <div class="section-title">
              <span class="wow fadeInUp">OUR CULTURE</span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                Behind the Scenes
              </h2>
            </div>
            <div class="tab-content-items">
              <p>
                Groot doesn't just preach, we practice. Our enthusiasm for our
                work fuels our commitment to embracing diversity and thriving in
                a dynamic workplace. Every unique perspective enriches our
                culture, making Groot a truly vibrant place to grow and succeed.
              </p>
              <div class="list-area"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 mt-5 mt-lg-0 wow fadeInUp" data-wow-delay=".4s">
          <div
            class="manage-image bg-cover"
            style="background-image: url('assets/img/about/about-8.jpg')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="manage-it-section fix section-padding pt-4">
  <div class="container">
    <div class="manage-it-wrapper">
      <div class="row justify-content-between">
        <div class="col-lg-5 mt-5 mt-lg-0 wow fadeInUp" data-wow-delay=".4s">
          <div
            class="manage-image bg-cover"
            style="background-image: url('assets/img/about/about-7.jpg')"
          ></div>
        </div>
        <div class="col-lg-6">
          <div class="manage-it-content">
            <div class="section-title">
              <span class="wow fadeInUp">What We Do </span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                Experience and Expertise
              </h2>
            </div>
            <div class="tab-content-items">
              <p>
                With a proven track record spanning more than two decades, Groot
                Software Solutions has become a trusted name in the industry.
                Our extensive experience and expertise have earned us the trust
                of companies worldwide, making us the go-to strategic partner
                for business transformation
              </p>
              <div class="list-area"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="service-section fix section-padding section-bg-2">
  <div class="left-shape">
    <img src="assets/img/service/left-shape.png" alt="shape-img" />
  </div>
  <div class="right-shape">
    <img src="assets/img/service/right-shape.png" alt="shape-img" />
  </div>
  <div class="line-shape-2">
    <img src="assets/img/service/line-shape-2.png" alt="shape-img" />
  </div>
  <div class="container">
    <div class="service-wrapper-2">
      <div class="row justify-content-between">
        <div class="col-lg-4">
          <div class="service-content">
            <div class="section-title">
              <span
                class="wow fadeInUp"
                style="visibility: visible; animation-name: fadeInUp"
                >How We Do</span
              >
              <h2
                class="wow fadeInUp size-off"
                data-wow-delay=".3s"
                style="
                  visibility: visible;
                  animation-delay: 0.3s;
                  animation-name: fadeInUp;
                "
              >
                Crafting Experiences with Our Tech Blend Approach
              </h2>
            </div>
            <p
              class="mt-4 mt-md-0 wow fadeInUp"
              data-wow-delay=".5s"
              style="
                visibility: visible;
                animation-delay: 0.5s;
                animation-name: fadeInUp;
              "
            >
              At Groot Software Solutions, we’re all about shaking things up in
              the tech world. Picture this: you got your tech mojo (that's the
              Engineering DNA) mixed with some serious user love (we call it
              Experience DNA). That’s our secret sauce for flipping the script
              on how businesses roll in the digital age. Let's blend brilliance
              and revolutionize your digital journey together.
            </p>
            <div
              class="service-button wow fadeInUp"
              data-wow-delay=".7s"
              style="
                visibility: visible;
                animation-delay: 0.7s;
                animation-name: fadeInUp;
              "
            >
              <a [routerLink]="['/services']" class="theme-btn">
                <span>
                  Explore Services
                  <i class="fas fa-chevron-right"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-7 mt-5 mt-lg-0">
          <div class="row g-4">
            <div
              class="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay=".3s"
              style="
                visibility: visible;
                animation-delay: 0.3s;
                animation-name: fadeInUp;
              "
            >
              <div class="service-box-items-2 content-hg">
                <div class="icon">
                  <i class="flaticon-blueprint"></i>
                </div>
                <div class="content">
                  <h3>
                    <a [routerLink]="['/services']">Service Offering</a>
                  </h3>
                  <p>
                    Our comprehensive suite of services covers every aspect of
                    your business needs. From initial strategy development and
                    design to ongoing operations management, we're here to
                    support you every step of the way. Whether you're looking to
                    streamline your processes, enhance your digital presence, or
                    optimize your operations, we have the expertise to help you
                    succeed.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay=".5s"
              style="
                visibility: visible;
                animation-delay: 0.5s;
                animation-name: fadeInUp;
              "
            >
              <div class="service-box-items-2 content-hg">
                <div class="icon">
                  <i class="flaticon-hiring"></i>
                </div>
                <div class="content">
                  <h3>
                    <a [routerLink]="['/services']">Technology Focus</a>
                  </h3>
                  <p>
                    At Groot, we're passionate about technology and its
                    potential to drive innovation. That's why we stay at the
                    forefront of the latest advancements in cloud computing,
                    data analytics, artificial intelligence, and more. By
                    leveraging these cutting-edge technologies, we empower our
                    clients to stay ahead of the curve and achieve their
                    business goals
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  _ngcontent-avt-c47=""
  class="grow-section section-padding section-bg-3"
>
  <div _ngcontent-avt-c47="" class="container">
    <div _ngcontent-avt-c47="" class="grow-wrapper">
      <div _ngcontent-avt-c47="" class="section-title style-2 col-md-12">
        <span
          _ngcontent-avt-c47=""
          class="text-white wow fadeInUp ft-twenty"
          style="visibility: visible; animation-name: fadeInUp"
          >Why Groot Software Solutions</span
        >
        <div _ngcontent-avt-c47="" class="content white-cnt">
          <p _ngcontent-avt-c47="" class="mt-3 mb-3">
            What sets us apart at Groot Software Solutions is our commitment to
            our clients' success. Our dedicated team of industry experts works
            tirelessly to understand your unique challenges and goals, crafting
            tailored solutions that deliver real results. Whether you're a
            startup looking to disrupt the market or an established enterprise
            seeking to stay competitive, we're here to help you thrive in the
            digital age
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container margin-minus mb-5">
  <div class="service-counter-wrapper border-rad">
    <div class="row">
      <div class="col-lg-5">
        <div class="service-counter-content">
          <div class="section-title">
            <span
              class="text-white wow fadeInUp"
              style="visibility: visible; animation-name: fadeInUp"
              >Unmatched Expertise, Unlimited Possibilities</span
            >
          </div>
          <p
            class="mt-4 mt-md-0 text-white wow fadeInUp"
            data-wow-delay=".5s"
            style="
              visibility: visible;
              animation-delay: 0.5s;
              animation-name: fadeInUp;
            "
          >
            Groot doesn't just deliver results; we redefine what's possible,
            driving efficiency, productivity, and profitability for your
            business, whether you're an Enterprise, SMB or a small startup.
          </p>
          <a
            [routerLink]="['/enquiry']"
            class="theme-btn bg-white mt-4 wow fadeInUp"
            data-wow-delay=".7s"
            style="
              visibility: visible;
              animation-delay: 0.7s;
              animation-name: fadeInUp;
            "
          >
            <span>
              Schedule A Consultation
              <i class="fas fa-chevron-right"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="col-lg-7 mt-5 mt-lg-0">
        <div class="row g-4">
          <div
            class="col-lg-4 wow fadeInUp"
            data-wow-delay=".3s"
            style="
              visibility: visible;
              animation-delay: 0.3s;
              animation-name: fadeInUp;
            "
          >
            <div class="service-counter-items">
              <div class="icon">
                <i class="flaticon-earth-globe"></i>
              </div>
              <div class="content">
                <h2><span class="count">80</span>+</h2>
                <p>
                  Projects
                  <br />
                  Completed
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 wow fadeInUp"
            data-wow-delay=".5s"
            style="
              visibility: visible;
              animation-delay: 0.5s;
              animation-name: fadeInUp;
            "
          >
            <div class="service-counter-items">
              <div class="icon">
                <i class="flaticon-document-management"></i>
              </div>
              <div class="content">
                <h2><span class="count">50</span>+</h2>
                <p>
                  Certified<br />
                  Experts
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 wow fadeInUp"
            data-wow-delay=".7s"
            style="
              visibility: visible;
              animation-delay: 0.7s;
              animation-name: fadeInUp;
            "
          >
            <div class="service-counter-items">
              <div class="icon">
                <i class="flaticon-financial-profit"></i>
              </div>
              <div class="content">
                <h2><span class="count">73</span>+</h2>
                <p>Best-in-class <br />NPS Score</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section
  _ngcontent-avt-c47=""
  class="grow-section section-padding section-bg-3 border-grt pb-0"
>
  <div _ngcontent-avt-c47="" class="container">
    <div _ngcontent-avt-c47="" class="grow-wrapper">
      <div _ngcontent-avt-c47="" class="section-title style-2 col-md-12">
        <span
          _ngcontent-avt-c47=""
          class="text-white wow fadeInUp ft-twenty"
          style="visibility: visible; animation-name: fadeInUp"
          >Meet Our Team
        </span>
        <div _ngcontent-avt-c47="" class="content white-cnt">
          <span
            _ngcontent-avt-c47=""
            class="text-white wow fadeInUp ft-twenty font-twenty"
            style="visibility: visible; animation-name: fadeInUp"
            >Top 1% Certified Talent
          </span>
        </div>
        <section class="project-section fix section-padding pt-5">
          <div class="container">
            <div class="row g-4">
              <div
                class="col-lg-7 wow fadeInUp"
                data-wow-delay=".3s"
                style="
                  visibility: visible;
                  animation-delay: 0.3s;
                  animation-name: fadeInUp;
                "
              >
                <div class="project-image">
                  <img src="assets/img/project/07.jpg" alt="project-img" />

                  <div class="project-content">
                    <h3>
                      <a class="anchor-now">Award Winning Team</a>
                    </h3>
                    <p>Web Design &amp; Development</p>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-5 wow fadeInUp"
                data-wow-delay=".5s"
                style="
                  visibility: visible;
                  animation-delay: 0.5s;
                  animation-name: fadeInUp;
                "
              >
                <div class="project-image">
                  <img src="assets/img/project/08.jpg" alt="project-img" />

                  <div class="project-content">
                    <h3>
                      <a class="anchor-now">Professional Brainstorming</a>
                    </h3>
                    <p>Professional Growth &amp; Development</p>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-5 wow fadeInUp"
                data-wow-delay=".3s"
                style="
                  visibility: visible;
                  animation-delay: 0.3s;
                  animation-name: fadeInUp;
                "
              >
                <div class="project-image">
                  <img src="assets/img/project/09.jpg" alt="project-img" />

                  <div class="project-content">
                    <h3>
                      <a class="anchor-now">Ultimate Talents</a>
                    </h3>
                    <p>Great Talents &amp; Performances</p>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-7 wow fadeInUp"
                data-wow-delay=".5s"
                style="
                  visibility: visible;
                  animation-delay: 0.5s;
                  animation-name: fadeInUp;
                "
              >
                <div class="project-image">
                  <img src="assets/img/project/10.jpg" alt="project-img" />

                  <div class="project-content">
                    <h3>
                      <a class="anchor-now">Professional Mentors</a>
                    </h3>
                    <p>Great Mentors &amp; Teachers</p>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-7 wow fadeInUp"
                data-wow-delay=".3s"
                style="
                  visibility: visible;
                  animation-delay: 0.3s;
                  animation-name: fadeInUp;
                "
              >
                <div class="project-image">
                  <img src="assets/img/project/11.jpg" alt="project-img" />

                  <div class="project-content">
                    <h3>
                      <a class="anchor-now">Professional Team</a>
                    </h3>
                    <p>Team Management &amp; Staff</p>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-5 wow fadeInUp"
                data-wow-delay=".5s"
                style="
                  visibility: visible;
                  animation-delay: 0.5s;
                  animation-name: fadeInUp;
                "
              >
                <div class="project-image">
                  <img src="assets/img/project/12.jpg" alt="project-img" />

                  <div class="project-content">
                    <h3>
                      <a class="anchor-now">Professional Growth</a>
                    </h3>
                    <p>Growth &amp; Development</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
