import { Component } from '@angular/core';

@Component({
  selector: 'ankesh-verma',
  templateUrl: './ankesh-verma.component.html',
  styleUrls: ['./ankesh-verma.component.scss']
})

export class AnkeshVermaComponent {
 constructor() { }
}