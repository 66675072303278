 // Assuming ServiceBox interface is defined in service-box.model.ts

export const serviceBoxes = [
  {
    iconClass: 'flaticon-coding',
    title: 'Strategy and Transformation',
    description: 'Driving industrial transformation through top-tier consulting, cloud computing, blockchain, IoT, and AI/ML expertise for inclusive growth.',
    routerLink: '/strategy-and-transformation'
  },
  {
    iconClass: 'flaticon-server',
    title: 'Applications and Technology',
    description: 'Ensuring inclusive software quality, support, modernization, and cybersecurity to empower businesses with cutting-edge technology solutions.',
    routerLink: '/applications-and-technology'
  },
  {
    iconClass: 'flaticon-service',
    title: 'Core Engineering',
    description: 'Building inclusive digital experiences with custom software, front-end and back-end development, mobile apps, UI/UX design, and advanced web solutions.',
    routerLink: '/core-engineering'
  },
  {
    iconClass: 'flaticon-data-science',
    title: 'Business & Digital Solutions',
    description: 'Facilitating inclusive business efficiency, acceleration, automation, intelligence, digital payments, data insights, and robust database solutions for sustainable growth.',
    routerLink: '/business-&-digitalsolutions'
  }
];
