import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consulting-and-strategy',
  templateUrl: './consulting-and-strategy.component.html',
  styleUrls: ['./consulting-and-strategy.component.scss']
})
export class ConsultingAndStrategyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



}
