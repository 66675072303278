<!--<< About  Ankesh verma Section Start >>-->
<section class="team-details-section fix pt-5">
  <div class="container">
    <div
      class="about-ankesh"
      style="
        padding: 30px;
        background-image: url(../../../../assets/img/team/img-ow.jpg);
      "
    >
      <div class="row justify-content-between align-items-center">
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay=".3s"
          style="
            visibility: visible;
            animation-delay: 0.3s;
            animation-name: fadeInUp;
          "
        >
          <div class="circular--portrait">
            <img class="img-fluid" src="assets/img/team/01.jpg" alt=" pic" />
          </div>
        </div>
        <div
          class="col-lg-6 mt-5 mt-lg-0 wow fadeInUp"
          data-wow-delay=".5s"
          style="
            visibility: visible;
            animation-delay: 0.5s;
            animation-name: fadeInUp;
          "
        >
          <div class="team-details-content">
            <h2>Ankesh Verma</h2>
            <br />

            <h3>Meet Ankesh Verma</h3>
            <br />

            <p style="color: black">
              Ankesh Verma, with over 12 years of Full Stack Development
              experience, leads Groot Software Solutions. His proactive approach
              ensures projects are delivered on time and within budget, tailored
              precisely to your business needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--<< Service Details Section Start >>-->
<section class="service-details fix section-padding pt-5">
  <div class="container">
    <div class="service-details-wrapper">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="seperation mt-5 mb-4"></div>
          <div class="service mt-5">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 mt-0">
                <div class="service-detail">
                  <h4>Services</h4>

                  <ul
                    class="detail"
                    style="list-style-type: circle; padding: 10px"
                  >
                    <li>
                      <p style="color: black">
                        <b>Custom Software Development: </b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Tailored solutions from conception to deployment,
                      utilizing cutting-edge technologies and best practices. We
                      specialize in crafting scalable, secure, and user-friendly
                      applications that meet the unique requirements of your
                      business.
                    </p>
                    <li>
                      <p style="color: black">
                        <b>Web and Mobile App Development: </b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> User-friendly interfaces for seamless digital
                      experiences across all devices, prioritizing functionality
                      and aesthetics. Our team is proficient in creating
                      responsive and intuitive web and mobile applications that
                      engage users and drive conversions.
                    </p>
                    <li>
                      <p style="color: black"><b>Enterprise Solutions:</b></p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Scalable solutions for organizational integration and
                      efficiency, designed to streamline processes and drive
                      growth. We have extensive experience in developing
                      enterprise-grade applications that enhance productivity,
                      collaboration, and decision-making.
                    </p>
                    <li>
                      <p style="color: black">
                        <b>Data Management and Business Intelligence:</b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Extracting actionable insights from your data for
                      informed decisions, leveraging advanced analytics and
                      visualization tools. We specialize in developing robust
                      data management and business intelligence solutions that
                      empower organizations to harness the full potential of
                      their data.
                    </p>
                    <li>
                      <p style="color: black"><b>E-commerce Solutions:</b></p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Secure and efficient online platforms to boost sales
                      and customer satisfaction, integrating payment gateways
                      and inventory management systems. Our e-commerce solutions
                      are designed to optimize the online shopping experience,
                      driving sales and fostering customer loyalty.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="service-details-items">
            <div class="details-content">
              <h3 class="mt-3">Why Choose Us?</h3>
              <ul>
                <li class="text-li">
                  <h6>Expertise:</h6>
                  An experienced team dedicated to delivering top-notch
                  solutions, with a proven track record of successful projects
                  across various industries. We have a team of seasoned
                  professionals with expertise in a wide range of technologies
                  and industries, ensuring that we can meet your unique needs
                  and challenges.
                </li>
                <li class="text-li">
                  <h6>Customization:</h6>
                  Tailored solutions designed specifically for your business,
                  ensuring that your unique needs and challenges are addressed
                  effectively. We take the time to understand your business
                  objectives and requirements, allowing us to develop solutions
                  that are aligned with your goals and objectives.
                </li>
                <li class="text-li">
                  <h6>Quality Assurance:</h6>
                  Rigorous testing and review processes ensure high-quality
                  deliverables, minimizing errors and maximizing user
                  satisfaction. We are committed to delivering solutions that
                  meet the highest quality standards, and we employ rigorous
                  testing and review processes to ensure that our solutions meet
                  your expectations.
                </li>
              </ul>
            </div>

            <div class="seperation mt-5 mb-4"></div>
            <h3>Our Expertise in Action</h3>
            <br />
            <p>
              At Groot Software Solutions, we pride ourselves on delivering
              innovative solutions tailored to your unique business needs. Here
              are some examples of how our directors' expertise translates into
              real-world<br /><b> use cases:</b>
            </p>

            <div class="row pt-5">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/ankesh-use-cases1.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Real-Time Trading Platform
                        </div>
                        <p class="image__description">
                          Develop a real-time trading platform for stock
                          <br />market transactions, with live updates and
                          <br />instant trade execution.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/ankesh-use-cases2.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Healthcare Management System
                        </div>
                        <p class="image__description">
                          Design a HIPAA-compliant system for managing patient
                          records, appointments, and medical billing within<br />
                          healthcare facilities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/ankesh-use-cases3.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">E-learning Platform</div>
                        <p class="image__description">
                          Create an interactive e-learning platform with<br />
                          multimedia content, quizzes, and progress
                          <br />tracking features for online education.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/ankesh-use-cases4.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Inventory Management System
                        </div>
                        <p class="image__description">
                          Develop an inventory management system with barcode
                          scanning, stock tracking, and automatic reorder
                          functionalities for retail businesses.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/ankesh-use-cases5.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          IoT Data Analytics Dashboard
                        </div>
                        <p class="image__description">
                          Build a dashboard for analyzing data from IoT
                          <br />devices, providing insights into device <br />
                          performance,usage patterns, and <br />
                          predictive maintenance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/ankesh-use-cases6.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">Travel Booking Portal</div>
                        <p class="image__description">
                          Design a travel booking portal with integrated payment
                          gateways, itinerary planning, and real-time flight and
                          hotel availability updates.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 30px">
              <h3>Looking for Something Else?</h3>
              <br />
              <p>
                If you have a project idea in mind or need assistance with a
                specific requirement, we're here to help. Get in touch with us
                today to discuss your project needs and explore how Groot
                Software Solutions can bring your vision to life.
              </p>
              <br />
              <div class="col-lg-12">
                <button
                  [routerLink]="['/enquiry']"
                  class="theme-btn"
                  type="submit"
                >
                  <span>
                    Get in Touch
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="main-sidebar">
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">Engagement Models</h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample1"
                    >
                      Fixed Price
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample1">
                    <div class="card card-body">
                      Clear budgets with defined project scopes, ensuring
                      transparency and accountability. Our fixed-price model is
                      ideal for projects with well-defined requirements and
                      deliverables.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample2"
                    >
                      Time and Material
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample2">
                    <div class="card card-body">
                      Flexibility for evolving project requirements, allowing
                      for adjustments based on feedback and market changes. With
                      this model, you pay for the actual time and resources
                      invested in your project, making it suitable for projects
                      with evolving requirements or uncertain timelines.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn3"
                      data-bs-toggle="collapse"
                      href="#collapseExample3"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample3"
                    >
                      Dedicated Team
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample3">
                    <div class="card card-body">
                      Long-term support with a dedicated team of experts,
                      providing ongoing maintenance and updates for continuous
                      improvement. Our dedicated team model offers you full
                      control over the development process and allows for
                      seamless collaboration between our team and yours.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">Featured Projects</h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample4"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample4"
                    >
                      Real-Time Trading System
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample4">
                    <div class="card card-body">
                      Manage bids and sales in real-time for optimal results,
                      incorporating robust security measures and efficient
                      transaction processing. Our real-time trading system is
                      designed to handle high volumes of transactions with
                      minimal latency, ensuring a seamless trading experience
                      for users.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample5"
                    >
                      Healthcare Management System
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample5">
                    <div class="card card-body">
                      HIPAA-compliant solution for streamlined healthcare
                      services, with features for patient management,
                      scheduling, and billing. Our healthcare management system
                      is designed to improve patient outcomes and streamline
                      administrative processes for healthcare providers.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">
                  Discover More with Groot Software Solutions
                </h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample6"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample6"
                    >
                      Technology Stack
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample6">
                    <div class="card card-body">
                      Our team is proficient in a wide range of technologies,
                      including but not limited to React JS, AngularJS, Redux,
                      Node.js, ASP.NET Core, SQL Server, Power BI, and Azure
                      DevOps. We stay up-to-date with the latest technologies
                      and trends to ensure that our solutions are always
                      cutting-edge.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample7"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample7"
                    >
                      Industry Experience
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample7">
                    <div class="card card-body">
                      We have extensive experience working with clients in
                      diverse industries, including finance, healthcare,
                      e-commerce, and more. Our industry-specific expertise
                      allows us to develop solutions that are tailored to the
                      unique needs and challenges of each industry.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample8"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample8"
                    >
                      Client Testimonials
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample8">
                    <div class="card card-body">
                      Hear from our satisfied clients about their experiences
                      working with Groot Software Solutions and the impact our
                      solutions have had on their businesses. We value feedback
                      from our clients and use it to continuously improve our
                      services and solutions.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample9"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample9"
                    >
                      Partnerships
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample9">
                    <div class="card card-body">
                      We partner with industry-leading technology providers to
                      ensure that our solutions are built using the latest tools
                      and technologies, keeping you ahead of the curve. Our
                      partnerships with leading technology providers allow us to
                      leverage their expertise and resources to deliver
                      innovative solutions that drive business results.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <form
                action="#"
                id="contact-form"
                class="message-form"
                style="
                  background-color: #00c0da;
                  padding: 20px;
                  border-radius: 30px;
                  padding-bottom: 50px;
                "
              >
                <div class="row g-3">
                  <div class="wid-title">
                    <h3 style="color: white; text-align: center; padding: 8px">
                      Contact us
                    </h3>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <a href="mailto:info@grootsoftwares.com">
                        <li
                          style="
                            background-color: white;
                            padding: 10px;
                            list-style: none;
                            border-radius: 3px;
                          "
                        >
                          <i class="fas fa-envelope"></i>
                          info&#64;grootsoftwares.com
                        </li>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <a href="tel:+01762460300">
                        <li
                          style="
                            background-color: white;
                            padding: 10px;
                            list-style: none;
                            border-radius: 3px;
                          "
                        >
                          <i class="fas fa-mobile-alt"></i> 0176-2460300
                        </li>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <li
                        style="
                          background-color: white;
                          padding: 10px;
                          list-style: none;
                          border-radius: 3px;
                        "
                      >
                        <i class="fas fa-map-marker-alt"></i> United States │
                        India
                      </li>
                    </div>
                  </div>
                </div>
              </form>

              <app-commonportfoliocomponent></app-commonportfoliocomponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
