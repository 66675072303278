import { Component, OnInit } from '@angular/core';

declare var $: any; // Declare jQuery globally

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.getBrandCarousel();
  }

  getBrandCarousel() {
    this.loadScript('assets/js/slick.min.js').then(() => {
      $('.brand-carousel-active').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        dots: false,
        dotsClass: 'slide-dots',
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              center: true,
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2,
            }
          }
        ],

      });

      // Hide Next and Previous buttons after a short delay
      setTimeout(() => {
        $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
      }, 100); // Adjust the delay as needed
    }).catch(error => console.log('Script loading error', error));


  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

}
