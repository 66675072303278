<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/bg-6.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Career</h1>
    </div>
  </div>
</div>

<!--<< About Section Start >>-->
<section class="about-section fix section-padding">
  <div class="container">
    <div class="about-wrapper">
      <div class="row">
        <div class="col-lg-6">
          <div class="about-image-items">
            <div class="circle-shape">
              <img
                src="assets/img/about/circle.png"
                alt="img"
                class="text-circle"
              />
              <div class="award-img">
                <img src="assets/img/about/award.png" alt="img" />
              </div>
            </div>
            <div class="border-shape">
              <img src="assets/img/about/border-shape.png" alt="shape-img" />
            </div>
            <div
              class="about-image bg-cover wow fadeInLeft"
              data-wow-delay=".3s"
              style="background-image: url('assets/img/about/about.jpg')"
            >
              <div class="about-image-2 wow fadeInUp" data-wow-delay=".5s">
                <img src="assets/img/about/about-2.jpg" alt="about-img" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="about-content mt-5">
            <div class="section-title mt-5">
              <span class="wow fadeInUp"
                >Explore Opportunities with Groot Software Solutions</span
              >
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              At Groot Software Solutions, we thrive on integrity, honesty, and
              a culture that fuels passion and teamwork, driving professional
              growth. Our vibrant working environment fosters continuous
              learning, transforming employees into industry experts. We take
              pride in our diverse workforce, comprising both beginners and
              seasoned professionals, who have placed their trust in Groot
              Software Solutions, contributing to our unbeatable team. We are
              proud to be recognized as a Great Place to Work 2024, acknowledged
              by the leading global analyst, ‘Great Place to Work.’
            </p>

            <p>
              This certification reflects our commitment to building a
              High-Trust, and High-Performance Culture, focusing on
              "Credibility, Respect, Fairness, Pride and Camaraderie." If you
              are ambitious and willing to push boundaries, Groot Software
              Solutions is the place for you. We are always on the lookout for
              individuals who can breathe life into our ideas.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container mt-5">
  <div class="row custom-hg">
    <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
      <div class="service-box-items p-3">
        <div class="content">
          <h3>
            <a>Jr. Software Developer - Full Stack </a>
          </h3>
          <p>
            We are seeking a talented Dot Net developer to join our dynamic
            team. The ideal candidate should have a passion for coding, a drive
            for problem-solving. Having experience of minimum 2-3 years.
          </p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
      <div class="service-box-items p-3">
        <div class="content">
          <h3>
            <a>Sr. Software Developer - Full Stack </a>
          </h3>
          <p>
            We are seeking a talented Dot Net developer to join our dynamic
            team. The ideal candidate should have a passion for coding, a drive
            for problem-solving. Having experience of minimum 3-4 years.
          </p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
      <div class="service-box-items p-3">
        <div class="content">
          <h3>
            <a>Jr. Business Development Executive</a>
          </h3>
          <p>
            We are in search of an experienced BDE to join our
            analytics team. Analysing market trends to create strategies and working towards expanding the organisation. Minimum Experience (1-2 years)
          </p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".9s">
      <div class="service-box-items p-3">
        <div class="content">
          <h3>
            <a>Sr. Business Development Executive</a>
          </h3>
          <p>
            We are in search of an experienced BDE to join our
            analytics team. Analysing market trends to create strategies and working towards expanding the organisation. Minimum Experience (3-4 years)
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<< Manage It Section Start >>-->
<section class="manage-it-section fix section-padding">
  <div class="container">
    <div class="manage-it-wrapper">
      <div class="row justify-content-between">
        <div class="col-lg-6">
          <div class="manage-it-content">
            <div class="section-title">
              <span class="wow fadeInUp">Why Join Us? </span>
            </div>
            <div class="single-tab-items mt-4 mt-md-0">
              <ul class="nav mb-4" role="tablist">
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".3s"
                  role="presentation"
                >
                  <a
                    href="#approach"
                    data-bs-toggle="tab"
                    class="nav-link active"
                    aria-selected="true"
                    role="tab"
                  >
                    Innovative Environment
                  </a>
                </li>
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".5s"
                  role="presentation"
                >
                  <a
                    href="#project"
                    data-bs-toggle="tab"
                    class="nav-link"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                  >
                    Continuous Growth
                  </a>
                </li>
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".7s"
                  role="presentation"
                >
                  <a
                    href="#advisory"
                    data-bs-toggle="tab"
                    class="nav-link"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                  >
                    Great Place to Work Certified
                  </a>
                </li>
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".7s"
                  role="presentation"
                >
                  <a
                    href="#benefits"
                    data-bs-toggle="tab"
                    class="nav-link"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                  >
                    Competitive Benefits
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div
                  id="approach"
                  class="tab-pane fade show active"
                  role="tabpanel"
                >
                  <div class="tab-content-items">
                    <p class="wow fadeInUp" data-wow-delay=".3s">
                      We foster an innovative and inclusive environment where
                      every team member is valued and encouraged to explore
                      their potential.
                    </p>
                  </div>
                </div>
                <div id="project" class="tab-pane fade" role="tabpanel">
                  <div class="tab-content-items">
                    <p>
                      We are committed to providing our employees with
                      opportunities for growth and development through ongoing
                      training and learning initiatives.
                    </p>
                  </div>
                </div>
                <div id="advisory" class="tab-pane fade" role="tabpanel">
                  <div class="tab-content-items">
                    <p>
                      Groot Software Solutions has been certified as a Great
                      Place to Work 2024, recognized for building a High-Trust
                      and High-Performance Culture.
                    </p>
                  </div>
                </div>
                <div id="benefits" class="tab-pane fade" role="tabpanel">
                  <div class="tab-content-items">
                    <p>
                      We offer competitive salaries, health insurance, flexible
                      working hours, and other benefits to ensure the well-being
                      of our employees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 mt-5 mt-lg-0 wow fadeInUp" data-wow-delay=".4s">
          <div
            class="manage-image bg-cover"
            style="background-image: url('assets/img/about/about-7.jpg')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container mb-5">
  <div class="cta-chat-wrapper style-2">
    <div class="chat-items wow fadeInUp col-md-9" data-wow-delay=".3s">
      <div class="icon">
        <i class="flaticon-chat"></i>
      </div>
      <div class="content">
        <h3>How to Apply?</h3>
        <p>
          To apply for any of the positions listed above, please send your
          resume and cover letter to <a href="mailto:hr@grootsoftwares.com"  style="color:#fff; text-decoration: none;">hr&#64;grootsoftwares.com</a> with the subject
          line indicating the position you are applying for. We look forward to
          welcoming you to our team!
        </p>
      </div>
    </div>
    <a
      [routerLink]="['/enquiry']"
      class="theme-btn bg-white wow fadeInUp"
      data-wow-delay=".5s"
    >
      <span>
        Join our Community

        <i class="fas fa-chevron-right"></i>
      </span>
    </a>
  </div>
</div>
