import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
declare var $ : any;
@Component({
  selector: 'app-common-slider',
  templateUrl: './common-slider.component.html',
  styleUrls: ['./common-slider.component.scss']
})
export class CommonSliderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    var parent = this;
    $(document).on('click', '.slidderButton', function () {
      parent.router.navigate(['/enquiry']);
    });
  }

  ngAfterViewInit(): void {
  $(document).ready(function() {
      
    $('#ensign-nivoslider-3').nivoSlider({

      effect: 'fade',

      slices: 15,

      boxCols: 8,

      boxRows: 4,

      animSpeed: 500,

      pauseTime: 5000,

      startSlide: 0,

      directionNav: true,

      controlNavThumbs: true,

      pauseOnHover: true,

      manualAdvance: false

     }); 
    
    })
}

}
