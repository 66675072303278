import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blockchain-software-development',
  templateUrl: './blockchain-software-development.component.html',
  styleUrls: ['./blockchain-software-development.component.scss']
})
export class  BlockchainSoftwareDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
