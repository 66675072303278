import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { EnquiryComponent } from './components/enquiry/enquiry.component';
import { TeamComponent } from './components/team/team.component';
import { TeamDetailComponent } from './components/team/team-details/team-detail.component';
import { ResponsiveHTMLComponent } from './components/services/responsive-html/responsive-html.component';
import { WebPortalDevelopmentComponent } from './components/services/web-portal-development/web-portal-development.component';
import { WebApplicationPaymentGatewaysComponent } from './components/services/web-application-payment-gateways/web-application-payment-gateways.component';
import { CustomWebDevelopmentComponent } from './components/services/custom-web-development/custom-web-development.component';
import { ConsultingAndStrategyComponent } from './components/services/consulting-and-strategy/consulting-and-strategy.component';
import { APIDesignAndDevelopmentComponent } from './components/services/api-design-and-development/api-design-and-development.component';
import { CMSWebDevelopmentComponent } from './components/services/cms-web-development/cms-web-development.component';
import { SoftwareDevelopmentComponent } from './components/services/Enterprise-web/software-development/software-development.component';
import { ProductManagementAndStrategyComponent } from './components/services/product-management-and-strategy/product-management-and-strategy.component';
import { DevOpsComponent } from './components/services/Agile-Transformation/dev-ops/dev-ops.component';
import { SHAREPOINTComponent } from './components/services/sharepoint/sharepoint.component';
import { MicrosoftOfficesComponent } from './components/services/microsoft-offices/microsoft-offices.component';
import { MobileAppComponent } from './components/services/mobile-app/mobile-app.component';
import { EventComponent } from './components/events/event/event.component';
import { EngagementComponent } from './components/engagement/engagement.component';
import { CareerComponent } from './components/career/career.component';
import { PartnerComponent } from './components/partner/partner.component';
import { SuccessComponent } from './components/success/success.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { AwardsComponent } from './components/awards/awards.component';
import { BlogdetailComponent } from './components/blogdetail/blogdetail.component';
import { BlogdetailComponent3 } from './components/blogdetail3/blogdetail3.component';
import { BlogdetailComponent2 } from './components/blogdetail2/blogdetail2.component';
import { LifeatgrootComponent } from './components/LifeAtGroot/lifeatgroot.component';
import { CasestudyComponent } from './components/CaseStudy/casestudy.component';
import { CasestudyOneComponent } from './components/CaseStudyOne/casestudyone.component';
import { CasestudyTwoComponent } from './components/CaseStudyTwo/casestudytwo.component';
import { CasestudyThreeComponent } from './components/CaseStudyThree/casestudythree.component';
import { CasestudyFourComponent } from './components/CaseStudyFour/casestudyfour.component';
import { CloudComputingComponent } from './components/services/cloud-computing/Cloud-Computing.component';
import { BlockchainSoftwareDevelopmentComponent } from './components/services/blockchain-software-development/blockchain-software-development.component';
import { InternetOfThingsComponent } from './components/services/internet-of-things/internet-of-things.component';
import { ArtificialIntelligenceMachineLearningComponent } from './components/services/artificial-intelligence_machine-learning/artificial-intelligence_machine-learning.component';
import { SoftwareTestingQAComponent } from './components/services/software-testing_QA/software-testing_QA.component';
import { MaintenanceSupportComponent } from './components/services/maintenance_support/maintenance_support.component';
import { ModernizationAndMigrationComponent } from './components/services/modernization_and_migration/modernization_and_migration.component';
import { WebApplicationDevelopmentComponent } from './components/services/web-application-development/web-application-development.component';
import { CustomSoftwareDevelopmentComponent } from './components/services/custom-software-development/custom-software-development.component';
import { FrontEndDevelopmentComponent } from './components/services/front-end-development/front-end-development.component';
import { BackEndDevelopmentComponent } from './components/services/back-end-development/backend-development.component';
import { IOSAppDevelopmentComponent } from './components/services/ios-app-development/ios-app-development.component';
import { AndroidAppDevelopmentComponent } from './components/services/android-app-development/android-app-development.component';
import { UiUxDesignComponent } from './components/services/ui-ux-design/ui-ux-design.component';
import { MvpDevelopmentComponent } from './components/services/mvp-development/mvp-development.component';
import { ProgressiveWebApplicationsComponent } from './components/services/progressive-web-application/progressive-web-application.component';
import { ECommerceDevelopmentComponent } from './components/services/e-commerce-development/e-commerce-development.component';
import { ErpCrmComponent } from './components/services/erp-crm/erp-crm.component';
import { DigitalAccelerationComponent } from './components/services/digital-acceleration/digital-accelerationcomponent';
import { BusinessIntelligenceComponent } from './components/services/business-intelligence/business-intelligence.component';
import { ProcessAutomationComponent } from './components/services/process-automation/process-automation.component';
import { DigitalWalletsCryptocurrencyComponent } from './components/services/digital-wallets-cryptocurrency/digital-wallets-cryptocurrency.component';
import { DataScienceComponent } from './components/services/data-science/data-science.component';
import { DatabaseDevelopmentComponent } from './components/services/database-development/database-development.component';
import { AnkeshVermaComponent } from './components/portfolio/ankesh-verma/ankesh-verma.component';
import { NaveenTiwariComponent } from './components/portfolio/naveen-tiwari/naveen-tiwari.component';
import { MohitSharmaComponent } from './components/portfolio/mohit-sharma/mohit-sharma.component';
import { HarshKaushalComponent } from './components/portfolio/harsh-kaushal/harsh-kaushal.component';
import { LoginComponent } from './admin/pages/login/login.component';
import { AddBlogComponent } from './admin/pages/blogs/add/add-blog.component';
import { BlogsListComponent } from './admin/pages/blogs/list/list-blogs.component';
import { AddCaseStudyComponent } from './admin/pages/caseStudies/add/add-case.component';
import { CaseStudiesListComponent } from './admin/pages/caseStudies/list/list-cases.component';
import { AddCareerComponent } from './admin/pages/careers/add/add-career.component';
import { CareersListComponent } from './admin/pages/careers/list/list-careers.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'career',
    component: CareerComponent,
  },
  {
    path: 'engagement-model',
    component: EngagementComponent,
  },
  {
    path: 'become-a-partner',
    component: PartnerComponent,
  },
  {
    path: 'services',
    component: ServicesComponent,
  },
  {
    path: 'enquiry',
    component: EnquiryComponent,
  },
  {
    path: 'faq',
    component: EventComponent,
  },
  {
    path: 'team',
    component: TeamComponent,
  },
  {
    path: 'team-detail',
    component: TeamDetailComponent,
  },
  {
    path: 'project',
    component: ResponsiveHTMLComponent,
  },
  {
    path: 'web-portal-development',
    component: WebPortalDevelopmentComponent,
  },
  {
    path: 'web-application-payment-gateways',
    component: WebApplicationPaymentGatewaysComponent,
  },
  {
    path: 'business-&-digitalsolutions',
    component: CustomWebDevelopmentComponent,
  },
  {
    path: 'core-engineering',
    component: APIDesignAndDevelopmentComponent,
  },
  {
    path: 'strategy-and-transformation',
    component: CMSWebDevelopmentComponent,
  },
  {
    path: 'consulting-and-strategy',
    component: ConsultingAndStrategyComponent,
  },
  {
    path: 'cloud-computing',
    component: CloudComputingComponent,
  },
  {
    path: 'custom-software-development',
    component: CustomSoftwareDevelopmentComponent,
  },
  {
    path: 'front-end-development',
    component: FrontEndDevelopmentComponent,
  },
  {
    path: 'back-end-development',
    component: BackEndDevelopmentComponent,
  },
  {
    path: 'internet-of-things',
    component: InternetOfThingsComponent,
  },
  {
    path: 'android-app-development',
    component: AndroidAppDevelopmentComponent,
  },
  {
    path: 'ui-ux-design',
    component: UiUxDesignComponent,
  },
  {
    path: 'database-development',
    component: DatabaseDevelopmentComponent,
  },
  {
    path: 'mvp-development',
    component: MvpDevelopmentComponent,
  },
  {
    path: 'progressive-web-application',
    component: ProgressiveWebApplicationsComponent,
  },
  {
    path: 'e-commerce-development',
    component: ECommerceDevelopmentComponent,
  },
  {
    path: 'erp-crm',
    component: ErpCrmComponent,
  },
  {
    path: 'data-science',
    component: DataScienceComponent,
  },
  {
    path: 'digital-wallets-cryptocurrency',
    component: DigitalWalletsCryptocurrencyComponent,
  },
  {
    path: 'process-automation',
    component: ProcessAutomationComponent,
  },
  {
    path: 'business-intelligence',
    component: BusinessIntelligenceComponent,
  },
  {
    path: 'digital-acceleration',
    component: DigitalAccelerationComponent,
  },
  {
    path: 'ios-app-devlopment',
    component: IOSAppDevelopmentComponent,
  },
  {
    path: 'artificial-intelligence_machine-learning',
    component: ArtificialIntelligenceMachineLearningComponent,
  },
  {
    path: 'software-testing_QA',
    component: SoftwareTestingQAComponent,
  },
  {
    path: 'maintenance_support',
    component: MaintenanceSupportComponent,
  },
  {
    path: 'modernization_and_migration',
    component: ModernizationAndMigrationComponent,
  },
  {
    path: 'web-application-development',
    component: WebApplicationDevelopmentComponent,
  },
  {
    path: 'blockchain-software-development',
    component: BlockchainSoftwareDevelopmentComponent,
  },
  {
    path: 'service-detail5',
    component: SoftwareDevelopmentComponent,
  },
  {
    path: 'the-power-of-minimalism',
    component: BlogdetailComponent,
  },
  {
    path: 'the-power-of-user-experience',
    component: BlogdetailComponent2,
  },
  {
    path: 'applications-and-technology',
    component: DevOpsComponent,
  },
  {
    path: 'project-detail',
    component: SHAREPOINTComponent,
  },
  {
    path: 'servicedetail6',
    component: MicrosoftOfficesComponent,
  },
  {
    path: 'news',
    component: MobileAppComponent,
  },
  {
    path: 'success-stories',
    component: SuccessComponent,
  },
  {
    path: 'portfolio',
    component: PortfolioComponent,
  },
  {
    path: 'ankesh-verma',
    component: AnkeshVermaComponent,
  },
  {
    path: 'naveen-tiwari',
    component: NaveenTiwariComponent,
  },
  {
    path: 'mohit-sharma',
    component: MohitSharmaComponent,
  },
  {
    path: 'harsh-kaushal',
    component: HarshKaushalComponent,
  },
  {
    path: 'awards-and-recognisation',
    component: AwardsComponent,
  },
  {
    path: 'case-study',
    component: CasestudyComponent,
  },
  {
    path: 'boosting-financial-data-efficiency',
    component: CasestudyOneComponent,
  },
  {
    path: 'unlocking-digital-creativity',
    component: CasestudyTwoComponent,
  },
  {
    path: 'championing-healthcare-evolution',
    component: CasestudyThreeComponent,
  },
  {
    path: 'revolutionizing-fintech-with-groot-software-solutions',
    component: CasestudyFourComponent,
  },
  {
    path: 'navigating-ai',
    component: BlogdetailComponent3,
  },
  {
    path: 'life-at-groot',
    component: LifeatgrootComponent,
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'admin/login',
    component: LoginComponent
  },
  {
    path: 'admin/add-blog',
    component: AddBlogComponent
  },
  {
    path: 'admin/blogs',
    component:BlogsListComponent
  },
  {
    path: 'admin/add-case-study',
    component: AddCaseStudyComponent
  },
  {
    path: 'admin/case-studies',
    component: CaseStudiesListComponent
  },
  {
    path: 'admin/add-career',
    component: AddCareerComponent
  },
  {
    path: 'admin/careers',
    component: CareersListComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  HomeComponent,
  AboutComponent,
  ServicesComponent,
  EnquiryComponent,
  TeamComponent,
  TeamDetailComponent,
  ResponsiveHTMLComponent,
  WebPortalDevelopmentComponent,
  WebApplicationPaymentGatewaysComponent,
  CustomWebDevelopmentComponent,
  APIDesignAndDevelopmentComponent,
  CMSWebDevelopmentComponent,
  SoftwareDevelopmentComponent,
  ProductManagementAndStrategyComponent,
  DevOpsComponent,
  SHAREPOINTComponent,
  MicrosoftOfficesComponent,
  MobileAppComponent,
  CareerComponent,
  EngagementComponent,
  PartnerComponent,
  SuccessComponent,
  PortfolioComponent,
  AwardsComponent,
  BlogdetailComponent,
  BlogdetailComponent2,
  BlogdetailComponent3,
  LifeatgrootComponent,
  CasestudyComponent,
  CasestudyOneComponent,
  CasestudyThreeComponent,
  CasestudyTwoComponent,
  CasestudyFourComponent,
  ConsultingAndStrategyComponent,
  CloudComputingComponent,
  BlockchainSoftwareDevelopmentComponent,
  InternetOfThingsComponent,
  ArtificialIntelligenceMachineLearningComponent,
  SoftwareTestingQAComponent,
  MaintenanceSupportComponent,
  ModernizationAndMigrationComponent,
  WebApplicationDevelopmentComponent,
  CustomSoftwareDevelopmentComponent,
  FrontEndDevelopmentComponent,
  BackEndDevelopmentComponent,
  IOSAppDevelopmentComponent,
  AndroidAppDevelopmentComponent,
  UiUxDesignComponent,
  MvpDevelopmentComponent,
  ProgressiveWebApplicationsComponent,
  ECommerceDevelopmentComponent,
  ErpCrmComponent,
  DigitalAccelerationComponent,
  BusinessIntelligenceComponent,
  ProcessAutomationComponent,
  DigitalWalletsCryptocurrencyComponent,
  DataScienceComponent,
  DatabaseDevelopmentComponent,
  AnkeshVermaComponent,
  NaveenTiwariComponent,
  MohitSharmaComponent,
  HarshKaushalComponent,
  LoginComponent
];
