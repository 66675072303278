import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiServices } from 'src/app/api-services';
import { email } from 'src/app/models/email';
import { notificationTypes, commonErrorMessage } from 'src/utils/constants';
import { ValidateEmail } from 'src/app/validation';
import { ShowNotificationsService } from '../services/show-notifications.service';
import { environment } from 'src/environments/environment';
import { notOnlyWhitespace } from 'src/app/Emptyspace';

@Component({
  selector: 'app-commonportfoliocomponent',
  templateUrl: './commonportfoliocomponent.component.html',
  styleUrls: ['./commonportfoliocomponent.component.scss'],
})
export class CommonportfoliocomponentComponent {
  environment = environment;

  enquiryForm: FormGroup;

  submitted = false;

  disabled = false;

  formTitle = 'Profile Page Visit';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiServices,
    private showNotificationService: ShowNotificationsService
  ) {
    this.createForm();
  }

  createForm() {
    this.enquiryForm = this.formBuilder.group({
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9 ()+-]*$'),notOnlyWhitespace],
      ],
      userEmail: ['', [Validators.required, Validators.email, ValidateEmail]],
      message: ['', [Validators.required,notOnlyWhitespace]],
      recaptcha: ['', [Validators.required]],
    });
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  get f() {
    return this.enquiryForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.enquiryForm.invalid) {
      return;
    }

    const emailData: email = {
      firstName: '',
      userEmail: this.f.userEmail.value,
      message: this.f.message.value,
      companyName: '',
      lastName: '',
      services: '',
      phoneNumber: this.f.phoneNumber.value,
    };

    this.sendEmail(emailData);
  }

  sendEmail(emailData: email) {
    this.disabled = true;

    this.apiService.sendEmail(emailData, this.formTitle).subscribe(
      (data: any) => {
        const { responseCode, responseMessage } = data;
        if (responseCode === 200) {
          this.showNotificationService.showNotification(
            notificationTypes.success,
            responseMessage
          );
          this.enquiryForm.reset();
          this.submitted = false;
        } else {
          this.showNotificationService.showNotification(
            notificationTypes.error,
            commonErrorMessage.somethingWentWrong
          );
        }
        this.disabled = false;
      },
      (error) => {
        this.disabled = false;
        console.error('Error sending email:', error);
        this.showNotificationService.showNotification(
          notificationTypes.error,
          commonErrorMessage.somethingWentWrong
        );
      }
    );
  }
}
