import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonSliderComponent } from './components/common-slider/common-slider.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonenquiryformComponent } from './components/commonenquiryform/commonenquiryform.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { CommonserviceformComponent } from './components/commonserviceform/commonserviceform.component';
import { CommonportfoliocomponentComponent } from './components/commonportfoliocomponent/commonportfoliocomponent.component';
import { CommonsendemailformComponent } from './components/commonsendemailform/commonsendemailform.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { LoginComponent } from './admin/pages/login/login.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb"
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableCellDirective, NzTableComponent, NzTableModule } from 'ng-zorro-antd/table';

import {
  MenuFoldOutline,
  MenuUnfoldOutline,
  EditOutline,
  OrderedListOutline,
} from '@ant-design/icons-angular/icons';
import { AdminLayout } from './admin/layout/layout.component';
import { BlogsListComponent } from './admin/pages/blogs/list/list-blogs.component';
import { CareersListComponent } from './admin/pages/careers/list/list-careers.component';
import { CaseStudiesListComponent } from './admin/pages/caseStudies/list/list-cases.component';
import { AddBlogComponent } from './admin/pages/blogs/add/add-blog.component';

const icons = [
  MenuFoldOutline,
  MenuUnfoldOutline,
  EditOutline,
  OrderedListOutline
];


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    routingComponents,
    CommonSliderComponent,
    CommonenquiryformComponent,
    CommonserviceformComponent,
    CommonportfoliocomponentComponent,
    CommonsendemailformComponent,
    LoginComponent,
    AdminLayout,
    BlogsListComponent,
    CareersListComponent,
    CaseStudiesListComponent,
    AddBlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    RecaptchaModule,
    RecaptchaFormsModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzIconModule.forChild(icons),
    NzMenuModule,
    MatSelectModule,
    MatFormFieldModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzButtonModule,
    NzTableModule
  ],
  exports: [FormsModule, ReactiveFormsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
