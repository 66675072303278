import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-computing',
  templateUrl: './Cloud-Computing.component.html',
  styleUrls: ['./Cloud-Computing.component.scss']
})
export class CloudComputingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
