import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    $(document).ready(function () {
      $('.filter-button').click(function () {
        var value = $(this).attr('data-filter');

        if (value == 'all') {
          //$('.filter').removeClass('hidden');
          $('.filter').show('1000');
        } else {
          $('.filter')
            .not('.' + value)
            .hide('3000');
          $('.filter')
            .filter('.' + value)
            .show('3000');
        }
      });

      if ($('.filter-button').removeClass('active')) {
        $(this).removeClass('active');
      }
      $(this).addClass('active');
    });
  }
}
