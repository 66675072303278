<!-- Header Area Start Here -->
<div id="preloader" class="preloader">
  <div class="animation-preloader">
    <div class="spinner"></div>
    <div class="txt-loading">
      <span data-text-preloader="G" class="letters-loading"> G </span>
      <span data-text-preloader="r" class="letters-loading"> r </span>
      <span data-text-preloader="o" class="letters-loading"> o </span>
      <span data-text-preloader="o" class="letters-loading"> o </span>
      <span data-text-preloader="t" class="letters-loading"> t </span>
    </div>
  </div>
  <div class="loader">
    <div class="row">
      <div class="col-3 loader-section section-left">
        <div class="bg"></div>
      </div>
      <div class="col-3 loader-section section-left">
        <div class="bg"></div>
      </div>
      <div class="col-3 loader-section section-right">
        <div class="bg"></div>
      </div>
      <div class="col-3 loader-section section-right">
        <div class="bg"></div>
      </div>
    </div>
  </div>
</div>
<div class="fix-area">
  <div class="offcanvas__info">
    <div class="offcanvas__wrapper">
      <div class="offcanvas__content">
        <div
          class="offcanvas__top mb-4 d-flex justify-content-between align-items-center"
        >
          <div class="offcanvas__logo">
            <a href="#">
              <img
                src="assets/img/logo/footer-logo-2.png"
                alt="logo-img"
                class="image-logo-2 image-logo-ft"
              />
            </a>
          </div>
          <div class="offcanvas__close">
            <button>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="mobile-menu fix mb-3"></div>
        <div class="offcanvas__contact">
          <h4>Contact Info</h4>
          <ul>
            <li class="d-flex align-items-center">
              <div class="offcanvas__contact-icon">
                <i class="fal fa-map-marker-alt"></i>
              </div>
              <div class="offcanvas__contact-text">
                <a target="_blank"
                  >Sushma Infinium, 3rd Floor, Zirakpur(Chandigarh), Punjab,
                  140603</a
                >
              </div>
            </li>
            <li class="d-flex align-items-center">
              <div class="offcanvas__contact-icon mr-15">
                <i class="fal fa-envelope"></i>
              </div>
              <div class="offcanvas__contact-text">
                <a href="mailto:info@grootsoftwares.com"
                  ><span class="info-info">info&#64;grootsoftwares.com</span></a
                >
              </div>
            </li>

            <li class="d-flex align-items-center">
              <div class="offcanvas__contact-icon mr-15">
                <i class="far fa-phone"></i>
              </div>
              <div class="offcanvas__contact-text">
                <a href="tel:+01762460300">0176-2460300</a>
              </div>
            </li>
          </ul>
          <div class="header-button mt-4">
            <a
              ng-reflect-router-link="about"
              href="/enquiry"
              class="theme-btn text-center"
            >
              <span>Contact Us<i class="fas fa-chevron-right"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="offcanvas__overlay"></div>

<header class="header-section-3 made-for-desktop" id="top-head">
  <div id="header-sticky" class="header-3">
    <div class="container-fluid">
      <div class="mega-menu-wrapper">
        <div class="header-main">
          <div class="logo">
            <a [routerLink]="['/']" class="header-logo">
              <img
                src="assets/img/logo/logo.png"
                alt="logo-img"
                class="image-logo"
              />
            </a>
          </div>
          <div class="header-left">
            <div class="navbar">
              <div class="subnav">
                <button class="subnavbtn" [routerLink]="['services']">
                  SERVICES <i class="fas fa-angle-down"></i>
                </button>
                <div class="subnav-content align-items-center">
                  <a
                    [routerLink]="['strategy-and-transformation']"
                    class="text-center overlay-container"
                    style="
                      background-image: url(../../../assets/img/header/home-1.jpg);
                      min-width: 200px;
                    "
                  >
                    <span>Strategy And Transformation</span>
                  </a>
                  <a
                    [routerLink]="['applications-and-technology']"
                    class="text-center overlay-container"
                    style="
                      background-image: url(../../../assets/img/header/home-2.jpg);
                      min-width: 200px;
                    "
                  >
                    <span>Applications And Technology</span>
                  </a>
                  <a
                    [routerLink]="['core-engineering']"
                    class="text-center overlay-container"
                    style="
                      background-image: url(../../../assets/img/header/home-3.jpg);
                      min-width: 290px;
                    "
                  >
                    <span>Core Engineering</span>
                  </a>
                  <a
                    [routerLink]="['business-&-digitalsolutions']"
                    class="text-center overlay-container"
                    style="
                      background-image: url(../../../assets/img/header/home-4.jpg);
                      min-width: 200px;
                    "
                  >
                    <span>Business And Digital Solutions</span>
                  </a>
                  <div class="all-ser text-center">
                    <p class="explore-or">Explore our services</p>
                    <button
                      [routerLink]="['services']"
                      class="button-nav"
                      type="submit"
                      style="padding: 15px"
                    >
                      <span>
                        View More
                        <i class="fas fa-chevron-right"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="subnav">
                <button class="subnavbtn" [routerLink]="['about']">
                  ABOUT US<i class="fas fa-angle-down"></i>
                </button>
                <div class="subnav subnav-about subnav-content">
                  <ul>
                    <li><a [routerLink]="['about']">Company Profile</a></li>
                    <li>
                      <a [routerLink]="['team']">Leadership & Advisors</a>
                    </li>
                    <li>
                      <a [routerLink]="['life-at-groot']">Life At Groot</a>
                    </li>
                    <li>
                      <a [routerLink]="['become-a-partner']"
                        >Become A Partner</a
                      >
                    </li>
                    <li>
                      <a [routerLink]="['awards-and-recognisation']"
                        >Awards & Recognition</a
                      >
                    </li>
                    <li><a [routerLink]="['career']">Career</a></li>
                  </ul>
                </div>
              </div>

              <div class="subnav">
                <button class="subnavbtn">
                  RESOURCES<i class="fas fa-angle-down"></i>
                </button>
                <div class="subnav subnav-resources subnav-content">
                  <ul>
                    <li><a [routerLink]="['news']">Blog</a></li>
                    <li><a [routerLink]="['case-study']">Case Study</a></li>
                  </ul>
                </div>
              </div>
              <a [routerLink]="['portfolio']">PORTFOLIO</a>
              <a [routerLink]="['engagement-model']">ENGAGEMENT MODELS</a>
            </div>
          </div>
          <div
            class="header-right d-flex justify-content-end align-items-center"
          >
            <div class="header-button">
              <a [routerLink]="['/enquiry']" class="theme-btn theme-btn-2">
                <span>
                  contact us
                  <i class="fas fa-chevron-right"></i>
                </span>
              </a>
            </div>
            <div class="header__hamburger d-xl-block my-auto">
              <div class="sidebar__toggle">
                <div class="header-bar">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<header class="header-section-3 made-for-mobile" id="top-head">
  <div id="header-sticky" class="header-3">
    <div class="container-fluid">
      <div class="mega-menu-wrapper">
        <div class="header-main">
          <div class="logo">
            <a [routerLink]="['/']" class="header-logo">
              <img
                src="assets/img/logo/logo.png"
                alt="logo-img"
                class="image-logo"
              />
            </a>
          </div>
          <div class="header-left">
            <div class="mean__menu-wrapper">
              <div class="main-menu">
                <nav id="mobile-menu">
                  <ul class="mobile-responsive-ise">
                    <li class="has-dropdown active d-lg-none">
                      <a [routerLink]="['services']" class="border-none">
                        Services
                        <i class="fas fa-angle-down"></i>
                      </a>
                      <ul class="submenu">
                        <li>
                          <a [routerLink]="['strategy-and-transformation']"
                            >Strategy and Transformation</a
                          >
                        </li>
                        <li>
                          <a [routerLink]="['applications-and-technology']"
                            >Applications & Technology</a
                          >
                        </li>
                        <li>
                          <a [routerLink]="['core-engineering']"
                            >Core Engineering</a
                          >
                        </li>
                        <li>
                          <a [routerLink]="['business-&-digitalsolutions']"
                            >Business & Digital Solutions</a
                          >
                        </li>
                      </ul>
                    </li>

                    <li class="has-dropdown">
                      <a [routerLink]="['about']" class="">
                        About Us
                        <i class="fas fa-angle-down"></i>
                      </a>
                      <ul class="submenu">
                        <li><a [routerLink]="['about']">Company Profile</a></li>
                        <li>
                          <a [routerLink]="['team']">Leadership & Advisors</a>
                        </li>
                        <li>
                          <a [routerLink]="['life-at-groot']">Life At Groot</a>
                        </li>

                        <li>
                          <a [routerLink]="['become-a-partner']"
                            >Become A Partner</a
                          >
                        </li>
                        <li>
                          <a
                            [routerLink]="['awards-and-recognisation']"
                            routerLinkActive="active"
                            >Awards & Recognition</a
                          >
                        </li>

                        <li><a [routerLink]="['career']">Careers</a></li>
                      </ul>
                    </li>
                    <li>
                      <a [routerLink]="['news']">
                        Resources
                        <i class="fas fa-angle-down"></i>
                      </a>
                      <ul class="submenu">
                        <li>
                          <a [routerLink]="['news']"> Blog </a>
                        </li>
                        <li>
                          <a [routerLink]="['case-study']">Case Studies</a>
                        </li>
                      </ul>
                    </li>

                    <li><a [routerLink]="['portfolio']">Portfolio</a></li>
                    <li>
                      <a [routerLink]="['engagement-model']"
                        >Engagement Model</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div
            class="header-right d-flex justify-content-end align-items-center"
          >
            <div class="header-button">
              <a [routerLink]="['enquiry']" class="theme-btn theme-btn-2">
                <span>
                  contact us
                  <i class="fas fa-chevron-right"></i>
                </span>
              </a>
            </div>
            <div class="header__hamburger d-xl-block my-auto">
              <div class="sidebar__toggle">
                <div class="header-bar">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<a class="scroll-top" href="#">
  <div class="arrow up"></div>
</a>
