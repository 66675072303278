import { Component } from '@angular/core';

@Component({
  selector: 'mohit-sharma',
  templateUrl: './mohit-sharma.component.html',
  styleUrls: ['./mohit-sharma.component.scss']
})

export class MohitSharmaComponent {
  constructor() { }
}