<div
  class="bg-cover"
  style="
    background-image: url('assets/img/breadcrumb-3.jpg');
    padding-top: 132px;
  "
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp service-sec-ban" data-wow-delay=".3s">
        our services
      </h1>
    </div>
    <!-- <div class="row align-items-end custom-service-bg">
      <div class="col-md-3 ps-0">
        <div class="all-service-sec">
          <div class="anchor-category">
            <p class="heads-ser">Strategy and Transformation</p>
            <a class="routerlinks" [routerLink]="['/consulting-and-strategy']"
              >Consulting & Strategy</a
            >
            <a class="routerlinks" [routerLink]="['/cloud-computing']"
              >Cloud Computing</a
            >
            <a
              class="routerlinks"
              [routerLink]="['/blockchain-software-development']"
              >Blockchain Software Development</a
            >
            <a class="routerlinks" [routerLink]="['/internet-of-things']"
              >Internet of Things (IoT)</a
            >
            <a
              class="routerlinks"
              [routerLink]="['/artificial-intelligence_machine-learning']"
              >Artificial Intelligence</a
            >
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="all-service-sec">
          <div class="anchor-category">
            <p class="heads-ser">Business & Digital Solutions</p>
            <a class="routerlinks" [routerLink]="['/erp-crm']">ERP & CRM</a>
            <a class="routerlinks" [routerLink]="['/digital-acceleration']"
              >Digital Acceleration</a
            >
            <a class="routerlinks" [routerLink]="['/business-intelligence']"
              >Business Intelligence</a
            >
            <a class="routerlinks" [routerLink]="['/process-automation']"
              >Process Automation</a
            >
            <a
              class="routerlinks"
              [routerLink]="['/digital-wallets-cryptocurrency']"
              >Digital Wallets & Cryptocurrency</a
            >
            <a class="routerlinks" [routerLink]="['/data-science']"
              >Data Science</a
            >
            <a class="routerlinks" [routerLink]="['/database-development']"
              >Database Development</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="all-service-sec">
          <div class="anchor-category">
            <p class="heads-ser">Core Engineering</p>

            <a
              class="routerlinks"
              [routerLink]="['/custom-software-development']"
              >Custom Software Development</a
            >
            <a class="routerlinks" [routerLink]="['/front-end-development']"
              >Front-End Development</a
            >
            <a class="routerlinks" [routerLink]="['/back-end-development']"
              >Back-End Development</a
            >
            <a class="routerlinks" [routerLink]="['/ios-app-devlopment']"
              >IOS App Development</a
            >
            <a class="routerlinks" [routerLink]="['/android-app-development']"
              >Android App Development</a
            >
            <a class="routerlinks" [routerLink]="['/ui-ux-design']"
              >UI/UX Design</a
            >

            <a class="routerlinks" [routerLink]="['/mvp-development']"
              >MVP Development</a
            >
            <a
              class="routerlinks"
              [routerLink]="['/progressive-web-application']"
              >Progressive Web Applications</a
            >
            <a class="routerlinks" [routerLink]="['/e-commerce-development']"
              >E-commerce Development</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="all-service-sec">
          <div class="anchor-category">
            <p class="heads-ser">Applications and Technology</p>
            <a class="routerlinks" [routerLink]="['/software-testing_QA']"
              >Software Testing & QA</a
            >
            <a class="routerlinks" [routerLink]="['/maintenance_support']"
              >Maintenance & Support</a
            >
            <a
              class="routerlinks"
              [routerLink]="['/modernization_and_migration']"
              >Modernization and Migration</a
            >
            <a
              class="routerlinks"
              [routerLink]="['/web-application-development']"
              >Web Application Development</a
            >
          </div>
        </div>
      </div>
    </div> -->
    
    <!-- dynamic -->
    <div class="row align-items-end custom-service-bg">
      <div class="col-md-3 ps-0" *ngFor="let category of serviceCategories">
        <div class="all-service-sec">
          <div class="anchor-category">
            <p class="heads-ser">{{ category.title }}</p>
            <a class="routerlinks" *ngFor="let link of category.links" [routerLink]="[link.route]">{{ link.text }}</a>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>

<!--<< Service Section Start >>-->
<section class="service-section fix section-padding sectio-border">
  <div class="container-fluid">
    <div class="section-title text-center">
      <span class="wow fadeInUp"
        >Empowering Startups. Equipped for Enterprises.</span
      >
      <h2 class="wow fadeInUp padding-heading" data-wow-delay=".3s">
        Using strategy, creativity and technology, we help our clients with
        their customer retention.
      </h2>
    </div>
    <!-- <div class="row">
      <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
        <div class="service-box-items">
          <div class="icon">
            <i class="flaticon-coding"></i>
          </div>
          <div class="content">
            <h3>
              <a [routerLink]="['/strategy-and-transformation']">
                Strategy and Transformation
              </a>
            </h3>
            <p>
              Driving industrial transformation through top-tier consulting,
              cloud computing, blockchain, IoT, and AI/ML expertise for
              inclusive growth.
            </p>
            <a
              [routerLink]="['/strategy-and-transformation']"
              class="arrow-icon"
            >
              <i class="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
        <div class="service-box-items">
          <div class="icon">
            <i class="flaticon-server"></i>
          </div>
          <div class="content">
            <h3>
              <a [routerLink]="['/applications-and-technology']">
                Applications and Technology
              </a>
            </h3>
            <p>
              Ensuring inclusive software quality, support, modernization, and
              cybersecurity to empower businesses with cutting-edge technology
              solutions.
            </p>
            <a
              [routerLink]="['/applications-and-technology']"
              class="arrow-icon"
            >
              <i class="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
        <div class="service-box-items">
          <div class="icon">
            <i class="flaticon-data-science"></i>
          </div>
          <div class="content">
            <h3>
              <a [routerLink]="['/core-engineering']"> Core Engineering </a>
            </h3>
            <p>
              Building inclusive digital experiences with custom software,
              front-end and back-end development, mobile apps, UI/UX design, and
              advanced web solutions.
            </p>
            <a [routerLink]="['/core-engineering']" class="arrow-icon">
              <i class="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".9s">
        <div class="service-box-items">
          <div class="icon">
            <i class="flaticon-service"></i>
          </div>
          <div class="content">
            <h3>
              <a [routerLink]="['/business-&-digitalsolutions']">
                Business & Digital Solutions
              </a>
            </h3>
            <p>
              Facilitating inclusive business efficiency, acceleration,
              automation, intelligence, digital payments, data insights, and
              robust database solutions for sustainable growth.
            </p>
            <a
              [routerLink]="['/business-&-digitalsolutions']"
              class="arrow-icon"
            >
              <i class="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" *ngFor="let box of serviceBoxes; let i=index" [attr.data-wow-delay]=".3 + i * 0.2 + 's'">
        <div class="service-box-items">
          <div class="icon">
            <i class="{{ box.iconClass }}"></i>
          </div>
          <div class="content">
            <h3><a [routerLink]="[box.routerLink]">{{ box.title }}</a></h3>
            <p>{{ box.description }}</p>
            <a [routerLink]="[box.routerLink]" class="arrow-icon"><i class="far fa-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</section>

<!--<< Cta Banner Section Start >>-->
<section
  class="cta-banner-section bg-cover section-padding"
  style="background-image: url('assets/img/banner/01.jpg')"
>
  <div class="container">
    <div class="cta-banner-wrapper section-padding pt-0">
      <div class="section-title mb-0">
        <span class="wow fadeInUp"
          >Trusted by Start-ups & Fortune 500 Clients.</span
        >
        <h2 class="text-white wow fadeInUp" data-wow-delay=".3s">
          Seeking Business Guidance? <br />
          Please Call :
          <a href="tel:++01762460300" class="theme-color-1">(0176)2460300</a>
        </h2>
      </div>
      <a
        routerLink="/enquiry"
        class="theme-btn hover-white wow fadeInUp"
        data-wow-delay=".5s"
      >
        <span>
          Get a Free Quote
          <i class="fas fa-chevron-right"></i>
        </span>
      </a>
    </div>
  </div>
</section>

<!--<< Feature Icon Box Section Start >>-->
<section class="feature-icon-box-area-2">
  <div class="custom-width">
    <div class="feature-icon-box-wrapper pt-5">
      <span
        _ngcontent-kvp-c49=""
        class="wow fadeInUp span-tag"
        style="visibility: visible; animation-name: fadeInUp"
        >Why Choose Groot Software Solutions?</span
      >
      <h3 class="mb-5">When you partner with us, you're guaranteed:</h3>
      <div class="row g-4">
        <div
          class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay=".3s"
        >
          <div class="icon-box-items">
            <div class="icon">
              <i class="flaticon-microchip"></i>
            </div>
            <div class="content">
              <h3>Unmatched Technical Expertise</h3>
              <p class="unmatched">
                Tailor-made solutions specifically designed for you.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay=".5s"
        >
          <div class="icon-box-items active">
            <div class="icon">
              <i class="flaticon-cloud-storage"></i>
            </div>
            <div class="content">
              <h3>Transparent Communication</h3>
              <p class="unmatched">
                Streamlined workflows and clear communication channels ensure a
                smooth journey.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay=".7s"
        >
          <div class="icon-box-items">
            <div class="icon">
              <i class="flaticon-gear"></i>
            </div>
            <div class="content">
              <h3>Post-Development Support</h3>
              <p class="unmatched">
                We're here for you even after the project's completion, ensuring
                lasting success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Contact Section Start >>-->
<section
  class="contact-section-2 fix section-padding bg-cover"
  style="background-image: url('assets/img/contact-bg.jpg')"
>
  <div class="contact-image">
    <img src="assets/img/contact-2.png" alt="contact-img" />
  </div>
  <div class="container">
    <div class="contact-wrapper-2">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="contact-content">
            <div class="section-title style-2">
              <span class="wow fadeInUp">Get in Touch</span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                Schedule A Free Consultation
              </h2>
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              Top American businesses accelerate their tech roadmaps with us.
              <br />See how we can help you.
            </p>
            <div class="contact-info-area">
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div class="icon">
                  <i class="far fa-phone"></i>
                </div>
                <div class="content">
                  <p>Call For Inquiry</p>
                  <h3><a href="tel:+01762460300">0176-2460300</a></h3>
                </div>
              </div>
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".8s"
              >
                <div class="icon">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="content">
                  <p>Send Us Email</p>
                  <h3>
                    <a href="mailto:info@grootsoftwares.com"
                      >info&#64;grootsoftwares.com</a
                    >
                  </h3>
                </div>
              </div>
              <div class="contact-info-items wow fadeInUp" data-wow-delay=".9s">
                <div class="icon">
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div class="content">
                  <p>Location</p>
                  <h3>
                    Sushma Infinium, 3rd Floor, Zirakpur(Chandigarh), Punjab,
                    140603
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="contact-box">
            <div class="contact-title">
              <h3 class="wow fadeInUp" data-wow-delay=".3s">
                Need Help For Project!
              </h3>
              <p class="wow fadeInUp" data-wow-delay=".5s">
                We are ready to help your next projects, let’s work together
              </p>
            </div>
            <div class="contact-form-items">
              <app-commonserviceform></app-commonserviceform>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
