export const notificationTypes = {
  success: "success",
  error: "error",
};

export const commonErrorMessage = {
  somethingWentWrong: "Something Went Wrong..",
};

export const responseCodes = {
  success: 200,
  error: 400,
};
