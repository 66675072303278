import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'android-app-development',
  templateUrl: './android-app-development.component.html',
  styleUrls: ['./android-app-development.component.scss']
})
export class AndroidAppDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
