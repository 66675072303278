<nz-layout>
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
    <div class="logo"></div>
    <ul nz-menu nzTheme="dark" nzMode="inline">
      <li nz-submenu nzTitle="Blogs" nzIcon="edit">
        <ul>
          <li nz-menu-item><a routerLink="/admin/add-blog">Add Blog</a></li>
          <li nz-menu-item><a routerLink="/admin/blogs">View Blogs</a></li>
        </ul>
      </li>
      <li nz-submenu nzTitle="Case Studies" nzIcon="ordered-list">
        <ul>
          <li nz-menu-item><a routerLink="/admin/add-case-study">Add Case Study</a></li>
          <li nz-menu-item><a routerLink="/admin/case-studies">View Case Studies</a></li>
        </ul>
      </li>
      <li nz-submenu nzTitle="Careers" nzIcon="team">
        <ul>
          <li nz-menu-item><a routerLink="/admin/add-career">Add New Opening</a></li>
          <li nz-menu-item><a routerLink="/admin/careers">View Career</a></li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <span
        class="trigger"
        nz-icon
        [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
        (click)="isCollapsed = !isCollapsed"
      ></span>
    </nz-header>
    <nz-content>
      <nz-breadcrumb>
        <nz-breadcrumb-item>Admin</nz-breadcrumb-item>
        <nz-breadcrumb-item>{{ currentUrl }}</nz-breadcrumb-item>
      </nz-breadcrumb>
      <div class="inner-content"> <ng-content></ng-content> </div>
    </nz-content>
    <nz-footer>Ant Design ©2020 Implement By Angular</nz-footer>
  </nz-layout>
</nz-layout>
