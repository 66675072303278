import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import $ from 'jquery';
// import {}from 'src/utils/constants/application_url_constants/';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUrl = '';

  isServiceActive = false;

  applicationUrls = [
    '/webPortalDevelopment',
    '/ResponsiveHTML',
    '/WebApplicationPaymentGateways',
    '/CustomWebDevelopment',
    '/APIDesignAndDevelopment',
    '/CMSWebDevelopment',
    '/SoftwareDevelopment',
    '/ProductManagementAndStrategy',
    '/DevOps',
    '/MicrosoftOffices',
    '/SHAREPOINT',
    '/MobileApp',
  ];

  constructor(private router: Router) {
    this.loadScript('assets/js/header.js').then(() => {});

    const updateUrls = this.applicationUrls.map((url) => {
      return url.toLowerCase();
    });
    router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.currentUrl = route.url;
        if (this.currentUrl && this.currentUrl.length > 0) {
          // this.currentUrl = this.currentUrl.slice(1).toLowerCase();
          this.currentUrl = this.currentUrl.toLowerCase();

          if (updateUrls.includes(this.currentUrl.toLowerCase())) {
            this.isServiceActive = true;
          } else {
            this.isServiceActive = false;
          }
        }
      }
    });
  }

  ngOnInit(): void {}

  dropDownEvent(event) {
    switch (event.type) {
      case 'mouseover':
        $(event.currentTarget).addClass('dropdown');
        break;
      case 'mouseout':
        $(event.currentTarget).removeClass('dropdown');
        break;
    }
  }

  serviceLinkEvent(event) {
    $('#jqServices').removeClass('dropdown');
  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }
}
