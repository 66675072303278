<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/bg-case-study-1.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">
        Boosting Financial Data Efficiency
      </h1>
    </div>
  </div>
</div>

<!--<< About Section Start >>-->

<section class="project-details fix section-padding pb-0 pt-5">
  <div class="container">
    <div class="project-details-wrapper">
      <div class="row g-4 justify-content-between align-items-center">
        <div class="project-title-content text-center">
          <span class="text text-center mt-4">Financial Services </span>
          <h2 style="text-align: center">
            Empowering Global Financial Success<br />
            with Custom Solutions
          </h2>
          <p style="text-align: center; font-size: 21px">
            At Groot Software Solutions, we thrive on partnering with
            forward-thinking organizations to drive innovation and efficiency.
            Here’s how we empowered a Plainsboro-based global leader in the
            financial services industry to enhance their operational
            capabilities.
          </p>
        </div>
        <div
          class="container"
          style="
            background-color: ghostwhite;
            border-radius: 20px;
            padding: 30px;
            margin-top: 20px;
          "
        >
          <div class="row">
            <div class="col-12">
              <h3 style="text-align: center; padding: 20px; font-size: 38px">
                Technologies Used
              </h3>
              <div class="row text-center">
                <div class="col-md-3 technology-details">PHP</div>
                <div class="col-md-3 technology-details">jQuery</div>
                <div class="col-md-3 technology-details">MySQL</div>
                <div class="col-md-3 technology-details">PHPUnit</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="row text-center">
                <div class="col-md-4 technology-details">JavaScript</div>
                <div class="col-md-4 technology-details">HTML/CSS</div>
                <div class="col-md-4 technology-details">AWS</div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>

      <div class="project-details-content mt-5">
        <div class="title-content text-center">
          <h2>Client Profile</h2>
          <h3 style="font-weight: 600px; padding: 15px">
            The Innovators in Financial Excellence
          </h3>
        </div>
        <div class="details-content text-center" style="font-size: 20px">
          <p>
            Our client, headquartered in Plainsboro, USA, stands out as a global
            leader with over 3,600 team members worldwide. Their dedication to
            efficiency and innovation in serving various sectors within the
            financial services industry sets them apart.
          </p>
        </div>
      </div>

      <div class="project-details-content mt-5">
        <div
          class="row m-0"
          style="background-color: black; border-radius: 8px; color: white"
        >
          <div class="col-lg-6 mt-5">
            <div class="title-content p-2">
              <h2 style="color: white; text-align: center">Needs & Goals</h2>
            </div>
            <div
              class="details-content p-3"
              style="font-weight: 500; text-align: center"
            >
              <p>
                Seeking to elevate their market presence<br />
                and operational efficiency, our client <br />turned to Groot
                Software Solutions <br />for top-tier engineering support.
              </p>
            </div>
          </div>
          <div class="col-lg-6 text-center">
            <div>
              <img
                class="img-fluid d-ffer"
                src="../../../assets/img/project/goals&needs.png"
                alt=""
                style="width: 490px; height: 330px; border: 4px solid black"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="project-details-content overlay-container mt-5"
        style="
          padding: 8%;
          border-radius: 8px;
          background-image: url(../../../assets/img/project/Real-impact.jpg);
          background-size: cover;
        "
      >
        <div class="overlay"></div>
        <div class="title-content text-center">
          <h2 style="color: white">Tailored Solutions for Real Impact</h2>
        </div>
        <div
          class="details-content text-center"
          style="font-size: 18px; padding: 20px; color: white"
        >
          <p>
            Together, we embarked on a journey to develop a dynamic system that
            swiftly extracts data from JSON databases, integrating it seamlessly
            into branded PDF documents. Additionally, we introduced a new
            module, Products Submittal, into their web application to streamline
            product catalog management.
          </p>
        </div>
      </div>
      <div class="project-details-content mt-5">
        <div class="title-content p-3 text-center">
          <h2>Measuring Success: Key Achievements</h2>
        </div>
        <div class="details-content">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-md-6 text-center mt-4">
                  <img
                    src="../../../assets/img/project/key.jpg"
                    alt=""
                    style="height: 260px; width: 260px; border-radius: 50%"
                  />
                </div>
                <div class="col-md-6">
                  <div class="list-items mt-5">
                    <ul class="style-res" style="margin-right: 30%">
                      <li class="list-indent">
                        Faster Data Extraction:<span class="list-bold">
                          Reduced extraction time by 40%, enhancing operational
                          speed</span
                        >
                      </li>
                      <li class="list-indent">
                        Quick Document Assembly:<span class="list-bold">
                          Cut assembly time by 50%, facilitating quicker
                          decision-making</span
                        >
                      </li>
                      <li class="list-indent">
                        Seamless Module Integration:<span class="list-bold">
                          Successfully integrated Products Submittal module,
                          improving catalog management efficiency by 30%.</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="col-md-10">
          <div class="blog-wrapper mt-4 mb-4">
            <div
              _ngcontent-doi-c66=""
              class="single-blog-post quote-post format-quote"
            >
              <div
                _ngcontent-doi-c66=""
                class="post-content text-white bg-cover"
              >
                <div _ngcontent-doi-c66="" class="quote-content">
                  <div _ngcontent-doi-c66="" class="icon">
                    <i _ngcontent-doi-c66="" class="fas fa-quote-left"></i>
                  </div>
                  <div _ngcontent-doi-c66="" class="quote-text">
                    <h3 _ngcontent-doi-c66="">
                      "Our experience with Groot Software Solutions has been
                      exceptional. They navigated our project needs with ease
                      and provided valuable insights. Their commitment to
                      excellence is truly commendable."
                      <span> --Director of Technology</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="project-details-image mt-5">
        <div class="row">
          <div class="col-lg-6">
            <img
              src="../../../assets/img/project/g-2.png"
              alt="img"
              style="border-radius: 8px; border: solid 2px bl"
            />
          </div>
          <div class="col-lg-6">
            <img
              src="../../../assets/img/project/g-1.jpg"
              alt="img"
              style="border-radius: 8px"
            />
          </div>
        </div>
      </div>
      <div class="project-details-content mt-5">
        <div class="row">
          <div class="col-lg-6">
            <div class="title-content">
              <h2>Impact on Client's Business Growth</h2>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="details-content" style="font-size: 20px">
              <p>
                By leveraging our solutions, the client witnessed significant
                improvements in operational efficiency, enabling them to serve
                their clients better and expand their market presence.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="project-details-content mt-5">
        <div class="row">
          <div class="col-lg-7">
            <div class="details-content">
              <p>
                <strong> conclusion:</strong> Through collaboration with Groot
                Software Solutions, our client achieved remarkable enhancements
                in financial data efficiency, positioning themselves as industry
                leaders poised for further growth and success.
              </p>
            </div>
          </div>
          <div class="col-lg-1"></div>

          <div class="col-lg-4">
            <div class="title-content">
              <a [routerLink]="['/enquiry']" class="theme-btn"
                ><span>
                  Schedule a Consultation Today
                  <i class="fas fa-chevron-right"></i></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
