<!--<< About  Naveen Tiwari Section Start >>-->

<section class="team-details-section fix pt-5">
  <div class="container">
    <div
      class="about-Naveen"
      style="
        padding: 30px;
        background-image: url(../../../../assets/img/team/img-ow.jpg);
      "
    >
      <div class="row justify-content-between align-items-center">
        <div
          class="col-lg-6 wow fadeInUp"
          data-wow-delay=".3s"
          style="
            visibility: visible;
            animation-delay: 0.3s;
            animation-name: fadeInUp;
          "
        >
          <div class="circular--portrait">
            <img class="img-fluid" src="assets/img/team/03.jpg" alt=" pic" />
          </div>
        </div>
        <div
          class="col-lg-6 mt-5 mt-lg-0 wow fadeInUp"
          data-wow-delay=".5s"
          style="
            visibility: visible;
            animation-delay: 0.5s;
            animation-name: fadeInUp;
          "
        >
          <div class="team-details-content">
            <h2>Naveen Tiwari</h2>
            <br />

            <h3>Meet Naveen Tiwari</h3>
            <br />

            <p>
              Naveen Tiwari, a seasoned Full Stack Developer with over 12 years
              of experience, leads Groot Software Solutions. His innovative
              approach ensures projects are not just completed, but they exceed
              expectations, driving success for your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--<< Service Details Section Start >>-->
<section class="service-details fix section-padding pt-5">
  <div class="container">
    <div class="service-details-wrapper">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="seperation mt-5 mb-4"></div>
          <div class="service mt-5">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 mt-0">
                <div class="service-detail">
                  <h4>Services</h4>

                  <ul
                    class="detail"
                    style="list-style-type: circle; padding: 10px"
                  >
                    <li>
                      <p style="color: black">
                        <b>Custom Software Development: </b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Tailored solutions designed to fit your business needs
                      perfectly, leveraging the latest technologies and industry
                      best practices.
                    </p>
                    <li>
                      <p style="color: black">
                        <b>Web and Mobile App Development: </b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Engaging digital experiences crafted with precision,
                      focusing on user-centric design and seamless
                      functionality..
                    </p>
                    <li>
                      <p style="color: black"><b>Enterprise Solutions:</b></p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Scalable solutions for organizational integration and
                      efficiency, empowering your business to thrive in the
                      digital age.
                    </p>
                    <li>
                      <p style="color: black">
                        <b>Data Management and Business Intelligence:</b>
                      </p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Transforming data into actionable insights, enabling
                      data-driven decision-making and strategic planning.
                    </p>
                    <li>
                      <p style="color: black"><b>E-commerce Solutions:</b></p>
                    </li>
                    <p class="detail-p" style="font-size: 15px; padding: 2px">
                      -> Secure and efficient online platforms to boost sales
                      and enhance customer satisfaction, tailored to your unique
                      requirements.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="service-details-items">
            <!-- <div class="details-image">
                            <img src="assets/img/service/details-1.jpg" alt="img">
                        </div> -->
            <div class="details-content">
              <h3 class="mt-3">Why Choose Us?</h3>
              <ul>
                <li class="text-li">
                  <h6>Expertise:</h6>
                  A team of seasoned professionals dedicated to delivering
                  innovative solutions tailored to your business needs.
                </li>
                <li class="text-li">
                  <h6>Customization:</h6>
                  Solutions crafted to fit your unique requirements, ensuring
                  maximum impact and value.
                </li>
                <li class="text-li">
                  <h6>Quality Assurance:</h6>
                  Rigorous testing and review processes to guarantee
                  high-quality deliverables that exceed expectations.
                </li>
              </ul>
            </div>

            <div class="seperation mt-5 mb-4"></div>
            <h3>Our Expertise in Action</h3>
            <br />
            <p>
              At Groot Software Solutions, we pride ourselves on delivering
              innovative solutions tailored to your unique business needs. Here
              are some examples of how our directors' expertise translates into
              real-world use cases:
            </p>
            <div class="row pt-5">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/naveen_use_case1.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Real-Time Bidding Platform
                        </div>
                        <p class="image__description">
                          Create a real-time bidding platform for online
                          auctions, allowing users to place bids and monitor
                          auctions in real-time.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/naveen_use_case2.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Mental Health Awareness Platform
                        </div>
                        <p class="image__description">
                          Develop an online platform for mental health
                          awareness, featuring blogs, forums, and resources for
                          individuals seeking support and information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/naveen_use_case3.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Inventory Pricing Optimization
                        </div>
                        <p class="image__description">
                          Design a dynamic pricing system for inventory
                          management, adjusting prices based on demand,
                          seasonality, and competitor pricing.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/naveen_use_case4.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Real-Time Collaboration Tool
                        </div>
                        <p class="image__description">
                          Build a real-time collaboration tool for remote teams,
                          facilitating video conferencing, screen sharing, and
                          document collaboration.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 pe-0">
                    <div class="image">
                      <img
                        class="image__img"
                        src="../../../../assets/img/use-cases/naveen_use_case5.jpg"
                        alt="Bricks"
                      />

                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">Online Marketplace</div>
                        <p class="image__description">
                          Create an online marketplace for buying and selling
                          goods, with features for user profiles, product
                          listings, and secure transactions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 ps-0">
                    <div class="image">
                      <img
                        class="image__img img-fluid"
                        src="../../../../assets/img/use-cases/naveen_use_case6.jpg"
                        alt="Bricks"
                      />
                      <div class="image__overlay image__overlay--primary">
                        <div class="image__title">
                          Social Networking Platform
                        </div>
                        <p class="image__description">
                          Develop a social networking platform with user
                          profiles, news feeds, messaging, and content sharing
                          functionalities for connecting users worldwide.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 30px">
              <h3>Looking for Something Else?</h3>
              <br />
              <p>
                If you have a project idea in mind or need assistance with a
                specific requirement, we're here to help. Get in touch with us
                today to discuss your project needs and explore how Groot
                Software Solutions can bring your vision to life.
              </p>
              <br />
              <div class="col-lg-12">
                <button
                  [routerLink]="['/enquiry']"
                  class="theme-btn"
                  type="submit"
                >
                  <span>
                    Get in Touch
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="main-sidebar">
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">Engagement Models</h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample1"
                    >
                      Fixed Price
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample1">
                    <div class="card card-body">
                      Clear project scopes with transparent pricing, ensuring
                      you know what to expect every step of the way.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample2"
                    >
                      Time and Material
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample2">
                    <div class="card card-body">
                      Flexibility to adapt to evolving project requirements,
                      allowing for adjustments based on feedback and changing
                      business needs.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn3"
                      data-bs-toggle="collapse"
                      href="#collapseExample3"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample3"
                    >
                      Dedicated Team
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample3">
                    <div class="card card-body">
                      Long-term support and collaboration with a dedicated team
                      of experts, committed to your project's success.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">Featured Projects</h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample4"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample4"
                    >
                      Real-Time Trading System
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample4">
                    <div class="card card-body">
                      Empower your business with a real-time trading system,
                      enabling quick decision-making and maximizing
                      profitability.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample5"
                    >
                      Online Blog Site for Mental Illness
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample5">
                    <div class="card card-body">
                      Provide a platform for mental health awareness and
                      support, fostering a supportive online community.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3 style="text-align: center">
                  Discover More with Groot Software Solutions
                </h3>
              </div>
              <div class="service-category">
                <div>
                  <p>
                    <a
                      class="btn1"
                      data-bs-toggle="collapse"
                      href="#collapseExample6"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample6"
                    >
                      Technology Stack
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample6">
                    <div class="card card-body">
                      Our team specializes in a wide range of technologies,
                      including React JS, AngularJS, Node.js, ASP.NET Core, SQL
                      Server, and more.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample7"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample7"
                    >
                      Industry Experience
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample7">
                    <div class="card card-body">
                      We have successfully delivered projects across various
                      industries, including finance, healthcare, e-commerce, and
                      more.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample8"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample8"
                    >
                      Client Testimonials
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample8">
                    <div class="card card-body">
                      Hear from our satisfied clients about their experiences
                      working with Groot Software Solutions.
                    </div>
                  </div>
                </div>
                <div style="margin-top: 15px">
                  <p>
                    <a
                      class="btn2"
                      data-bs-toggle="collapse"
                      href="#collapseExample9"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample9"
                    >
                      Partnerships
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </p>
                  <div class="collapse" id="collapseExample9">
                    <div class="card card-body">
                      We partner with leading technology providers to ensure our
                      solutions are always cutting-edge and innovative.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="single-sidebar-widget">
              <form
                action="#"
                id="contact-form"
                class="message-form"
                style="
                  background-color: #00c0da;
                  padding: 20px;
                  border-radius: 30px;
                  padding-bottom: 50px;
                "
              >
                <div class="row g-3">
                  <div class="wid-title">
                    <h3 style="color: white; text-align: center; padding: 8px">
                      Contact us
                    </h3>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <a href="mailto:info@grootsoftwares.com">
                        <li
                          style="
                            background-color: white;
                            padding: 10px;
                            list-style: none;
                            border-radius: 3px;
                          "
                        >
                          <i class="fas fa-envelope"></i>
                          info&#64;grootsoftwares.com
                        </li>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <a href="tel:+0176-2460300">
                        <li
                          style="
                            background-color: white;
                            padding: 10px;
                            list-style: none;
                            border-radius: 3px;
                          "
                        >
                          <i class="fas fa-mobile-alt"></i> 0176-2460300
                        </li>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="single-form-input">
                      <li
                        style="
                          background-color: white;
                          padding: 10px;
                          list-style: none;
                          border-radius: 3px;
                        "
                      >
                        <i class="fas fa-map-marker-alt"></i> United States │
                        India
                      </li>
                    </div>
                  </div>
                </div>
              </form>

              <app-commonportfoliocomponent></app-commonportfoliocomponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
