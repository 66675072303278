import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiServices } from '../../api-services';
import { email } from 'src/app/models/email';
import { notificationTypes, commonErrorMessage } from 'src/utils/constants';
import { ValidateEmail } from 'src/app/validation';
import { ShowNotificationsService } from '../services/show-notifications.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnInit {
	environment = environment;

	// Flag
	submitted = false;
	disabled = false;
	// Initialize Form
	enquiryForm: FormGroup;
	formTitle = "Contact";
	currentYear = new Date().getFullYear();

	//Notification

	constructor(
		private formbulider: UntypedFormBuilder,
		private apiService: ApiServices,
		private showNotificationService: ShowNotificationsService,
		private router: Router		// private toastr: ToastrService
	) {
		this.createForm();
		this.currentYear = new Date().getFullYear(); //for year dynamic use this method
	}

	ngOnInit(): void {
		this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
				//   console.log('Navigation started:', event);
				  // this.enquiryForm.reset();
				  this.enquiryForm.controls['userEmail'].setValue('');
				//   this.enquiryForm.controls['userEmail'].setErrors(null);
				this.submitted = false;
			}
		});
	}

	get f() {
		return this.enquiryForm.controls;
	}

	resolved(captchaResponse: string) {
		// console.log(`Resolved captcha with response: ${captchaResponse}`);
	  }
	  

	//form data submission
	onSubmit() {

		this.submitted = true;
		// Stop here if form is invalid
		if (this.enquiryForm.invalid) {

			return;
		}

		const emailData: email = {
			firstName: '',
			lastName: '',
			userEmail: this.f.userEmail.value,
			companyName: '',
			phoneNumber: '',
			message: '',
			services: ''
		}

		this.sendEmail(emailData);
	}

	sendEmail(emailData: email) {
		this.disabled = true;
		this.apiService.sendEmail(emailData, this.formTitle).subscribe(
			(data: any) => {
				const { responseCode, responseMessage } = data;
				if (responseCode === 200) {
					this.showNotificationService.showNotification(notificationTypes.success, responseMessage);
					this.enquiryForm.reset();
					this.submitted = false;
				} else {
					this.showNotificationService.showNotification(notificationTypes.error, commonErrorMessage.somethingWentWrong);
				}
				this.disabled = false;
			},
			(error) => {
				this.disabled = false;
				this.showNotificationService.showNotification(notificationTypes.error, commonErrorMessage.somethingWentWrong);
			}
		);
	}

	createForm() {
		this.enquiryForm = this.formbulider.group({
			userEmail: ['', [Validators.required, Validators.email, ValidateEmail]],
			recaptcha: ['', [Validators.required]],

		});
	}
}
