<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/bg-case-study-2.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">
        Unlocking Digital Creativity
      </h1>
    </div>
  </div>
</div>

<!--<< About Section Start >>-->

<section class="project-details fix section-padding pb-0 pt-5">
  <div class="container">
    <div class="project-details-wrapper">
      <div class="row g-4 justify-content-between align-items-center">
        <div class="project-title-content text-center">
          <span class="text text-center mt-4"
            >Digital Media & Entertainment</span
          >
          <h2 style="text-align: center">
            Navigating the World of Cinematic Magic
          </h2>
          <p style="text-align: center; font-size: 21px">
            In the ever-evolving landscape of digital media and entertainment,
            Groot Software Solutions partners with global innovators to push the
            boundaries of creativity. Here's how we collaborated with a leading
            digital media solutions company to redefine the standards of
            cinematic excellence.
          </p>
        </div>
        <div
          class="container"
          style="
            background-color: ghostwhite;
            border-radius: 20px;
            padding: 30px;
            margin-top: 20px;
          "
        >
          <div class="row">
            <div class="col-12">
              <h3 style="text-align: center; padding: 20px; font-size: 38px">
                Technologies Used
              </h3>
              <div class="row text-center">
                <div class="col-md-3 technology-details">C++</div>
                <div class="col-md-3 technology-details">C#</div>
                <div class="col-md-3 technology-details">Python</div>
                <div class="col-md-3 technology-details">JavaScript</div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-12">
              <div class="row text-center">
                <div class="col-md-4 technology-details">HTML/CSS</div>
                <div class="col-md-4 technology-details">AWS</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="project-details-content" style="padding: 40px">
        <div class="title-content text-center">
          <h2>Client's Vision for Innovation</h2>
          <h3 style="font-weight: 600px; padding: 15px">
            The Innovators in Financial Excellence
          </h3>
        </div>
        <div class="details-content text-center" style="font-size: 20px">
          <p>
            Based in Plainsboro, USA, our client aimed to elevate their flagship
            3-D modeling software to new heights, staying ahead of the curve in
            meeting the demands of filmmakers and game designers worldwide.
          </p>
        </div>
      </div>

      <div
        class="project-details-content overlay-container mt-5"
        style="
          padding: 8%;
          border-radius: 8px;
          background-image: url(../../../assets/img/project/innovation.jpg);
          background-size: cover;
        "
      >
        <div class="overlay"></div>
        <div class="title-content text-center">
          <h2 style="color: white">
            Empowering Innovation with Groot Software Solutions
          </h2>
        </div>
        <div
          class="details-content text-center"
          style="font-size: 18px; padding: 20px; color: white"
        >
          <p>
            Understanding the challenge, we assembled a team of top-notch
            engineers with diverse skills and a passion for innovation. Through
            meticulous talent selection and leveraging advanced technologies, we
            brought our client's vision to life.
          </p>
        </div>
      </div>

      <div class="project-details-content" style="padding: 40px">
        <div class="title-content text-center">
          <h2>Bringing Talent and Tech Together</h2>
          <h3 style="font-weight: 600px; padding: 15px">
            The Innovators in Financial Excellence
          </h3>
        </div>
        <div class="details-content text-center" style="font-size: 20px">
          <p>
            With dedication and precision, our team fine-tuned every aspect of
            the software, from graphics to animation and rendering, ensuring an
            unparalleled user experience.
          </p>
        </div>
      </div>
      <div class="project-details-content mt-5">
        <div class="title-content p-3 text-center">
          <h2>Measuring Success: Key Achievements</h2>
        </div>
        <div class="details-content">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-md-6 text-center mt-4">
                  <img
                    src="../../../assets/img/project/key.jpg"
                    alt=""
                    style="height: 260px; width: 260px; border-radius: 50%"
                  />
                </div>
                <div class="col-md-6">
                  <div class="list-items mt-5">
                    <ul style="margin-right: 30%">
                      <li class="list-indent">
                        Enhanced Graphics:<span class="list-bold">
                          Boosted graphic performance by 30%, delivering better
                          visuals.</span
                        >
                      </li>
                      <li class="list-indent">
                        Smooth Animation:<span class="list-bold">
                          Slashed animation delays by 40%, creating smoother
                          movements.</span
                        >
                      </li>
                      <li class="list-indent">
                        Faster Rendering:<span class="list-bold">
                          Reduced rendering time by 50%, accelerating the
                          creation process.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="project-details-content overlay2-container mt-5"
        style="
          padding: 8%;
          border-radius: 8px;
          background-image: url(../../../assets/img/project/Redefining-Digital-Creativity.jpg);
          background-size: cover;
        "
      >
        <div class="overlay2"></div>
        <div class="title-content text-center">
          <h2 style="color: white">
            The Result: Redefining Digital Creativity
          </h2>
        </div>
        <div
          class="details-content text-center"
          style="font-size: 18px; padding: 20px; color: white"
        >
          <p>
            Together, we revolutionized digital media, setting new standards for
            creativity and performance, making a significant impact on films and
            video games worldwide.
          </p>
        </div>
      </div>

      <div class="container p-0 pb-3 pt-3 mt-5 mb-5">
        <div class="cta-chat-wrapper style-2">
          <div
            data-wow-delay=".3s"
            class="chat-items wow fadeInUp col-md-8"
            style="
              visibility: visible;
              animation-delay: 0.3s;
              animation-name: fadeInUp;
            "
          >
            <div class="icon"><i class="flaticon-chat"></i></div>
            <div class="content">
              <h3>Ready to Unleash Your Creativity?</h3>
              <p>
                Discover how Groot Software Solutions can help you unlock your
                full creative potential.
              </p>
            </div>
          </div>
          <a
            [routerLink]="['/enquiry']"
            data-wow-delay=".5s"
            class="theme-btn bg-white wow fadeInUp"
            style="
              visibility: visible;
              animation-delay: 0.5s;
              animation-name: fadeInUp;
            "
            ><span>
              Schedule a Consultation Today
              <i class="fas fa-chevron-right"></i></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>
