import { Component } from '@angular/core';

@Component({
  selector: 'harsh-kaushal',
  templateUrl: './harsh-kaushal.component.html',
  styleUrls: ['./harsh-kaushal.component.scss']
})

export class HarshKaushalComponent {
  constructor() { }
}