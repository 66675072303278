import { Component, OnInit } from '@angular/core';

declare var $: any; // Declare jQuery globally

@Component({
  selector: 'app-casestudyone',
  templateUrl: './casestudyone.component.html',
  styleUrls: ['./casestudyone.component.scss']
})
export class CasestudyOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.getSectionCard();
  }

  getSectionCard() {
    this.loadScript('assets/js/slick.min.js').then(() => {
      $('.case-studies-carousel-active-2').slick({
        autoplay: true,
        autoplaySpeed: 1500,
        speed: 1000,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        dotsClass: 'slide-dots',
        responsive: [
          {
            breakpoint: 1499,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              center: true,
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1
            }
          }
        ],

      });

      // Hide Next and Previous buttons after a short delay
      setTimeout(() => {
        $('.slick-prev.slick-arrow, .slick-next.slick-arrow').hide();
      }, 100); // Adjust the delay as needed
    }).catch(error => console.log('Script loading error', error));

    // Event handlers for previous and next buttons
    $('.testimonial-nav-prev').on('click', function () {
      $('.news-carousel-active').slick('slickPrev');
    });

    $('.testimonial-nav-next').on('click', function () {
      $('.news-carousel-active').slick('slickNext');
    });
  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

}
