`
    <div class="send-request">
      <button
        nz-button
        nzType="primary"
        [disabled]="setOfCheckedId.size === 0"
        [nzLoading]="loading"
        (click)="sendRequest()"
      >
        Delete
      </button>
      <span>Selected {{ setOfCheckedId.size }} items</span>
    </div>
    <nz-table
      #rowSelectionTable
      nzShowPagination
      nzShowSizeChanger
      [nzData]="listOfData"
      (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
    >
      <thead>
        <tr>
          <th
            [nzChecked]="checked"
            [nzIndeterminate]="indeterminate"
            nzLabel="Select all"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th>Title</th>
          <th>Created Date</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of rowSelectionTable.data">
          <td
            [nzChecked]="setOfCheckedId.has(data.id)"
            [nzDisabled]="data.disabled"
            [nzLabel]="data.name"
            (nzCheckedChange)="onItemChecked(data.id, $event)"
          ></td>
          <td>{{ data.name }}</td>
          <td>2 days ago</td>
          <td>{{ data.disabled ? "In Active" : "Active" }}</td>
          <td class="action-buttons"> <button>Edit</button> <button>Delete</button> </td>
        </tr>
      </tbody>
    </nz-table>
  `
