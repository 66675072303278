<div
  class="breadcrumb-wrapper section-padding bg-cover mb-0 size-first"
  style="background-image: url('../../../assets/img/bg-4.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Become A Partner</h1>
    </div>
  </div>
</div>

<section class="grow-section section-padding section-bg-3">
  <div class="container">
    <div class="grow-wrapper">
      <div class="section-title style-2 col-md-12">
        <div class="content white-cnt">
          <h3
            class="text-white wow fadeInUp fade-text-call"
            data-wow-delay=".3s"
          >
            Unlock Unlimited Earnings & Lifetime Commissions
          </h3>
        </div>
      </div>
      <div class="title-section-area">
        <p class="mt-3 mb-3 pe-5 w-75 text-white">
          Start earning recurring commissions on every successful referral
          throughout your first year of partnership. No minimum commitments and
          a non-binding agreement make getting started simple.
        </p>
        <a
          [routerLink]="['/enquiry']"
          class="theme-btn theme-btn-2 hover-white wow fadeInUp"
          data-wow-delay=".5s"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <span>
            Become a Partner
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
      <div class="circle-progress-bar-wrapper mbm-10"></div>
    </div>
  </div>
</section>
<section class="counter-section-2 style-margin">
  <div class="container">
    <div class="counter-wrapper-2">
      <div class="counter-items wow fadeInUp" data-wow-delay=".3s">
        <div class="icon">
          <i class="flaticon-verified"></i>
        </div>
        <div class="content">
          <h2><span class="count">80</span>+</h2>
          <p>Projects Completed</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".5s">
        <div class="icon">
          <i class="flaticon-service"></i>
        </div>
        <div class="content">
          <h2><span class="count">50</span>+</h2>
          <p>Certified Experts</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".7s">
        <div class="icon">
          <i class="flaticon-customer-service-1"></i>
        </div>
        <div class="content">
          <h2><span class="count">73</span></h2>
          <p>Best-in-class NPS Score</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".9s">
        <div class="icon">
          <i class="flaticon-medal"></i>
        </div>
        <div class="content">
          <h2><span class="count">40</span>+</h2>
          <p>Technologies</p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- -------- start Separate Section—---------  -->
<section>
  <div
    class="container text-center p-5 mobile-customs-set"
    style="background-color: ghostwhite; border-radius: 30px; margin-top: 15%"
  >
    <p>
      <b
        ><i
          >"Does your company offer products or services to businesses,
          agencies, or freelancers? Partner with us to generate additional
          revenue for your business. Everyone is Hiring Engineers. Checkout Who
          Can Be an Ideal Partner for Groot Software Solutions? Our program is
          designed for strategic alliances. Let’s craft a mutually beneficial
          partnership. Find out if you’d be a great fit for Groot Software
          Solutions. "</i
        ></b
      >
    </p>

    <div class="card-group mt-5" style="gap: 30px">
      <div class="card card-radius" style="border-radius: 15px">
        <img
          class="card-img-top"
          src="../../../assets/img/project/it-consulting.png"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="card-title">Business & IT Consultants</h5>
          <p class="card-text">
            Your expertise in identifying new markets, insights about industry
            trends, and emerging technologies can help us build a value-centric
            partnership.
          </p>
        </div>
      </div>
      <div class="card card-radius" style="border-radius: 15px">
        <img
          class="card-img-top"
          src="../../../assets/img/project/Marketing.jpg"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="card-title">Marketing Agencies</h5>
          <p class="card-text">
            Leverage your strong online presence to find referral partners in
            your client base who match our potential clients and join our
            referral partner program as valuable allies.
          </p>
        </div>
      </div>
      <div class="card card-radius" style="border-radius: 15px">
        <img
          class="card-img-top"
          src="../../../assets/img/project/Clients.jpg"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="card-title">Individuals with Potential Clients</h5>
          <p class="card-text">
            Spread the word among your existing customers, partners, and wider
            network, encouraging them to avail IT software development solutions
            by partnering with us.
          </p>
        </div>
      </div>
    </div>

    <div style="color: black; margin-top: 30px">
      <p>
        <b
          ><i>
            "Have a great network of businesses, agencies, or freelancers?<br />
            That's all you need to get started! Earn a Recurring Commission on
            Each Referral Turned Lead "</i
          ></b
        >
      </p>
    </div>
  </div>
</section>
<section class="service-details fix section-padding pt-5 padding-set">
  <div class="container">
    <div class="service-details-wrapper">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="seperation mt-5 mb-4"></div>
          <div class="service-details-items">
            <div class="details-content content-req">
              <h3 class="mt-3">What Benefits Are in Store for You?</h3>
              <p class="mt-3"><b>With your value, your business grows!</b></p>
              <ul class="mt-3">
                <li class="text-list">
                  Derive Recurring Commission: Earn lucrative commissions on
                  every lead that converts to a customer in the 1st year.
                </li>
                <li class="text-list">
                  Guaranteed Network Expansion: Grow your network with us and
                  gain new business opportunities.
                </li>
                <li class="text-list">
                  Multiply Business Value: Provide extra value to your network
                  by offering a broader range of services.
                </li>
              </ul>
            </div>
          </div>
         
          <div class="FQA mt-5">
            <h3>Frequently Asked Questions</h3>
            <div class="faq-content">
              <div class="faq-accordion">
                <div class="accordion" id="accordion">
                  <div
                    class="accordion-item wow fadeInUp"
                    *ngFor="let faq of faqs; let i = index"
                    [attr.data-wow-delay]="'.3s' + i * 0.2 + 's'"
                  >
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#faq' + (i + 1)"
                        aria-expanded="false"
                        [attr.aria-controls]="'faq' + (i + 1)"
                      >
                        {{ faq.question }}
                      </button>
                    </h4>
                    <div
                      [id]="'faq' + (i + 1)"
                      class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'faq' + (i + 1)"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        {{ faq.answer }}
                        <p class="mt-3" *ngIf="faq.additionalInfo">
                          <b>Some good referral opportunities are:</b>
                        </p>
                        <ul class="mt-3" *ngIf="faq.additionalInfo">
                          <li
                            class="text-list"
                            *ngFor="let info of faq.additionalInfo"
                          >
                            {{ info }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-4">
          <div class="main-sidebar">
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3>Contact us to kickstart your innovation journey</h3>
              </div>
              <app-commonenquiryform></app-commonenquiryform>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
