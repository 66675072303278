import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ClientApp';

  isAdmin() {
    return window.location.pathname.includes("admin");
  }

  isLoggedIn() {
    let token = window.localStorage.getItem("ACCESS_TOKEN");
    if(typeof token === "string" && token.length){
      return true
    }
    return false;
  }

}
