<div>
  <form
    [formGroup]="enquiryForm"
    (ngSubmit)="onSubmit()"
    id="contact-form"
    class="message-form"
  >
    <div class="row g-3">
      <div class="col-lg-12">
        <div class="single-form-input">
          <input
            type="text"
            formControlName="firstName"
            placeholder="First Name*"
          />
        </div>
        <div *ngIf="submitted && f.firstName.errors" class="text-danger">
          <div *ngIf="f.firstName.errors.required">First name is required</div>
          <div
            *ngIf="
              f.firstName.errors.notOnlyWhitespace &&
              !f.firstName.errors.required
            "
          >
            First name is required
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <input
            type="text"
            formControlName="lastName"
            placeholder="Last Name*"
          />
        </div>
        <div *ngIf="submitted && f.lastName.errors" class="text-danger">
          <div *ngIf="f.lastName.errors.required">Last name is required</div>
          <div
            *ngIf="
              f.lastName.errors.notOnlyWhitespace && !f.lastName.errors.required
            "
          >
            Last name is required
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <input
            type="email"
            formControlName="userEmail"
            placeholder="Company Email*"
          />
        </div>
        <div *ngIf="submitted && f.userEmail.errors" class="text-danger">
          <div *ngIf="f.userEmail.errors.required">Email is required</div>
          <div *ngIf="f.userEmail.errors.email && !f.userEmail.errors.required">
            Invalid email
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <input
            type="text"
            formControlName="phoneNumber"
            placeholder="Contact Number*"
          />
        </div>
        <div *ngIf="submitted && f.phoneNumber.errors" class="text-danger">
          <div *ngIf="f.phoneNumber.errors.required">
            Contact number is required
          </div>
          <div *ngIf="f.phoneNumber.errors?.pattern" class="text-danger">
            Enter only number.
          </div>
          <div
            *ngIf="
              f.phoneNumber.errors.notOnlyWhitespace &&
              !f.phoneNumber.errors.required
            "
          >
            Contact number is required
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <input
            type="text"
            formControlName="companyName"
            placeholder="Company Name*"
          />
        </div>
        <div *ngIf="submitted && f.companyName.errors" class="text-danger">
          <div *ngIf="f.companyName.errors.required">
            Company name is required
          </div>
          <div
            *ngIf="
              f.companyName.errors.notOnlyWhitespace &&
              !f.companyName.errors.required
            "
          >
            Company name is required
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <textarea
            formControlName="message"
            placeholder="Please Send A Message To Get The Conversation Started*"
          ></textarea>
        </div>
        <div *ngIf="submitted && f.message.errors" class="text-danger">
          <div *ngIf="f.message.errors.required">Message is required</div>
          <div
            *ngIf="
              f.message.errors.notOnlyWhitespace && !f.message.errors.required
            "
          >
            Message is required
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="single-form-input">
          <re-captcha
            (resolved)="resolved($event)"
            siteKey="{{ environment.recaptcha.siteKey }}"
            formControlName="recaptcha"
          ></re-captcha>
        </div>
        <div *ngIf="submitted && f.recaptcha.errors" class="text-danger">
          <div *ngIf="f.recaptcha.errors.required">
            Please verify you are human
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <button class="theme-btn" type="submit" [disabled]="disabled">
          <span>
            <span>
              Submit

              <i class="fas fa-chevron-right"></i>
            </span>
          </span>
        </button>
      </div>
    </div>
  </form>
</div>
